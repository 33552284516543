<template>
  <div></div>
</template>

<script>
export default {
  name: "LogoutView",
  mounted() {
    this.$store.dispatch('user/logout');
    this.$router.push('/')
  }
}
</script>

<style scoped>

</style>