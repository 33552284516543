<template>
  <b-alert variant="success" :show="!!message">
    <span>
      {{ message }} <br>
    </span>
  </b-alert>
</template>

<script>
export default {
  name: "SuccessAlert",
  props: [
      "message",
  ]
}
</script>

<style scoped>

</style>