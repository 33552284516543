import UserService from "@/services/UserService";
import store from "@/store"

export default {
    namespaced: true,

    state: {
        user: null,
        userToken: null,
        caretakerToken: null,
    },

    actions: {
        login({ commit }, payload) {
            return new Promise((resolve, reject) => {
                UserService.generateToken(payload)
                    .then(async ({data}) => {
                        // Set user token
                        commit('SET_USER_TOKEN', data.token);

                        // Get authenticated user
                        await store.dispatch('user/getAuthenticatedUser');

                        // Get Active Subscription
                        await store.dispatch('subscription/getActiveSubscription');

                        resolve(data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },


        // eslint-disable-next-line no-unused-vars
        register({ commit }, payload) {
            return new Promise((resolve, reject) => {
                UserService.register(payload)
                    .then(async ({data}) => {
                        resolve(data);
                    })
                    .catch(error => {

                        reject(error);
                    });
            });
        },

        verifyPhone({ commit }, payload) {
            return new Promise((resolve, reject) => {
                UserService.verifyCustomerByPhone(payload)
                    .then(async ({data}) => {
                        // Set user token
                        commit('SET_USER_TOKEN', data.token);

                        // Set authenticated user
                        commit('SET_AUTHENTICATED_USER', data.user);

                        resolve(data);
                    })
                    .catch(error => {

                        reject(error);
                    });
            });
        },

        getAuthenticatedUser({ commit }) {
            return new Promise((resolve, reject) => {
                UserService.getAuthenticatedUser({commit})
                    .then(async ({data}) => {
                        // Set authenticated user
                        if (data.user.email !== process.env.VUE_APP_CARETAKER_EMAIL) {
                            commit('SET_AUTHENTICATED_USER', data.user);
                        } else {
                            commit('SET_AUTHENTICATED_USER', null);
                        }

                        // Get Active Subscription
                        await store.dispatch('subscription/getActiveSubscription');

                        resolve(data.user);
                    }).catch((err) => {
                    reject(err);
                });
            });
        },

        updateDetails({ commit }, payload) {
            return new Promise((resolve, reject) => {
                UserService.updateDetails(payload)
                    .then(({ data }) => {
                        // Set authenticated user
                        if (data.email !== process.env.VUE_APP_CARETAKER_EMAIL){
                            commit('SET_AUTHENTICATED_USER', data.user);
                        } else {
                            commit('SET_AUTHENTICATED_USER', null);
                        }
                        resolve(data);
                    }).catch((err) => {
                    reject(err);
                });
            });
        },

        updateKYCDetails({ commit }, payload) {
            return new Promise((resolve, reject) => {
                UserService.updateKYCDetails(payload)
                    .then(({ data }) => {
                        // Set authenticated user
                        if (data.email !== process.env.VUE_APP_CARETAKER_EMAIL){
                            commit('SET_AUTHENTICATED_USER', data.user);
                        } else {
                            commit('SET_AUTHENTICATED_USER', null);
                        }
                        resolve(data);
                    }).catch((err) => {
                    reject(err);
                });
            });
        },

        destroyCaretakerToken({commit}){
            commit('SET_CARETAKER_TOKEN', null);
        },

        logout({ commit }){
            commit('SET_USER_TOKEN', null);
            commit('SET_AUTHENTICATED_USER', null);
        }

    },

    mutations: {
        SET_AUTHENTICATED_USER(state, data) {
            state.user = data;
        },

        SET_USER_TOKEN(state, data) {
            state.userToken = data;
        },

        SET_CARETAKER_TOKEN(state, data) {
            state.caretakerToken = data;
        },
    },

    getters: {
        user: (state) => state.user,
        userToken: (state) => state.userToken,
        caretakerToken: (state) => state.caretakerToken,
    },
};
