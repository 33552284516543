<template>
  <div class="row center-col">
      <div class="col-md-5">
        <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileMenuWrapper",
  inheritAttrs: false, // optional
}
</script>

<style scoped>

</style>