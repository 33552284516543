import {ApiService} from "@/services/APIService";

export default {
    getPages(
        recordsPerPage,
        currentPage,
        search
    ){
        let url = 'api/manager/cms/pages?';
        url += 'page=' + currentPage + '&';
        url += 'records_per_page=' + recordsPerPage + '&';
        url += 'search=' + search;

        return ApiService.get(url);
    },

    getPage(page_id){
        return ApiService.get('api/manager/cms/pages/' + page_id);
    },

    addPage(payload){
        return ApiService.post('api/manager/cms/pages/add', {
            title: payload.title,
            content: payload.content,
            category_id: payload.category_id,
        });
    },

    updatePage(payload){
        return ApiService.post('api/manager/cms/pages/update/' + payload.id, payload);
    },

    getPageCategories(){
        return ApiService.get('api/manager/cms/page-categories');
    }
}