import {ApiService} from "@/services/APIService";

export default {
    getReports(
        recordsPerPage,
        currentPage,
        search
    ){
        let url = 'api/manager/reports?';
        url += 'page=' + currentPage + '&';
        url += 'records_per_page=' + recordsPerPage + '&';
        url += 'search=' + search;

        return ApiService.get(url);
    },

    getReport(report_reference){
        return ApiService.get('api/manager/report/' + report_reference);
    },

    updateReport(payload){
        return ApiService.post('api/manager/report/' + payload.report_reference, payload)
    },

}