<template>
  <tablet-menu-wrapper>
    <mobile-menu-header
      title="Manage Customers"
      back-title="Back to Dashboard"
      back-route-link="/manager/dashboard"
    ></mobile-menu-header>
    <tablet-menu-body>
      <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
      <div v-else id="infinite-list-tablet-view">
        <div v-if="customers.length === 0 && !loading.page">
          <CenterMessage message="No customers found."></CenterMessage>
        </div>
        <div v-else>
          <div v-if="!loading.page" class="spread-cols-to-ends mb-2">
            <div>
              <b-form-input
                placeholder="Search for Customer"
                size="sm"
                v-model="search"
              >
              </b-form-input>
            </div>
            <div class="mt-3">
              <span
                >Showing page {{ pagination.currentPage }} of
                {{ pagination.totalPages }}.</span
              >
            </div>
          </div>
          <b-table-lite
            small
            hover
            v-if="!loading.page"
            :items="customers"
            :fields="fields"
          >
            <template #cell(status)="data">
              <b-badge
                v-if="data.item.status_id === 1"
                variant="warning"
                style="color: white"
                >Inactive</b-badge
              >
              <b-badge v-if="data.item.status_id === 2" variant="success"
                >Live</b-badge
              >
            </template>
            <template #cell(username)="data">
              {{ data.item.username }}
              <b-icon-patch-check-fill
                variant="warning"
                v-if="
                  data.item.is_kyc_verified && data.item.active_subscription
                "
              ></b-icon-patch-check-fill>
              <b-icon-patch-check-fill
                variant="primary"
                v-else-if="data.item.is_kyc_verified"
              ></b-icon-patch-check-fill>
            </template>
            <template #cell(kyc_submitted)="data">
              {{ data.item.id_photo ? "Yes" : "No" }}
            </template>
            <template #cell(verified)="data">
              {{ data.item.is_kyc_verified ? "Yes" : "No" }}
            </template>
            <template #cell(joined)="data">
              {{ moment(data.item.created_at).format("l") }}
            </template>
            <template #cell(action)="data">
              <router-link :to="'/manager/customers/view/' + data.item.id">
                <b-icon-search variant="primary" size="sm"> </b-icon-search>
              </router-link>
              <b-icon-check-circle-fill
                v-if="data.item.status_id === 1"
                class="mouse-pointer ml-1"
                variant="success"
                @click="updateCustomerStatus('activate', data.item.id)"
              >
              </b-icon-check-circle-fill>
              <b-icon-x-circle-fill
                v-if="data.item.status_id === 2"
                class="mouse-pointer ml-1"
                variant="warning"
                @click="updateCustomerStatus('deactivate', data.item.id)"
              >
              </b-icon-x-circle-fill>
            </template>
          </b-table-lite>
          <nav>
            <ul class="pagination justify-content-center">
              <li v-if="pagination.currentPage > 1" class="page-item">
                <a
                  class="page-link"
                  href="#"
                  aria-label="Previous"
                  @click.prevent="changePage(pagination.currentPage - 1)"
                >
                  <span aria-hidden="true">«</span>
                </a>
              </li>
              <li
                class="page-item"
                v-for="page in pagination.totalPages"
                :key="page"
                :class="{ active: page == pagination.currentPage }"
              >
                <a
                  v-if="
                    page < 3 ||
                    page > pagination.totalPages - 3 ||
                    (page < pagination.currentPage + 3 &&
                      page > pagination.currentPage - 3)
                  "
                  class="page-link"
                  @click.prevent="changePage(page)"
                  >{{ page }}</a
                >
              </li>
              <li
                v-if="pagination.currentPage < pagination.totalPages"
                class="page-item"
              >
                <a
                  class="page-link"
                  aria-label="Next"
                  @click.prevent="changePage(pagination.currentPage + 1)"
                >
                  <span aria-hidden="true">»</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </tablet-menu-body>
  </tablet-menu-wrapper>
</template>

<script>
import TabletMenuBody from "@/components/Workmorr/MobileMenu/TabletLayout/TabletMenuBody";
import TabletMenuWrapper from "@/components/Workmorr/MobileMenu/TabletLayout/TabletMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import CustomLoader from "@/components/Workmorr/CustomLoader";
import ManageCustomersService from "@/services/manager/ManageCustomersService";
import {
  BTableLite,
  BIconSearch,
  BIconPatchCheckFill,
  BIconCheckCircleFill,
  BIconXCircleFill,
} from "bootstrap-vue";
import CenterMessage from "@/components/Workmorr/CenterMessage";
import { updateObjectWithId } from "@/utils/helpers";

export default {
  name: "ManageCustomersView",
  components: {
    CustomLoader,
    TabletMenuBody,
    TabletMenuWrapper,
    MobileMenuHeader,
    BTableLite,
    BIconSearch,
    CenterMessage,
    BIconPatchCheckFill,
    BIconCheckCircleFill,
    BIconXCircleFill,
  },
  data() {
    return {
      loading: {
        page: true,
        records: false,
      },
      fields: [
        "username",
        "email",
        "phone",
        "status",
        "kyc_submitted",
        "joined",
        "action",
      ],
      customers: [],
      search: "",
      pagination: {
        currentPage: 1,
        perPage: 12,
        totalRows: 1000, // Placeholder so get is run at least once
        totalPages: 1,
      },
    };
  },

  async mounted() {
    await this.getCustomers();
    this.loading.page = false;
  },
  methods: {
    changePage(page) {
      this.pagination.currentPage = page;
      this.getCustomers();
    },

    async getCustomers(search) {
      this.loading.page = true;
      if (search === true) {
        this.pagination.currentPage = 1;
      }

      let response = await ManageCustomersService.getCustomers(
        this.pagination.perPage,
        this.pagination.currentPage,
        this.search
      );

      this.customers = response.data.customers.data;
      this.pagination.totalRows = response.data.customers.total;
      this.loading.records = false;
      this.loading.page = false;
      this.pagination.totalPages = response.data.customers.last_page;
    },

    updateCustomerStatus(action, customer_id) {
      ManageCustomersService.updateCustomerStatus({
        action: action,
        id: customer_id,
      }).then((response) => {
        this.customers = updateObjectWithId(
          this.customers,
          response.data.customer
        );
      });
    },
  },

  watch: {
    search() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(
        function () {
          this.getCustomers(true);
        }.bind(this),
        1000
      );
    },
  },
};
</script>

<style scoped>
</style>