<template>
  <tablet-menu-wrapper>
    <mobile-menu-header
      title="Manage Subscriptions"
      back-title="Back to Dashboard"
      back-route-link="/manager/dashboard"
    ></mobile-menu-header>
    <tablet-menu-body>
      <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
      <div v-else id="infinite-list-tablet-view">
        <div v-if="subscriptions.length === 0 && !loading.page">
          <CenterMessage message="No subscriptions found."></CenterMessage>
        </div>
        <div v-else>
          <div v-if="!loading.page" class="spread-cols-to-ends mb-1">
            <div></div>
            <div class="mt-3">
              <span
                >Showing page {{ pagination.currentPage }} of
                {{ pagination.totalPages }}.</span
              >
            </div>
          </div>
          <b-table-lite
            small
            hover
            v-if="!loading.page"
            :items="subscriptions"
            :fields="fields"
          >
            <template #cell(username)="data">
              {{ data.item.user.username }}
            </template>
            <template #cell(start)="data">
              {{ (moment(data.item.start_date).calendar().split(" at"))[0] }}
            </template>
            <template #cell(offer)="data">
              {{
                data.item.offer.price + " for " + data.item.offer.days + " days"
              }}
            </template>
            <template #cell(end)="data">
              {{ (moment(data.item.end_date).calendar().split(" at"))[0] }}
            </template>
            <template #cell(active)="data">
              <b-badge v-if="data.item.is_active == true" variant="success"
                >Active</b-badge
              >
              <b-badge v-if="data.item.is_active == false" variant="danger"
                >Expired</b-badge
              >
            </template>
            <template #cell(action)="data">
              <router-link :to="'/manager/subscriptions/view/' + data.item.id">
                <b-icon-search variant="primary" size="sm"> </b-icon-search>
              </router-link>
            </template>
          </b-table-lite>
          <nav>
            <ul class="pagination justify-content-center">
              <li v-if="pagination.currentPage > 1" class="page-item">
                <a
                  class="page-link"
                  href="#"
                  aria-label="Previous"
                  @click.prevent="changePage(pagination.currentPage - 1)"
                >
                  <span aria-hidden="true">«</span>
                </a>
              </li>
              <li
                class="page-item"
                v-for="page in pagination.totalPages"
                :key="page"
                :class="{ active: page == pagination.currentPage }"
              >
                <a
                  v-if="
                    page < 3 ||
                    page > pagination.totalPages - 3 ||
                    (page < pagination.currentPage + 3 &&
                      page > pagination.currentPage - 3)
                  "
                  class="page-link"
                  @click.prevent="changePage(page)"
                  >{{ page }}</a
                >
              </li>
              <li
                v-if="pagination.currentPage < pagination.totalPages"
                class="page-item"
              >
                <a
                  class="page-link"
                  aria-label="Next"
                  @click.prevent="changePage(pagination.currentPage + 1)"
                >
                  <span aria-hidden="true">»</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </tablet-menu-body>
  </tablet-menu-wrapper>
</template>

<script>
import TabletMenuBody from "@/components/Workmorr/MobileMenu/TabletLayout/TabletMenuBody";
import TabletMenuWrapper from "@/components/Workmorr/MobileMenu/TabletLayout/TabletMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import ManageSubscriptionsService from "@/services/manager/ManageSubscriptionsService";
import CustomLoader from "@/components/Workmorr/CustomLoader";
import { BIconSearch, BTableLite } from "bootstrap-vue";
import CenterMessage from "@/components/Workmorr/CenterMessage";

export default {
  name: "ManageSubscriptionsView",
  components: {
    CustomLoader,
    TabletMenuBody,
    TabletMenuWrapper,
    MobileMenuHeader,
    BTableLite,
    BIconSearch,
    CenterMessage,
  },
  data() {
    return {
      loading: {
        page: true,
        records: false,
      },
      fields: ["id", "username", "offer", "start", "end", "active"],
      subscriptions: [],
      search: "",
      pagination: {
        currentPage: 1,
        perPage: 12,
        totalRows: 1000, // Placeholder so get is run at least once
        totalPages: 1,
      },
    };
  },

  async mounted() {
    await this.getSubscriptions();
  },

  methods: {
    changePage(page) {
      this.pagination.currentPage = page;
      this.getSubscriptions();
    },

    async getSubscriptions() {
        this.loading.page = true;
        let response = await ManageSubscriptionsService.getSubscriptions(
          this.pagination.perPage,
          this.pagination.currentPage,
          this.search
        );
        this.subscriptions = response.data.subscriptions.data;
        this.pagination.totalRows = response.data.subscriptions.total;
        this.pagination.totalPages = response.data.subscriptions.last_page;
        this.loading.page = false;
      
    },
  },
};
</script>

<style scoped>
</style>