import {ApiService} from "@/services/APIService";

export default {
    getOrders(){
        return ApiService.get('api/manager/orders');
    },

    getOrder(order_id){
        return ApiService.get('api/manager/order/' + order_id);
    }
}