<template>
  <mobile-menu-wrapper>
    <mobile-menu-header
        title='Manager Dashboard'
        back-title="Back to Profile"
        back-route-link="/account"
    ></mobile-menu-header>
    <mobile-menu-body>
      <mobile-menu-link
          route="/manager/customers"
          borderBottom="true"
          title="Manage Customers"
          icon="person-badge"
      ></mobile-menu-link>
      <mobile-menu-link
          route="/manager/messages"
          borderBottom="true"
          title="Manage Messages"
          icon="mailbox"
      ></mobile-menu-link>
      <mobile-menu-link
          route="/manager/gigs"
          borderBottom="true"
          title="Manage Gigs"
          icon="tools"
      ></mobile-menu-link>
      <mobile-menu-link
          route="/manager/orders"
          borderBottom="true"
          title="Manage Orders"
          icon="briefcase"
      ></mobile-menu-link>
      <mobile-menu-link
          route="/manager/subscriptions"
          borderBottom="true"
          title="Manage Subscriptions"
          icon="people-fill"
      ></mobile-menu-link>
      <mobile-menu-link
          route="/manager/transactions"
          borderBottom="true"
          title="Manage Payments & Settlements"
          icon="currency-dollar"
      ></mobile-menu-link>
      <mobile-menu-link
          route="/manager/pages"
          borderBottom="true"
          title="Manage Pages"
          icon="blockquote-left"
      ></mobile-menu-link>
      <mobile-menu-link
          route="/manager/reports"
          borderBottom="false"
          title="Manage Reports"
          icon="exclamation-circle"
      ></mobile-menu-link>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>


<script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import MobileMenuLink from "@/components/Workmorr/MobileMenu/MobileMenuLink";

export default {
  name: "ManagerDashboardView",
  components: {MobileMenuBody, MobileMenuWrapper, MobileMenuHeader, MobileMenuLink},

}
</script>

<style scoped>

</style>