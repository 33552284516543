<template>
  <mobile-menu-wrapper>
    <mobile-menu-header
        title=''
        back-title="Back to Gig"
        :back-route-link="gigUrl"
    ></mobile-menu-header>
    <mobile-menu-body v-if="gig && user">
      <div v-if="success">
          <div style="text-align: center">
            <div style="font-size: 3rem; text-align: center" class="mt-5">
              <b-icon-check-circle variant="success"></b-icon-check-circle>
            </div>
            <h6 class="mb-3">{{ gig.user.id === user.id ? 'Offer Created Successfully' : 'Bid Successful' }}</h6>
            <b-button
                style="min-width: 200px"
                variant="primary"
                @click="goToChat()"
            >
              <b-icon-chat-right-dots class="mr-1"></b-icon-chat-right-dots>
              {{ gig.user.id === user.id  ? 'View Offer' : 'View Bid' }}
            </b-button>
          </div>
      </div>
      <div v-else>
        <h6 class="mb-3">{{ gig.user.id === user.id ? 'Create Custom Offer' : 'Bid' }} for Service:<br> <router-link :to="gigUrl">{{ gig.long_title }}</router-link></h6>
        <ErrorAlert v-if="errors" :errors="errors"></ErrorAlert>
        <div v-if="gig && user">
          <b-form-group v-if="gig.user.id === user.id"
                        label="Select Buyer"

          >
            <b-form-select v-model="offer.buyer" :disabled="loading.form">
              <b-form-select-option value="">Select Buyer</b-form-select-option>
              <b-form-select-option v-for="option in options" :key="option.value" :value="option.value">{{ option.text }}</b-form-select-option>
            </b-form-select>
            <div style="font-size: 0.8rem" class="mt-2">
              <b-icon-exclamation-circle
                  class="text-primary font-weight-bold"
              ></b-icon-exclamation-circle>
              <em class="text-muted">
                Only showing users you have interacted with. If you can't find a user, please send them a message first.
              </em>
            </div>
          </b-form-group>
          <b-form-group
              label="Offer Description">
            <b-form-checkbox
                v-model="maintainGigDescription"
                @change="toggleOfferDescription()"
            >
              <span style="font-size: 0.8rem" class="">Maintain Gig Description.</span>
            </b-form-checkbox>
            <editor
                v-model="offer.description"
                api-key="id8huwbtsicpgcsq9digruov36p4d48xrd8zgpfyk9gvgyzn"
                :init="{
             height: 150,
             menubar: false,
             plugins: [
               'advlist autolink lists link image charmap print preview anchor',
               'searchreplace visualblocks code fullscreen',
               'insertdatetime media table paste code help wordcount'
             ],
             toolbar:
               'undo redo | formatselect | bold italic backcolor | \
               alignleft aligncenter alignright alignjustify | \
               bullist numlist outdent indent | removeformat '
           }"
            />
          </b-form-group>
          <b-form-group
              label="Amount in GHS"
              :description="amountDescription"
          >
            <b-form-input
                v-model="offer.amount"
                type="number"
            ></b-form-input>
          </b-form-group>
          <b-form-group
              label="Completion Time in hours"
          >
            <b-form-input
                v-model="offer.duration"
                type="number"
                placeholder="E.g. 24"
            ></b-form-input>
          </b-form-group>
          <div class="row">
            <div class="col-12">
              <b-button
                  variant="success"
                  block
                  @click="createOffer()"
              >
                <span class="font-weight-bold">{{ gig.user.id === user.id ? 'Create Custom Offer' : 'Bid for Service' }}</span>
              </b-button>
            </div>
          </div>
        </div>
      </div>

    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>

<script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import GigService from "@/services/GigService";
import ErrorAlert from "@/components/Workmorr/ErrorAlert";
import { BIconExclamationCircle, BFormCheckbox, BIconCheckCircle, BIconChatRightDots } from 'bootstrap-vue';
import Editor from '@tinymce/tinymce-vue';

// Vue Select
import "vue-search-select/dist/VueSearchSelect.css";

import {formatErrors} from "@/utils/helpers";
import ChatService from "@/services/ChatService";

export default {
  name: "CreateGigOfferView",
  components: {
    MobileMenuBody,
    MobileMenuWrapper,
    MobileMenuHeader,
    BIconExclamationCircle,
    BFormCheckbox,
    Editor,
    ErrorAlert,
    BIconCheckCircle,
    BIconChatRightDots
  },
  data(){
    return {
      loading: {
        page: true,
        form: false,
      },
      success: '',
      errors: {},
      gig: null,
      user: null,
      offer: {
        buyer: '',
        description: '',
        amount: '',
        duration: '',
      },
      maintainGigDescription: false,
      userTypedDescription: '',
      options: [],
      chat: null,
    }
  },

  computed: {
    gigUrl(){
      if (this.gig){
        return '/gigs/view/' + this.gig.id + "/" + this.gig.slug;
      }

      return '';
    },

    amountDescription(){
      if (this.gig.user.id === this.user.id){
        if (this.gig.type_id === 1){
          // Offer
          return "How much you are charging for the service."
        }else{
          return "How much you are paying for the service."
        }
      } else {
        if (this.gig.type_id === 2){
          // Offer
          return "How much you are charging for the service."
        }else{
          return "How much you are paying for the service."
        }
      }

    }
  },

  async mounted() {
    await this.getGig();
    this.user = await this.$store.getters['user/user'];
    this.offer.amount = this.gig.amount;
    this.offer.duration = this.gig.duration;
    await this.getBuyers();
  },

  methods: {
    async getGig() {
      await GigService.getGig(this.$route.params.gig_id)
          .then((response) => {
            this.gig = response.data.gig;
          });
    },

    toggleOfferDescription(){
      if (this.maintainGigDescription){
        this.userTypedDescription = this.offer.description;
        this.offer.description = this.gig.description;
      } else {
        this.offer.description = this.userTypedDescription;
      }
    },

    createOffer(){
      let payload = {};
      payload.gig_id = this.gig.id;
      payload.is_custom = true;
      payload.amount = this.offer.amount;
      payload.description = this.offer.description;
      payload.duration = this.offer.duration;
      if (this.offer.buyer){
        payload.buyer_id = this.offer.buyer;
      }


      GigService.createOffer(payload)
          .then(response => {
            // Clear errors
            this.errors = {};
            this.success = response.data.message;
            this.chat = response.data.chat;
          }).catch((error) => {
        // Clear success
        this.success = null;
        this.errors = formatErrors(error);
      }).finally(() => {
        this.loading = false;
      });
    },

    goToChat(){
      let chatUrl = '/account/messages/' + this.chat.id + '/' + this.chat.user.username
      this.$router.push(chatUrl);
    },

    async getBuyers(){
      let response = await ChatService.getChats('');
      response.data.chats.map(function(chat) {
        this.options.push({
          value: chat.user.id,
          text: chat.user.username
        });
      }.bind(this))
    }
  }
}
</script>

<style scoped>

</style>