import {ApiService} from "@/services/APIService";

export default {
    getGigs(
        recordsPerPage,
        page,
        search
    ){
        let url = 'api/manager/gigs?';

        url += 'page=' + page + '&';
        url += 'records_per_page=' + recordsPerPage + '&';

        if (search){
            url += '&search=' + search;
        }
        return ApiService.get(url);
    },

    getGig(gig_id){
        return ApiService.get('api/manager/gig/' + gig_id);
    },

    updateGig(payload){
        return ApiService.get('api/manager/gig/' + payload.gig_id + '/update');
    }
}