<template>
  <mobile-menu-wrapper>
    <mobile-menu-header
        title='Your Favorites'
        back-title="Back to Account"
        back-route-link="/account"
    ></mobile-menu-header>
    <mobile-menu-body>
      <div id="infinite-list-mobile-view">
        <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
        <div v-else>
          <ErrorAlert v-if="errors" :errors="errors"></ErrorAlert>
          <SuccessAlert v-if="success" :message="success"></SuccessAlert>
          <b-card v-for="gig in gigs" :key="gig.id" class="mb-2 pt-1 shadow-sm">
            <router-link :to="'/gigs/view/' + gig.id + '/' + gig.slug" tag="span" class="mouse-pointer">
          <span style="font-size: 0.9rem" class="font-weight-bold">
            {{ gig.short_title }}
          </span>
            </router-link>
            <div class="spread-cols-to-ends mb-1">
              <div>
                <div>
                  <span style="font-size: 0.8rem"><em>{{ gig.category.description }}</em></span>
                </div>
                <div style="font-size: 0.7rem">
                  by <span style="color:green; font-weight: bold;">
                {{ gig.user.username }}
              </span>
                  <b-icon-patch-check-fill variant="primary" v-if="gig.user.is_kyc_verified"></b-icon-patch-check-fill>
                </div>
              </div>
              <div>
              </div>
            </div>

            <template #footer>
              <div class="spread-cols-to-ends mt-1">
                <div>
                  <span v-if="gig.amount > 0" style="color:green; font-weight: bold">	&#8373;{{ gig.amount }}</span>
                  <b-badge v-else variant="success" class="mb-1">Custom Offer</b-badge>
                </div>
                <div>
                  <b-icon-trash-fill @click="removeGigFromFavorites(gig.id)" variant="danger" class="mouse-pointer"></b-icon-trash-fill>
                </div>
              </div>
            </template>
          </b-card>
          <CustomLoader v-if="loading.list"></CustomLoader>
        </div>
        <div v-if="(!loading.list) && gigs && gigs.length === 0" class="text-center">
          <CenterMessage>
            <h6>You have no favorites.</h6>
            <router-link to="/">
              <b-button variant="outline-success" class="font-weight-bold" style="min-width: 300px">
                Browse Gigs and find Favorites.
              </b-button>
            </router-link>
          </CenterMessage>
        </div>
      </div>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>

<script>
import CustomLoader from "@/components/Workmorr/CustomLoader";
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import GigService from "@/services/GigService";
import { BIconTrashFill, BIconPatchCheckFill } from 'bootstrap-vue';
import CenterMessage from "@/components/Workmorr/CenterMessage";
import SuccessAlert from "@/components/Workmorr/SuccessAlert";
import ErrorAlert from "@/components/Workmorr/ErrorAlert";
import {formatErrors, removeObjectWithId} from "@/utils/helpers";

export default {
  name: "MyGigsView",
  components: {
    CustomLoader,
    MobileMenuBody,
    MobileMenuWrapper,
    MobileMenuHeader,
    BIconTrashFill,
    BIconPatchCheckFill,
    CenterMessage,
    SuccessAlert,
    ErrorAlert
  },
  data() {
    return {
      loading: {
        page: true,
        list: false,
      },
      gigs: [],
      gig: null,
      pagination: {
        currentPage: 1,
        perPage: 10,
        totalRows: 1000,
      },
      errors: {},
      success: '',
    }
  },

  async mounted() {
    await this.getGigs();
    this.loading.page = false;

    const listElm = document.querySelector('#infinite-list-mobile-view');
    // eslint-disable-next-line no-unused-vars
    listElm.addEventListener('scroll', e => {
      if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
        this.pagination.currentPage++;
        this.getGigs();
      }
    });
  },

  methods: {
    async getGigs(){
      if (this.gigs.length < this.pagination.totalRows){
        this.loading.list = true;
        let response = await GigService.getGigs(
            this.pagination.currentPage,
            false,
            this.pagination.perPage,
            'records',
            null,
            null,
            false,
            true
        );
        response.data.records.data.map(function(gig) {
          this.gigs.push(gig);
        }.bind(this))

        this.pagination.totalRows = response.data.records.total;
        this.loading.list = false;
      }
    },

    removeGigFromFavorites(gig_id){
      this.loading.page = true;

      GigService.favoriteGig(gig_id).then((response) => {
        this.gigs = removeObjectWithId(this.gigs, gig_id);
        this.success = response.data.message;

      }).catch((response) => {
        this.errors = formatErrors(response.data.message);
      })

      this.loading.page = false;
    }
  }



}
</script>

<style scoped>
  .card-body, .card-footer{
    padding-top: 2px;
    padding-bottom: 2px;
  }

</style>