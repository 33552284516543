<template>
  <div class="mb-2">
    <slot></slot>
    <div v-if="message.from_user_id === $store.getters['user/user'].id">
      <div class="from-user shadow-sm p-2 col-10 offset-2">
        <span style="font-size: 0.9rem" v-html="message.message"></span>
      </div>
      <div v-if="message.file && !message.is_censored" class="text-right mt-2">
        <a :href="file.firebase_url" target="_blank" rel="noopener noreferrer">
          <b-icon-cloud-arrow-down-fill></b-icon-cloud-arrow-down-fill>
          {{ message.file.original_name | truncate(20, "...") }}
        </a>
      </div>
      <div v-if="message.order" class="text-right mt-2">
        <b-button size="sm" variant="primary" @click="goToOrder()">
          View Order
        </b-button>
      </div>
      <div class="time-text text-muted text-right">
        You - {{ moment(message.created_at).format("h:mm a") }}
      </div>
    </div>
    <div v-else>
      <div class="from-user shadow-sm p-2 col-10">
        <span style="font-size: 0.9rem" v-html="message.message"></span>
      </div>
      <div v-if="message.is_censored" class="text-left mt-1">
        <span style="font-size: 0.7rem; font-weight: 500;" class="mb-2 mt-1">
          <b-icon-exclamation-circle
            class="text-danger"
          ></b-icon-exclamation-circle>
          <em>
            Be careful when dealing with users outside Workmorr. Ensure that you
            communicate and create your orders using Workmorr so there is
            evidence of your interactions. Using Workmorr is 100% FREE.
          </em>
        </span>
      </div>

      <div v-if="message.file && !message.is_censored" class="text-left mt-2">
        <a :href="file.firebase_url" target="_blank" rel="noopener noreferrer">
          <b-icon-cloud-arrow-down-fill></b-icon-cloud-arrow-down-fill>
          {{ message.file.original_name | truncate(20, "...") }}
        </a>
      </div>
      <div
        v-if="
          message.offer &&
          !message.offer.order &&
          !message.is_censored &&
          !message.from_user_id === $store.getters['user/user'].id
        "
        class="text-left mt-2"
      >
        <b-button
          size="sm"
          :disable="loading"
          variant="success"
          @click="payForOffer()"
        >
          Accept Offer & Pay
        </b-button>
      </div>
      <div v-if="message.order" class="text-left mt-2">
        <b-button size="sm" variant="primary" @click="goToOrder()">
          View Order
        </b-button>
      </div>
      <div class="time-text text-muted">
        {{ username }} - {{ moment(message.created_at).format("h:mm a") }}
      </div>
    </div>
  </div>
</template>

<script>
import { BIconCloudArrowDownFill, BIconExclamationCircle } from "bootstrap-vue";
import PaymentService from "@/services/PaymentService";
import { formatErrors } from "@/utils/helpers";

export default {
  name: "MobileMenuChatMessage",
  inheritAttrs: false, // optional
  props: ["message", "username", "service"],
  components: { BIconCloudArrowDownFill, BIconExclamationCircle },

  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async payForOffer() {
      this.loading = true;
      // Call Initiate Payment Endpoint
      PaymentService.initiateManualPayment({
        object_type: "offer",
        object_id: this.message.offer.id,
      })
        .then((response) => {
          this.$router.push(
            "/pay/offer/" +
              this.message.offer.id +
              "/" +
              response.data.reference
          );
        })
        .catch((error) => {
          this.errors = formatErrors(error);
        })
        .finally(() => (this.loading = false));
    },

    goToOrder() {
      this.$router.push("/account/orders/view/" + this.message.order.id);
    },
  },
};
</script>

<style scoped>
.from-self {
  color: white;
}

.from-self,
.from-user {
  border-radius: 10px !important;
}

.time-text {
  font-size: 0.7rem;
  font-style: italic;
  margin-top: 0.3rem;
}
</style>