import {ApiService} from "@/services/APIService";

export default {
    getSubscriptions(recordsPerPage, page){
        let url = 'api/manager/subscriptions?';

        url += 'page=' + page + '&';
        url += 'records_per_page=' + recordsPerPage;

        return ApiService.get(url);
    },
}