<template>
  <tablet-menu-wrapper :portrait="true">
    <mobile-menu-header
        title=''
        back-title="Back to Home"
        back-route-link="/"
    ></mobile-menu-header>
    <tablet-menu-body>
      <div>
        <CenterMessage>
          <h6 class="text-center">Oops, we can't find that page.</h6>
          <router-link to="/">
            <b-button variant="outline-success" class="font-weight-bold" style="min-width: 300px">
              Back to Home.
            </b-button>
          </router-link>
        </CenterMessage>
      </div>
    </tablet-menu-body>
  </tablet-menu-wrapper>
</template>

<script>
import TabletMenuBody from "@/components/Workmorr/MobileMenu/TabletLayout/TabletMenuBody";
import TabletMenuWrapper from "@/components/Workmorr/MobileMenu/TabletLayout/TabletMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import CenterMessage from "@/components/Workmorr/CenterMessage";

export default {
  name: "NotFound404View",
  components: {TabletMenuBody, TabletMenuWrapper, MobileMenuHeader, CenterMessage},
  data() {
    return {

    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>