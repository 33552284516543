<template>
  <mobile-menu-wrapper v-if="review">
    <div style="text-align: center">
      <h5>Rate Your Order by <b style="color: green">{{ review.gig.user.username }}</b></h5>
      <small>Your reviews help us make Workmorr better.</small>
    </div>
    <mobile-menu-body>
      <div style="text-align: center" class="mb-4 mt-1">
        <StarRating
            v-model="form.rating"
            :rounded-corners="true"
            :padding="10"
            :show-rating="false"
        />
      </div>
      <b-form-group
          label="Leave a comment (Optional)">
        <b-form-textarea
            :disabled="loading"
            v-model="form.comments"
            :placeholder="'Excellent service by ' + review.gig.user.username"
        >
        </b-form-textarea>
      </b-form-group>
      <div class="text-center">
        <b-button @click.prevent="completeReview"  variant="success" :disabled="loading">
          {{ loading ? 'Loading, please wait...' : 'Submit Review & Continue to Workmorr' }}
        </b-button>
      </div>
    </mobile-menu-body>

  </mobile-menu-wrapper>
</template>

<script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import GigService from "@/services/GigService";
import StarRating from 'vue-star-rating'

export default {
  name: "ReviewOrderView",
  components: {MobileMenuBody, MobileMenuWrapper, StarRating},
  data() {
    return {
      loading:false,
      user: null,
      review: null,
      form: {
        rating: 5,
        comments: ''
      }
    }
  },

  mounted() {
    this.user = this.$store.getters['user/user'];
    if (this.user.pending_review !== null){
      this.review = this.user.pending_review;
    }else{
      this.continueToWorkmorr();
    }
  },

  methods: {

    completeReview(){
      this.loading = true;
      GigService.completeReview({
        review_id: this.review.id,
        rating: this.form.rating,
        comments: this.form.comments
      }).then(async () => {
        await this.continueToWorkmorr();
      }).catch((response) => {
        console.log(response)
      }).finally(() => this.loading = false)
    },

    async continueToWorkmorr() {
      // Update User
      await this.$store.dispatch('user/getAuthenticatedUser');
      const redirectPath = this.$route.query.redirect;
      window.location.href = redirectPath;
    }
  },
}
</script>

<style scoped>
  .card-body, .card-footer{
    padding-top: 2px;
    padding-bottom: 2px;
  }

</style>