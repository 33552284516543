<template>
  <mobile-menu-wrapper>
    <mobile-menu-header
        title='Your Details'
        back-title="Back to Account"
        back-route-link="/account"
    ></mobile-menu-header>
    <b-alert v-if="pending_actions.length > 0" class="mt-1 mb-1" variant="warning" :show="!!pending_actions">
       <span v-for="action in pending_actions" :key="action">
          {{ action }} <br>
        </span>
    </b-alert>
    <mobile-menu-body>
      <mobile-menu-link
          route="/account/profile/username-and-email-settings"
          borderBottom="true"
          title="Username & Email Settings"
      ></mobile-menu-link>
      <mobile-menu-link
          route="/account/profile/password-settings"
          borderBottom="true"
          title="Password Settings"
      ></mobile-menu-link>
      <mobile-menu-link
          route="/account/profile/identity-details"
          borderBottom="true"
          title="Identity Details"
      ></mobile-menu-link>
      <mobile-menu-link
          route="/account/profile/settlement-account-settings"
          borderBottom="true"
          title="Payment Account Settings"
      ></mobile-menu-link>
      <mobile-menu-link
          route="/account/profile/location-settings"
          borderBottom="false"
          title="Location Settings"
      ></mobile-menu-link>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>

<script>
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuLink from "@/components/Workmorr/MobileMenu/MobileMenuLink";
import UserService from "@/services/UserService";
export default {
  name: "MyProfileView",
  components: {MobileMenuLink, MobileMenuBody, MobileMenuWrapper, MobileMenuHeader},
  data() {
    return {
      pending_actions: []
    }
  },

  async mounted() {
    let response = await UserService.getPendingActions();
    this.pending_actions = response.data.actions;
  }
}
</script>

<style scoped>

</style>