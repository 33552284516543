import {ApiService} from "@/services/APIService";

export default {
    getCustomers(
        recordsPerPage,
        currentPage,
        search
    ){
        let url = 'api/manager/customers?';
        url += 'page=' + currentPage + '&';
        url += 'records_per_page=' + recordsPerPage + '&';
        url += 'search=' + search;

        return ApiService.get(url);
    },

    getCustomer(customer_id){
        return ApiService.get('api/manager/customer/' + customer_id);
    },

    updateCustomer(payload){
        return ApiService.post('api/manager/customer/' + payload.get('id') + '/update', payload, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
    },

    verifyCustomerKyc(customer_id){
        return ApiService.get('api/manager/customer/' + customer_id + '/verify-kyc');
    },

    updateCustomerStatus(payload){
        return ApiService.post('api/manager/customer/' + payload.id + '/update/status', payload)
    },
}