var render = function render(){var _vm=this,_c=_vm._self._c;return _c('mobile-menu-wrapper',{attrs:{"portrait":true}},[_c('mobile-menu-header',{attrs:{"title":"Report Issue","back-title":_vm.prevRoute && _vm.prevRoute.meta.title ? 'Back to ' + _vm.prevRoute.meta.title : 'Back',"back-route-link":_vm.prevRoute ? _vm.prevRoute.path : '/'}}),_c('mobile-menu-body',[(_vm.errors)?_c('ErrorAlert',{attrs:{"errors":_vm.errors}}):_vm._e(),(_vm.success)?_c('SuccessAlert',{attrs:{"message":_vm.success}}):_vm._e(),_c('div',[_c('b-form-group',{attrs:{"id":"report-category-group","label":"Category of Report","label-for":"report-category"}},[_c('b-form-select',{attrs:{"disabled":_vm.loading},model:{value:(_vm.report.category_id),callback:function ($$v) {_vm.$set(_vm.report, "category_id", $$v)},expression:"report.category_id"}},[_c('b-form-select-option',{attrs:{"value":""}},[_vm._v("Select Report Category")]),_vm._l((_vm.options.category),function(option){return _c('b-form-select-option',{key:option.value,attrs:{"value":option.value}},[_vm._v(_vm._s(option.text))])})],2)],1),_c('b-form-group',{attrs:{"label":"Description"}},[_c('editor',{attrs:{"disabled":_vm.loading,"api-key":"id8huwbtsicpgcsq9digruov36p4d48xrd8zgpfyk9gvgyzn","init":{
           height: 150,
           menubar: false,
           plugins: [
             'advlist autolink lists link image charmap print preview anchor',
             'searchreplace visualblocks code fullscreen',
             'insertdatetime media table paste code help wordcount'
           ],
           toolbar:
             'undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat '
         }},model:{value:(_vm.report.description),callback:function ($$v) {_vm.$set(_vm.report, "description", $$v)},expression:"report.description"}})],1),_c('b-form-group',{attrs:{"label":"Supporting files or images (Optional)"}},[_c('b-form-file',{ref:"report-files",attrs:{"multiple":"","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.report.files),callback:function ($$v) {_vm.$set(_vm.report, "files", $$v)},expression:"report.files"}})],1),_c('b-button',{attrs:{"block":"","type":"submit","variant":"danger","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.loading ? 'Loading, please wait...' : 'Report Issue')+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }