<template>
  <b-alert variant="warning" :show="!!errors.length">
    <span style="font-size: 0.8rem" v-for="error in errors" :key="error">
      {{ error }} <br>
    </span>
  </b-alert>
</template>

<script>
export default {
  name: "WarningAlert",
  props: [
      'errors'
  ],
}
</script>

<style scoped>

</style>