<template>
  <mobile-menu-wrapper>
    <mobile-menu-header
      title="Add Gig"
      back-title="Back to Gigs"
      back-route-link="/"
    ></mobile-menu-header>
    <mobile-menu-body>
      <b-form-group label="Type of Service">
        <div class="row">
          <div class="col-6 pr-1">
            <b-button
              @click="toggleGigType(1)"
              :variant="gig.type_id === 1 ? 'success' : 'outline-success'"
            >
              Offer
            </b-button>
          </div>
          <div class="col-6 pl-1">
            <b-button
              @click="toggleGigType(2)"
              :variant="gig.type_id === 2 ? 'success' : 'outline-success'"
            >
              Request
            </b-button>
          </div>
        </div>
      </b-form-group>
      <b-form-group label="Title">
        <b-input-group :prepend="prepend">
          <b-form-input
            :disabled="loading.form"
            v-model="gig.title"
            aria-label="First name"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
      <b-form-group label="Category">
        <b-form-select v-model="gig.category_id" :disabled="loading.form">
          <b-form-select-option value="">Select Category</b-form-select-option>
          <b-form-select-option
            v-for="cat in options.category"
            :key="cat.value"
            :value="cat.value"
            >{{ cat.text }}</b-form-select-option
          >
        </b-form-select>
      </b-form-group>
      <b-form-group label="Description">
        <editor
          :disabled="loading.form"
          v-model="gig.description"
          api-key="id8huwbtsicpgcsq9digruov36p4d48xrd8zgpfyk9gvgyzn"
          :init="{
            height: 150,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
               alignleft aligncenter alignright alignjustify | \
               bullist numlist outdent indent | removeformat ',
          }"
        />
      </b-form-group>
      <b-form-group label="Pictures / Images (Optional)">
        <b-form-file
          multiple
          v-model="gig.files"
          placeholder="Select or drop up to 10 images ..."
          drop-placeholder="Drop image here..."
          ref="gig-files"
        ></b-form-file>
      </b-form-group>
      <b-form-group label="Amount in GHS" :description="amountDescription">
        <b-form-input
          :disabled="loading.form"
          v-model="gig.amount"
          type="number"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Completion Time in hours">
        <b-form-input
          v-model="gig.duration"
          type="number"
          placeholder="E.g. 24"
        ></b-form-input>
      </b-form-group>
      <b-form-group>
        <b-form-checkbox
          v-model="gig.location_based"
          @change="toggleLocationBased()"
        >
          <b
            >{{ gig.type_id === 1 ? "Buyer" : "Seller" }} needs to be present at
            my location.</b
          >
        </b-form-checkbox>
        <div style="height: 200px; border-radius: 7px" v-if="show_map">
          <MglMap
            :accessToken="accessToken"
            :mapStyle.sync="mapStyle"
            :attributionControl="false"
            @load="onMapLoaded"
          >
            <MglGeolocateControl position="top-right" />
            <MglNavigationControl position="top-right" />
            <MglMarker
              v-if="gig.longitude && gig.latitude"
              :coordinates="[gig.longitude, gig.latitude]"
              :draggable="true"
              @dragend="updateLocation"
              color="green"
            />
          </MglMap>
        </div>
      </b-form-group>
      <ErrorAlert v-if="errors" :errors="errors"></ErrorAlert>
      <div class="row">
        <div class="col-12">
          <b-button
            variant="success"
            block
            :disabled="loading.form"
            @click.prevent="addGig"
          >
            <span class="font-weight-bold">{{
              this.loading.form
                ? "Loading, please wait ..."
                : gig.type_id === 1
                ? "Add Service Offer"
                : "Add Service Request"
            }}</span>
          </b-button>
        </div>
      </div>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>

<script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import Editor from "@tinymce/tinymce-vue";
import ErrorAlert from "@/components/Workmorr/ErrorAlert";
import GigService from "@/services/GigService";
import Mapbox from "mapbox-gl";
import {
  MglMap,
  MglMarker,
  MglGeolocateControl,
  MglNavigationControl,
} from "vue-mapbox";

// Vue Select
import "vue-search-select/dist/VueSearchSelect.css";
import { formatErrors } from "@/utils/helpers";

export default {
  name: "AddGigView",
  components: {
    MobileMenuBody,
    MobileMenuWrapper,
    MobileMenuHeader,
    Editor,
    ErrorAlert,
    MglMap,
    MglMarker,
    MglGeolocateControl,
    MglNavigationControl,
  },

  created() {
    // We need to set mapbox-gl library here in order to use it in template
    this.mapbox = Mapbox;
  },

  data() {
    return {
      loading: {
        page: true,
        form: false,
      },
      success: "",
      errors: {},
      gig: {
        title: "",
        type_id: 1,
        category_id: "",
        description: "",
        amount: "",
        duration: "",
        location_based: false,
        longitude: "",
        latitude: "",
        files: null,
      },
      options: {
        category: [],
        type: [
          { value: 1, text: "Offer" },
          { value: 2, text: "Request" },
        ],
      },
      show_map: false,
      accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN, // your access token. Needed if you using Mapbox maps
      mapStyle: "mapbox://styles/mapbox/streets-v11", // your map style
      mapbox: null,
    };
  },

  async mounted() {
    let response = await GigService.getGigCategories();
    response.data.categories.map(
      function (category) {
        this.options.category.push({
          value: category.id,
          text: category.description,
        });
      }.bind(this)
    );
  },

  computed: {
    amountDescription() {
      if (this.gig.type_id === 1) {
        // Offer
        return "How much you are charging for the service. Enter 0 for custom pricing.";
      } else {
        return "How much you are paying for the service. Enter 0 for custom pricing.";
      }
    },

    prepend() {
      if (this.gig.type_id === 1) {
        // Offer
        return "I will ";
      } else {
        return "I need someone to ";
      }
    },

    geolocationPermissionGranted() {
      return "geolocation" in navigator;
    },
  },

  methods: {
    toggleGigType(type_id) {
      this.gig.type_id = type_id;
    },

    toggleLocationBased() {
      if (this.gig.location_based === true) {
        // Check if user has granted access to location
        if (this.geolocationPermissionGranted) {
          // Show map
          this.show_map = true;
        } else {
          this.errors = ["Please allow us access to your location."];
          this.gig.location_based = false;
        }
      } else {
        this.show_map = false;
      }
    },

    addGig() {
      this.loading.form = true;

      const formData = new FormData();
      formData.append("title", this.gig.title);
      formData.append("type_id", this.gig.type_id);
      formData.append("category_id", this.gig.category_id);
      formData.append("description", this.gig.description);
      formData.append("amount", this.gig.amount);
      formData.append("duration", this.gig.duration);
      formData.append(
        "location_based",
        this.gig.location_based == false ? 0 : 1
      );
      formData.append("longitude", this.gig.longitude);
      formData.append("latitude", this.gig.latitude);
      if (this.gig.files) {
        for (let i = 0; i < this.gig.files.length; i++) {
          let file = this.$refs["gig-files"].files[i];
          formData.append("files[" + i + "]", file);
        }
      }

      GigService.addGig(formData)
        .then((response) => {
          // Clear errors
          this.errors = {};
          this.$router.push("/gigs/edit/" + response.data.gig.id);
        })
        .catch((error) => {
          // Clear success
          this.success = null;
          this.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },

    updateLocation(event) {
      const newCoordinates = event.marker.getLngLat();
      this.gig.longitude = newCoordinates.lng;
      this.gig.latitude = newCoordinates.lat;
    },

    async onMapLoaded(event) {
      if (this.gig.longitude && this.gig.latitude) {
        event.map.flyTo({
          center: [this.gig.longitude, this.gig.latitude],
          zoom: 14,
          speed: 1,
        });
      } else if (this.geolocationPermissionGranted) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          // Here we catching 'load' map event
          const asyncActions = event.component.actions;

          const newParams = await asyncActions.flyTo({
            center: [position.coords.longitude, position.coords.latitude],
            zoom: 14,
            speed: 1,
          });

          this.gig.longitude = newParams.center.lng;
          this.gig.latitude = newParams.center.lat;
        });
      } else {
        this.errors = ["Please allow us access to your location."];
      }
    },
  },
};
</script>

<style scoped>
.btn {
  width: 100% !important;
}

.btn-radio:focus {
  outline-style: none !important;
  box-shadow: none !important;
}
</style>