<template>
  <mobile-menu-wrapper>
    <mobile-menu-header
        title='Your Money'
        back-title="Back to Account"
        back-route-link="/account"
    ></mobile-menu-header>
    <mobile-menu-body>
      <WarningAlert v-if="errors !== null" :errors="errors"></WarningAlert>
      <b-card v-for="account in accounts" :key="account.id" class="mb-2 shadow-sm">
        <span class="font-weight-bold">
          {{ account.name_on_account ?? user.name }}
        </span>
        <div class="mt-2">
          Balance:
          <span class="font-weight-bold text-success">
          {{ account.currency_code + ' ' + account.balance }}
        </span>
        </div>
      </b-card>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>

<script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import UserService from "@/services/UserService";
import WarningAlert from "@/components/Workmorr/WarningAlert";

export default {
  name: "MyMoneyView",
  components: {WarningAlert, MobileMenuBody, MobileMenuWrapper, MobileMenuHeader},
  data() {
    return {
      accounts: null,
      user: null,
      errors: null,
    }
  },

  async mounted() {
    let response = await UserService.getAccounts();
    this.accounts = response.data.accounts;
    this.errors = response.data.warning === null ? null : [response.data.warning];
    this.user = this.$store.getters['user/user'];
  }
}
</script>

<style scoped>

</style>