<template>
  <mobile-menu-wrapper>
    <mobile-menu-header
        :title="report ? 'Report #' + report.reference : ''"
        back-title="Back to Reports"
        back-route-link="/manager/reports"
    ></mobile-menu-header>
    <mobile-menu-body>
      <div style="font-size: 0.8rem" v-if="report">
        <span>
          Category: <b>{{ report.category.name }}</b>
        </span>
        <div class="mt-3">
          <b>Description</b>
          <div class="mt-2" v-html="report.description"></div>
        </div>
        <div class="mt-3" v-if="report.files.length > 0">
          <b>Supporting Files</b>
          <ul class="mt-2">
            <li class="mb-2" v-for="file in report.files" :key="file.id">
              <a :href="file.file.firebase_url"
                 target='_blank'
                 rel='noopener noreferrer'
              >
                <b-icon-cloud-arrow-down-fill></b-icon-cloud-arrow-down-fill>
                {{ file.file.original_name | truncate(50, '...') }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>

<script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import ManageReportsService from "@/services/manager/ManageReportsService";
import {BIconCloudArrowDownFill} from "bootstrap-vue";

export default {
  name: "ViewReportView",
  components: {
    MobileMenuBody,
    MobileMenuWrapper,
    MobileMenuHeader,
    BIconCloudArrowDownFill
  },
  mounted() {
    this.getReport()
  },
  data(){
    return {
      report: null
    }
  },
  methods: {
    getReport(){
      ManageReportsService.getReport(this.$route.params.id)
          .then((response) => {
            this.report = response.data.report;
            window.document.title = 'Workmorr - View Report #' + this.report.reference;
          })
    }
  },
}
</script>

<style scoped>

</style>