<template>
  <div class="row pt-3">
    <div class="col-md-12 text-center">
      <h5 style="text-align: center">Save time. Earn money today.</h5>
    </div>
    <div class="col-md-12">
      <div class="row center-col">
        <div class="col-md-5 form">
          <b-tabs
            active-nav-item-class="font-weight-bold text-success"
            content-class="mt-3"
            justified
          >
            <b-tab
              @click="section = 'login'"
              :active="section === 'login'"
              :title-link-class="'text-secondary'"
              title="Login"
            >
              <ValidationObserver v-slot="{ invalid }">
                <b-form>
                  <ErrorAlert v-if="errors" :errors="errors"></ErrorAlert>
                  <ValidationProvider
                    name="email"
                    :rules="{ required: true, email: true }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="login-email-group"
                      label="Email Address"
                      label-for="login_email"
                    >
                      <b-form-input
                        id="login_email"
                        v-model="form.email"
                        type="email"
                        placeholder="Enter email"
                        :disabled="loading"
                        :state="getValidationState(validationContext)"
                      ></b-form-input>
                      <b-form-invalid-feedback id="login-email-live-feedback">{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>

                  <ValidationProvider
                    name="password"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="login-password-group"
                      label="Password"
                      label-for="login_password"
                    >
                      <b-form-input
                        @keyup.enter="onSubmit"
                        id="login_password"
                        v-model="form.password"
                        type="password"
                        placeholder="Enter your password"
                        :disabled="loading"
                        :state="getValidationState(validationContext)"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        id="login-password-live-feedback"
                        >{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </ValidationProvider>

                  <div class="text-center">
                    <b-button
                      block
                      @click.prevent="onSubmit"
                      variant="success"
                      :disabled="loading || invalid"
                    >
                      {{ loading ? "Loading, please wait..." : "Login" }}
                    </b-button>
                    <div class="mt-2">
                      <router-link
                        tag="span"
                        class="mouse-pointer mt-2"
                        :to="'/password/forgot'"
                      >
                        Forgot Your Password? <br />
                        We'll help you get in.
                      </router-link>
                    </div>
                  </div>
                </b-form>
              </ValidationObserver>
            </b-tab>
            <b-tab
              @click="section = 'register'"
              :active="section === 'register'"
              :title-link-class="'text-secondary'"
              title="Register"
            >
              <ValidationObserver v-slot="{ invalid }">
                <b-form>
                  <ErrorAlert v-if="errors" :errors="errors"></ErrorAlert>
                  <ValidationProvider
                    name="name"
                    :rules="{ required: true, min: 4 }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="register-name-group"
                      label="Name"
                      label-for="register_name"
                    >
                      <b-form-input
                        id="register_name"
                        v-model="form.name"
                        placeholder="Enter name"
                        :disabled="loading"
                        :state="getValidationState(validationContext)"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        id="register-name-live-feedback"
                        >{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </ValidationProvider>

                  <ValidationProvider
                    name="username"
                    :rules="{ required: true, min: 4 }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="register-username-group"
                      label="Username"
                      label-for="register_username"
                      description="This will be your public name on Workmorr. Use a nickname or business name to protect your identity."
                    >
                      <b-form-input
                        id="register_username"
                        v-model="form.username"
                        placeholder="Enter username"
                        :disabled="loading"
                        :state="getValidationState(validationContext)"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        id="register-username-live-feedback"
                        >{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </ValidationProvider>

                  <ValidationProvider
                    name="country"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="register-country-group"
                      label="Country"
                      label-for="register_country"
                      description="This will be used to validate your number in international format."
                    >
                      <b-form-select v-model="form.country" :disabled="loading">
                        <b-form-select-option
                          v-for="option in options.countries"
                          :key="option.value"
                          :value="option.value"
                          >{{ option.text }}</b-form-select-option
                        >
                      </b-form-select>

                      <b-form-invalid-feedback
                        id="register-country-live-feedback"
                        >{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </ValidationProvider>

                  <ValidationProvider
                    name="phone"
                    :rules="{ required: true, digits: 10 }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="register-phone-group"
                      label="Active Phone Number"
                      label-for="register_phone"
                      description="We'll never share your phone with anyone else. Important information will be communicated to this phone number so please make sure it is valid and accessible by you."
                    >
                      <b-form-input
                        id="register_phone"
                        v-model="form.phone"
                        type="text"
                        placeholder="Enter phone number"
                        :disabled="loading"
                        :state="getValidationState(validationContext)"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        id="register-form-live-feedback"
                        >{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </ValidationProvider>

                  <ValidationProvider
                    name="email"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="register-email-group"
                      label="Email Address"
                      label-for="register_email"
                      description="We'll never share your email with anyone else. Important information will be communicated to this email so please make sure it is valid and accessible by you."
                    >
                      <b-form-input
                        id="register_email"
                        v-model="form.email"
                        type="email"
                        placeholder="Enter email"
                        :disabled="loading"
                        :state="getValidationState(validationContext)"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        id="register-form-live-feedback"
                        >{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </ValidationProvider>

                  <ValidationProvider
                    name="password"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="register-password-group"
                      label="Enter a Password"
                      label-for="register_password"
                    >
                      <b-form-input
                        id="register_password"
                        v-model="form.password"
                        type="password"
                        placeholder="Enter a password"
                        :disabled="loading"
                        :state="getValidationState(validationContext)"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        id="register-password-live-feedback"
                        >{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </ValidationProvider>

                  <ValidationProvider
                    name="password "
                    :rules="{ required: true, confirmed: 'password' }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="register-password-confirmation-group"
                      label="Confirm your Password"
                      label-for="register_password_confirmation"
                    >
                      <b-form-input
                        id="register_password_confirmation"
                        v-model="form.password_confirmation"
                        type="password"
                        placeholder="Repeat your password from above"
                        :disabled="loading"
                        :state="getValidationState(validationContext)"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        id="register-password-confirmation-live-feedback"
                        >{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </ValidationProvider>

                  <ValidationProvider
                    name="consent"
                    :rules="{ required: { allowFalse: false } }"
                    v-slot="validationContext"
                  >
                    <b-form-group id="consent-group">
                      <b-form-checkbox
                        v-model="form.consent"
                        :disabled="loading"
                        :state="getValidationState(validationContext)"
                      >
                        By registering, you agree to our Terms of Use and
                        Privacy Policy.
                      </b-form-checkbox>
                      <b-form-invalid-feedback id="consent-live-feedback">{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>

                  <div class="text-center">
                    <b-button
                      block
                      @click.prevent="onSubmit"
                      type="submit"
                      variant="success"
                      :disabled="loading || invalid"
                    >
                      {{
                        loading ? "Loading, please wait..." : "Join Workmorr"
                      }}
                    </b-button>
                  </div>
                </b-form>
              </ValidationObserver>
            </b-tab>
          </b-tabs>
          <BottomFooter class="mt-3"></BottomFooter>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatErrors } from "@/utils/helpers";
import ErrorAlert from "@/components/Workmorr/ErrorAlert";
import BottomFooter from "@/components/Workmorr/BottomFooter";
import UserService from "@/services/UserService";

export default {
  name: "LoginOrRegisterView",
  components: { BottomFooter, ErrorAlert },
  data() {
    return {
      loading: false,
      section: "register",
      form: {
        username: "",
        name: "",
        phone: "",
        country: "GH",
        email: "",
        password: "",
        password_confirmation: "",
        consent: false,
      },
      error: "",
      errors: {},
      options: {
        countries: [],
      },
    };
  },

  async mounted() {
    let response = await UserService.getCountries();
    response.data.map(
      function (country) {
        this.options.countries.push({
          value: country.alpha_2_code,
          text: country.name + " " + country.flag,
        });
      }.bind(this)
    );
  },

  methods: {
    getAPIFieldError(field) {
      if (typeof this.errors[field] !== "undefined") {
        return this.errors[field][0];
      }
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    onSubmit() {
      this.loading = true;

      let action = "";
      let payload = {};
      switch (this.section) {
        case "login":
          action = "user/login";
          payload = {
            email: this.form.email,
            password: this.form.password,
          };
          break;
        case "register":
          action = "user/register";
          payload = {
            name: this.form.name,
            username: this.form.username,
            country_alpha_2_code: this.form.country,
            phone: this.form.phone,
            email: this.form.email,
            password: this.form.password,
            password_confirmation: this.form.password_confirmation,
            consent: this.form.consent,
          };
          break;
      }

      // Process submission
      // eslint-disable-next-line no-unused-vars
      this.$store
        .dispatch(action, payload)
        .then((response) => {
          let route = "";

          if (this.section === "login") {
            // redirect to home or redirect route if set.
            if (this.$route.query.redirect) {
              route = this.$route.query.redirect;
            } else {
              route = "/account";
            }
          } else {
            route = "/v/" + response.user.id + "/";
          }

          this.$router.push(route);
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 422 || error.response.status === 401) {
            let scope = this;
            scope.errors = formatErrors(error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.form {
  border-radius: 5px;
  padding: 20px;
  min-height: 300px;
}
</style>