var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tablet-menu-wrapper',{attrs:{"portrait":true}},[_c('mobile-menu-header',{attrs:{"title":"Add Page","back-title":"Back to Pages","back-route-link":"/manager/pages"}}),_c('tablet-menu-body',[(_vm.loading.page)?_c('CustomLoader',{attrs:{"center":true}}):_c('div',[(_vm.errors)?_c('ErrorAlert',{attrs:{"errors":_vm.errors}}):_vm._e(),(_vm.success)?_c('SuccessAlert',{attrs:{"message":_vm.success}}):_vm._e(),_c('b-form-group',{attrs:{"label":"Page Title"}},[_c('b-form-input',{attrs:{"disabled":_vm.loading.form},model:{value:(_vm.page.title),callback:function ($$v) {_vm.$set(_vm.page, "title", $$v)},expression:"page.title"}})],1),_c('b-form-group',{attrs:{"label":"Page Category"}},[_c('b-form-select',{attrs:{"disabled":_vm.loading.form},model:{value:(_vm.page.category_id),callback:function ($$v) {_vm.$set(_vm.page, "category_id", $$v)},expression:"page.category_id"}},[_c('option',{domProps:{"value":null}},[_vm._v("Select Category")]),_vm._l((_vm.categories),function(category){return _c('option',{key:category.id,domProps:{"value":category.id}},[_vm._v(_vm._s(category.description))])})],2)],1),_c('b-form-group',{attrs:{"label":"Page Content"}},[_c('editor',{attrs:{"disabled":_vm.loading.form,"api-key":"id8huwbtsicpgcsq9digruov36p4d48xrd8zgpfyk9gvgyzn","init":{
           height: 300,
           menubar: false,
           plugins: [
             'advlist autolink lists link image charmap print preview anchor',
             'searchreplace visualblocks code fullscreen',
             'insertdatetime media table paste code help wordcount'
           ],
           toolbar:
             'undo redo | formatselect | bold italic backcolor | media | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help'
         }},model:{value:(_vm.page.content),callback:function ($$v) {_vm.$set(_vm.page, "content", $$v)},expression:"page.content"}})],1),_c('div',{staticClass:"text-center"},[_c('b-button',{staticStyle:{"min-width":"200px"},attrs:{"type":"submit","variant":"success","disabled":_vm.loading.form},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.loading.form ? 'Loading, please wait...' : 'Add Page')+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }