<template>
  <mobile-menu-wrapper>
    <mobile-menu-header
        title='Your Gigs'
        back-title="Back to Account"
        back-route-link="/account"
    ></mobile-menu-header>
    <mobile-menu-body>
      <div id="infinite-list-mobile-view">
        <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
        <div v-else>
          <ErrorAlert v-if="errors" :errors="errors"></ErrorAlert>
          <SuccessAlert v-if="success" :message="success"></SuccessAlert>
          <b-card v-for="gig in gigs" :key="gig.id" class="mb-2 pt-1 shadow-sm">
            <router-link :to="'/gigs/view/' + gig.id + '/' + gig.slug" tag="span" class="mouse-pointer">
              <span style="font-size: 0.9rem" class="font-weight-bold">
                {{ gig.short_title }}
              </span>
            </router-link>
            <div class="spread-cols-to-ends mb-1">
              <div>
                <span style="font-size: 0.8rem"><em>{{ gig.category.description }}</em></span>
              </div>
              <div>
                <span v-if="gig.amount > 0" style="color:green; font-weight: bold">	&#8373;{{ gig.amount }}</span>
                <b-badge v-else variant="success">Custom Offer</b-badge>
              </div>
            </div>

            <template #footer>
              <div class="spread-cols-to-ends mt-1">
                <div class="mb-1">
                  <b-badge v-if="gig.status_id === 1" variant="info">In review</b-badge>
                  <b-badge v-else-if="gig.status_id === 2" variant="success">Live</b-badge>
                  <b-badge v-else-if="gig.status_id === 4" variant="warning" class="text-white">Paused</b-badge>
                </div>
                <div>
                  <b-icon-pencil-square @click="$router.push('/gigs/edit/' + gig.id)" variant="primary" class="mouse-pointer"></b-icon-pencil-square>
                  <b-icon-pause-fill v-if="gig.status_id !== 4" @click="performGigAction('pause', gig.id)" variant="warning" class="mouse-pointer ml-2"></b-icon-pause-fill>
                  <b-icon-play-fill v-if="gig.status_id === 4" @click="performGigAction('activate', gig.id)" variant="success" class="mouse-pointer ml-2"></b-icon-play-fill>
                  <b-icon-trash-fill @click="showConfirmActionModal(gig)" variant="danger" class="mouse-pointer ml-2"></b-icon-trash-fill>

                </div>
              </div>
            </template>
          </b-card>
          <CustomLoader v-if="loading.list"></CustomLoader>
        </div>
        <div v-if="(!loading.list) && gigs && gigs.length === 0" class="text-center">
          <CenterMessage>
            <h6>You have no gigs yet.</h6>
            <router-link to="/gigs/add">
              <b-button variant="outline-success" class="font-weight-bold" style="min-width: 300px">
                Request or Offer a Service
              </b-button>
            </router-link>
          </CenterMessage>
        </div>
      </div>
      <b-modal id="confirm-modal" title="Confirm Delete" size="sm">
        <template #default>
          <span class="font-weight-light">
            Are you sure you want to delete this Gig? This action is permanent and cannot be undone.
          </span>
        </template>
        <template #modal-footer>
          <div style="text-align: center">
            <b-button @click="$bvModal.hide('confirm-modal');" variant="outline-primary" class="btn-sm mr-2">No, go back</b-button>
            <b-button @click="performGigAction('delete', gig.id)" variant="danger" class="btn-sm">Yes, delete Gig</b-button>
          </div>
        </template>
      </b-modal>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>

<script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import GigService from "@/services/GigService";
import CustomLoader from "@/components/Workmorr/CustomLoader";
import {BIconTrashFill, BIconPencilSquare, BIconPlayFill, BIconPauseFill} from 'bootstrap-vue'
import CenterMessage from "@/components/Workmorr/CenterMessage";
import {formatErrors, removeObjectWithId, updateObjectWithId} from "@/utils/helpers";
import SuccessAlert from "@/components/Workmorr/SuccessAlert";
import ErrorAlert from "@/components/Workmorr/ErrorAlert";

export default {
  name: "MyGigsView",
  components: {
    CenterMessage,
    CustomLoader,
    MobileMenuBody,
    MobileMenuWrapper,
    MobileMenuHeader,
    BIconTrashFill,
    BIconPencilSquare,
    BIconPlayFill,
    BIconPauseFill,
    SuccessAlert,
    ErrorAlert,
  },
  data() {
    return {
      loading: {
        page: true,
        list: false,
      },
      gigs: [],
      gig: null,
      pagination: {
        currentPage: 1,
        perPage: 10,
        totalRows: 1000, // Placeholder so get is run at least once
      },
      success: '',
      errors: {},
    }
  },

  async mounted() {

    await this.getGigs();
    this.loading.page = false;

    const listElm = document.querySelector('#infinite-list-mobile-view');
    // eslint-disable-next-line no-unused-vars
    listElm.addEventListener('scroll', e => {
      if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
        this.pagination.currentPage++;
        this.getGigs();
      }
    });
  },

  methods: {
    async getGigs(){
      if (this.gigs.length < this.pagination.totalRows){
        this.loading.list = true;
        let response = await GigService.getGigs(
            this.pagination.currentPage,
            true,
            this.pagination.perPage
        );
        response.data.records.data.map(function(gig) {
          this.gigs.push(gig);
        }.bind(this))
        this.pagination.totalRows = response.data.records.total;
        this.loading.list = false;
      }
    },

    showConfirmActionModal(gig){
      this.gig = gig;
      this.$bvModal.show('confirm-modal');
    },

    async performGigAction(action, gig_id) {
      this.loading.page = true;

      await GigService.performGigAction(action, gig_id).then((response) => {
        if (action === 'delete') {
          // remove gig from stack
          removeObjectWithId(this.gigs, gig_id);
        } else {
          // update gig
          this.gigs = updateObjectWithId(this.gigs, response.data.gig)
        }
        this.success = response.data.message;

      }).catch((response) => {
        this.errors = formatErrors(response.data.message);
      })

      this.loading.page = false;
    }
  }



}
</script>

<style scoped>
  .card-body, .card-footer{
    padding-top: 2px;
    padding-bottom: 2px;
  }
</style>