import {ApiService} from "@/services/APIService";

export default {
    getActiveSubscription() {
        return ApiService.get("/api/subscriptions/active");
    },

    getSubscriptionOffers() {
        return ApiService.get("/api/subscriptions/offers");
    },

    confirmSubscriptionPayment(payload) {
        return ApiService.post("/api/subscriptions/pay/confirm", payload);
    },

    getOffer(offer_id){
        return ApiService.get("/api/subscriptions/offers/" + offer_id);
    }
}