<template>
  <div>
    <router-link tag="div" class="mouse-pointer" :to="route">
      <div class="spread-cols-to-ends">
        <span>
          <b-icon v-if="icon" :icon="icon" class="mr-1"></b-icon>
          {{ title }}
          <b-badge pill variant="danger" class="ml-1" v-if="route==='/account/messages' && $store.getters['user/user'].unread_messages_count > 0">
          <span class="small mb-1 font-weight-bold">{{ $store.getters['user/user'].unread_messages_count }}</span>
        </b-badge>
        </span>

        <div>
        <span class="mr-2">
          <b-icon-chevron-right></b-icon-chevron-right>
        </span>
        </div>
      </div>
    </router-link>
    <hr v-if="borderBottom === 'true'" class="mt-1">
  </div>
</template>

<script>
import {
  BIconChevronRight,
  BIcon,
} from "bootstrap-vue";

export default {
  name: "MobileMenuLink",
  props: [
      'borderBottom',
      'route',
      'title',
      'icon'
  ],
  components: {
    BIconChevronRight,
    BIcon,
  }
}
</script>

<style scoped>
  .mouse-pointer:hover {
    cursor: pointer;
    font-weight: bold;
    color: green;
  }
</style>