<template>
  <mobile-menu-wrapper>
    <mobile-menu-header
      title="Pay"
      back-title="Go Back"
      :back-route-link="lastPath"
    ></mobile-menu-header>
    <mobile-menu-body>
      <div v-if="object_type === 'offer' && object" style="font-size: 0.9rem">
        <div class="mb-3">
          <div class="spread-cols-to-ends mb-2">
            <span><b>Gig Offer</b></span>
            <span
              ><b style="color: #008d36; font-size: 1rem"
                >GHS {{ object.amount }}</b
              ></span
            >
          </div>
        </div>
        <b-alert variant="success" :show="object !== null">
          <div class="mb-2">
            <b>
              <b-icon-exclamation-circle-fill></b-icon-exclamation-circle-fill>
              You are protected. </b
            ><br />
          </div>
          <span style="font-size: 0.8rem"
            >We will <b>not</b> release your money to the Seller until you
            confirm that you are satisfied with the service provided.</span
          >
        </b-alert>
      </div>
      <div
        v-if="object_type === 'subscription' && object"
        style="font-size: 0.9rem"
      >
        <div class="mb-3">
          <div class="spread-cols-to-ends mb-2">
            <span><b>Subscription</b></span>
            <span
              ><b style="color: #008d36; font-size: 1rem"
                >GHS {{ object.price }}</b
              ></span
            >
          </div>
        </div>
      </div>
      <div
        v-if="object_type === 'promotion' && object"
        style="font-size: 0.9rem"
      >
        <div class="mb-3">
          <div class="spread-cols-to-ends mb-2">
            <span><b>Promotion</b></span>
            <span
              ><b style="color: #008d36; font-size: 1rem"
                >GHS {{ object.price }}</b
              ></span
            >
          </div>
        </div>
      </div>
      <div style="height: 400px">
        <!-- <vue-iframe
            :src="iFrameURL"
            allow=""
            frame-id="my-iframe"
            @load="onLoad"
            name="my-frame"
            scrolling="false"
        /> -->
        <div class="mb-3">
          <span style="font-weight: 800; font-size: 16px">Ghana 🇬🇭</span>
          <br />
          <div class="mt-2" style="font-size: 14px; line-height: 1.3">
            <b>MTN Mobile Money </b><br />
            Account No: <b>059 701 8036</b> <br />
            Name: <b>Workmorr Company Limited </b> or <b>Michael Selby</b>
            <br />
          </div>
        </div>

        <div class="mb-2">
          <span style="font-weight: 800; font-size: 15px"
            >Payment Instructions</span
          >
          <br />
          <ol class="mt-1" style="font-size: 14px; line-height: 1.3">
            <li>Make payment to the above account.</li>
            <li>
              Use the reference
              <b>{{ $route.params.checkout_id.slice(0, 4) }}</b> when completing
              the transaction
            </li>
            <li>
              Click the "I have completed payment" button below when completed.
            </li>
          </ol>
          <div class="text-center">
            <b-button
              @click.prevent="submitPayment"
              variant="success"
              :disabled="loading"
            >
              {{
                loading
                  ? "Loading, please wait..."
                  : "I have completed payment."
              }}
            </b-button>
          </div>
        </div>
      </div>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>

<script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import { BAlert, BIconExclamationCircleFill } from "bootstrap-vue";
import GigService from "@/services/GigService";
import SubscriptionService from "@/services/SubscriptionService";
import PaymentService from "@/services/PaymentService";
import { formatErrors } from "@/utils/helpers";
import PromotionService from "@/services/PromotionService";

export default {
  name: "InitiatePaymentView",
  components: {
    MobileMenuBody,
    MobileMenuWrapper,
    MobileMenuHeader,
    BAlert,
    BIconExclamationCircleFill,
  },
  data() {
    return {
      lastPath: "/",
      object: null,
      object_type: null,
      loading: false,
    };
  },
  mounted() {
    this.object_type = this.$route.params.object_type;
    if (this.$route.params.object_type === "offer") {
      // Get Gig Offer
      GigService.getOffer(this.$route.params.object_id).then((response) => {
        this.object = response.data.offer;
      });
    } else if (this.$route.params.object_type === "subscription") {
      // Get Subscription Offer
      SubscriptionService.getOffer(this.$route.params.object_id).then(
        (response) => {
          this.object = response.data.offer;
        }
      );
    } else if (this.$route.params.object_type === "promotion") {
      // Get Subscription Offer
      PromotionService.getOffer(this.$route.params.object_id).then(
        (response) => {
          this.object = response.data.offer;
        }
      );
    }
  },

  computed: {
    iFrameURL() {
      return (
        "https://pay.hubtel.com/" + this.$route.params.checkout_id + "/direct"
      );
    },
  },

  methods: {
    onLoad() {
      console.log("loaded");
    },

    async submitPayment() {
      this.loading = true;
      this.loading = true;
      // Call Initiate Payment Endpoint
      PaymentService.submitManualPayment({
        reference: this.$route.params.checkout_id,
      })
        .then(() => {
          this.$router.push(
            "/paid" +
              "/" +
              this.$route.params.object_type +
              "/" +
              this.$route.params.object_id
          );
        })
        .catch((error) => {
          this.errors = formatErrors(error);
        })
        .finally(() => (this.loading = false));
      this.loading = false;
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.lastPath = from;
    });
  },
};
</script>

<style scoped>
</style>