<template>
  <mobile-menu-wrapper :portrait="true">
    <mobile-menu-header
        title='Forgot Password'
        back-title="Back to Login"
        back-route-link="/login"
    ></mobile-menu-header>
    <mobile-menu-body>
      <ErrorAlert v-if="errors" :errors="errors"></ErrorAlert>
      <SuccessAlert v-if="success" :message="success"></SuccessAlert>
      <div>
        <b-form-group
            id="login-email-group"
            label="Email Address"
            label-for="login_email"
        >
          <b-form-input
              id="login_email"
              v-model="form.email"
              type="email"
              placeholder="Enter email"
              :disabled="loading"
          ></b-form-input>
        </b-form-group>
        <b-button block @click.prevent="onSubmit" type="submit" variant="success" :disabled="loading || success !== null">
          {{ loading ? 'Loading, please wait...' : 'Send Reset Link' }}
        </b-button>
      </div>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>

<script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import ErrorAlert from "@/components/Workmorr/ErrorAlert";
import SuccessAlert from "@/components/Workmorr/SuccessAlert";
import UserService from "@/services/UserService";
import {formatErrors} from "@/utils/helpers";

export default {
  name: "ForgotPasswordView",
  components: {MobileMenuBody, MobileMenuWrapper, MobileMenuHeader, ErrorAlert, SuccessAlert},
  data(){
    return {
      loading: false,
      success: null,
      errors: {},
      form: {
        email: ''
      }
    }
  },

  methods: {
    onSubmit(){
      this.loading = true;
      UserService.initiatePasswordReset(this.form)
          .then(response => {
            // Clear errors
            this.errors = {};
            this.success = response.data.message;
          }).catch((error) => {
        // Clear success
        this.success = null;
        this.errors = formatErrors(error);
      }).finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>