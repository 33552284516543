import { ApiService } from "@/services/APIService";

export default {
    getOrder(order_id) {
        return ApiService.get("/api/orders/" + order_id);
    },

    getOrders(
        currentPage,
        recordsPerPage
    ) {

        let url = '/api/orders?';

        url += 'page=' + currentPage + '&';
        url += 'records_per_page=' + recordsPerPage;

        return ApiService.get(url);
    },

    performOrderAction(action, payload) {
        let url = '';
        switch (action) {
            case 'confirm-payment':
                url = '/api/orders/pay/confirm';
                break;
            case 'request':
                url = '/api/orders/request';
                break;
            case 'accept':
                url = '/api/orders/accept';
                break;
            case 'submit-for-review':
                url = '/api/orders/review';
                break;
            case 'revision':
                url = '/api/orders/revision';
                break;
            case 'complete':
                url = '/api/orders/complete';
                break;
            case 'cancel':
                url = '/api/orders/cancel';
                break;
        }

        return ApiService.post(url, payload)
    }
}