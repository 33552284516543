<template>
  <tablet-menu-wrapper :portrait="true">
    <mobile-menu-header
        title=''
        back-title="Back to Home"
        back-route-link="/"
    ></mobile-menu-header>
    <tablet-menu-body>
      <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
      <div v-else>
          <h4>{{ page.title }}</h4>
          <p v-html="page.content">

          </p>
      </div>
    </tablet-menu-body>
  </tablet-menu-wrapper>
</template>

<script>
import TabletMenuBody from "@/components/Workmorr/MobileMenu/TabletLayout/TabletMenuBody";
import TabletMenuWrapper from "@/components/Workmorr/MobileMenu/TabletLayout/TabletMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import CustomLoader from "@/components/Workmorr/CustomLoader";
import PageService from "@/services/PageService";

export default {
  name: "PageView",
  components: {TabletMenuBody, TabletMenuWrapper, MobileMenuHeader, CustomLoader},
  data() {
    return {
      loading: {
        page: true,
      },
      page: {},
    }
  },
  mounted() {
    PageService.getPage(this.$route.params.id).then((response) => {
      this.page = response.data.page
      this.loading.page = false;
      window.document.title = "Workmorr - " + this.page.title;
    }).catch(() => {
      this.$router.push('/404')
    })
  }
}
</script>

<style scoped>

</style>