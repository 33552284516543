<template>
  <mobile-menu-wrapper>
    <mobile-menu-header
      title="Promote Service 🚀"
      back-title="Go Back"
      :back-route-link="lastPath"
    ></mobile-menu-header>
    <mobile-menu-body v-if="object">
      <div>
        <div class="mb-2">
          <p style="font-size: 15px; line-height: 1.3">
            Get your gig promoted to for it to show at the top of listings and
            attract thousands of views.
          </p>
          <!-- Gig details go here -->
          <div class="mb-2" style="font-weight: 800; font-size: 15px">
            Select Package
          </div>

          <ValidationObserver v-slot="{ invalid }">
            <b-form>
              <ErrorAlert v-if="errors" :errors="errors"></ErrorAlert>
              <SuccessAlert v-if="success" :message="success"></SuccessAlert>
              <ValidationProvider
                name="promotion type"
                :rules="{ required: true }"
                v-slot="validationContext"
              >
                <b-form-group
                  id="promotion-type-group"
                  label-for="promotion-type"
                >
                  <b-form-select
                    id="promotion-type"
                    v-model="form.promotion"
                    :disabled="loading"
                    :state="getValidationState(validationContext)"
                  >
                    <b-form-select-option :value="null"
                      >Select Promotion Package</b-form-select-option
                    >
                    <b-form-select-option
                      v-for="offer in offers"
                      :value="offer"
                      :key="offer.id"
                      >{{
                        "GHS " + offer.price + " for " + offer.name
                      }}</b-form-select-option
                    >
                  </b-form-select>
                  <b-form-invalid-feedback id="promotion-type-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-form>
            <div class="mt-2" v-if="activePaymentMethod !== 'MANUAL'">
              <b-alert variant="info" :show="true">
                <div class="mb-2">
                  <b>
                    <b-icon-exclamation-circle></b-icon-exclamation-circle>
                    <span style="font-size: 0.9rem" class="ml-2"
                      >Important</span
                    > </b
                  ><br />
                </div>
                <span style="font-size: 0.8rem"
                  >Once you click pay, you will be redirected to a payment
                  page.</span
                >
                <br /><br />
                <span style="font-size: 0.8rem"
                  >Make sure to enter your phone number <b>WITHOUT</b> the
                  leading 0 as the international format is already
                  applied.</span
                >
                <br /><br />
                <span style="font-size: 0.8rem"
                  >For example if your phone number is <b>0241231231</b>, enter
                  only <b>241231231</b>. Your transaction will <b>fail</b> if
                  you do not do this.</span
                >
              </b-alert>
            </div>
            <div class="text-center">
              <b-button
                @click.prevent="promoteGig"
                type="submit"
                variant="success"
                :disabled="loading || invalid"
                block
              >
                {{
                  loading ? "Loading, please wait..." : "Pay to Promote Service"
                }}
              </b-button>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>
    
    <script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody.vue";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import GigService from "@/services/GigService";
import PromotionService from "@/services/PromotionService";
import ErrorAlert from "@/components/Workmorr/ErrorAlert";
import SuccessAlert from "@/components/Workmorr/SuccessAlert";
import PaymentService from "@/services/PaymentService";
import { formatErrors } from "@/utils/helpers";
import { BIconExclamationCircle } from "bootstrap-vue";

export default {
  name: "RequestOrderView",
  components: {
    MobileMenuBody,
    MobileMenuWrapper,
    MobileMenuHeader,
    ErrorAlert,
    SuccessAlert,
    BIconExclamationCircle,
  },

  data() {
    return {
      loading: false,
      lastPath: "/",
      object: null,
      object_type: null,
      requestSubmitted: false,
      form: {
        promotion: null,
      },
      offers: [],
      errors: {},
      success: null,
      activePaymentMethod: null,
    };
  },
  async mounted() {
    // Get Gig Offer
    GigService.getGig(this.$route.params.gig_id).then((response) => {
      this.object = response.data.gig;
    });

    // Get Subscription Offers
    let response = await PromotionService.getPromotionOffers();
    this.offers = response.data.offers;

    // Get active payment method
    let activePaymentMethodResponse =
        await PaymentService.getActivePaymentMethod();
      this.activePaymentMethod =
        activePaymentMethodResponse.data.active_payment_method;
  },

  methods: {
    onLoad() {
      console.log("loaded");
    },

    async promoteGig() {
      this.loading = true;
      /**
       * 1. Check active payment method.
       * 2. Based on active payment method, initiate manual or inbuilt checkout.
       * 3. Redirect to pay page with object and object id - Concatenate gig id and promotion id.
       */
     

      if (this.activePaymentMethod == "MANUAL") {
        PaymentService.initiateManualPayment({
          object_type: "promotion",
          object_id: this.form.promotion.id,
          object_additional: this.object.id,
        })
          .then((response) => {
            this.$router.push(
              "/pay/promotion/" +
                this.form.promotion.id +
                "_" +
                this.object.id +
                "/" +
                response.data.reference
            );
          })
          .catch((error) => {
            this.errors = formatErrors(error);
          })
          .finally(() => (this.loading = false));
      } else {
        PaymentService.initiatePayment({
          object_type: "promotion",
          object_id: this.form.promotion.id,
          object_additional: this.object.id,
        })
          .then((response) => {
            PaymentService.getTransaction(response.data.reference).then(
              (response) => {
                let iFrameURL = response.data.transaction.checkout_url;
                // Go to checkout
                window.location.href = iFrameURL;
              }
            );
          })
          .catch((error) => {
            this.errors = formatErrors(error);
          })
          .finally(() => (this.loading = false));
      }
    },

    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.lastPath = from;
      });
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
    
    <style scoped>
</style>