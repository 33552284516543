import { CaretakerApiService } from "@/services/CaretakerAPIService";
import {ApiService} from "@/services/APIService";
import store from '@/store'

export default {
    getGigs(
        page = 1,
        owned = false,
        recordsPerPage = 16,
        type = 'records',
        search = null,
        category = null,
        distance = false,
        favorited = false,
        gig_type = null
    ){
        // Generate url
        let url = 'api/gigs?';

        // Mandatory parameters
        url += 'page=' + page + '&';
        url += 'owned=' + owned + '&';
        url += 'favorited=' + favorited + '&';
        url += 'records_per_page=' + recordsPerPage + '&';
        url += 'gig_type=' + gig_type + '&';
        url += 'type=' + type;

        // Optional parameters
        if (search){
            url += '&search=' + search;
        }

        if (category){
            url += '&category=' + category;
        }

        if (distance){
            url += '&distance=' + distance;
        }

        if (store.getters['user/user'] !== null){
            return ApiService.get(url);
        }else{
            return CaretakerApiService.get(url);
        }

    },

    getGig(gig_id){
        if ( store.getters['user/user'] ){
            return ApiService.get("/api/gigs/" + gig_id)
        } else {
            return CaretakerApiService.get("/api/gigs/" + gig_id)
        }

    },

    performGigAction(action, gig_id){
        return ApiService.get("/api/gigs/" + action + "/" + gig_id);
    },

    favoriteGig(gig_id){
        return ApiService.get("/api/gigs/favorite/" + gig_id);
    },

    deleteGigImage(gig_id, image_id){
        return ApiService.get("/api/gigs/update/" + gig_id + "/images/delete/" + image_id);
    },

    getGigCategories(){
        return CaretakerApiService.get("/api/gigs/categories");
    },

    completeReview(payload){
        return ApiService.post("/api/reviews/complete", payload);
    },

    createOffer(payload){
        return ApiService.post("/api/offers/create", payload);
    },

    getOffer(offer_id){
        return ApiService.get("/api/offers/" + offer_id);
    },

    addGig(payload){
        return ApiService.post("/api/gigs/add", payload, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    },

    updateGig(payload){
        return ApiService.post("/api/gigs/update/" + payload.get('id'), payload, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    }
}
