<template>
  <mobile-menu-wrapper>
    <mobile-menu-header
      title=""
      back-title=""
      back-route-link=""
    ></mobile-menu-header>
    <mobile-menu-body>
      <div style="text-align: center" class="mt-5 mb-5">
        <div style="font-size: 3rem; text-align: center">
          <b-icon-check-circle
            v-if="paymentStatus == 'success'"
            variant="success"
          ></b-icon-check-circle>
        </div>
        <div style="font-size: 3rem; text-align: center">
          <b-icon-exclamation-circle
            v-if="paymentStatus == 'pending'"
            variant="success"
          ></b-icon-exclamation-circle>
        </div>
        <div style="font-size: 3rem; text-align: center">
          <b-icon-exclamation-circle
            v-if="paymentStatus == 'error'"
            variant="danger"
          ></b-icon-exclamation-circle>
        </div>
        <div v-if="object_type === 'offer'">
          <h6 class="mb-1">Gig Payment Submitted</h6>
          <div style="font-size: 0.9rem" class="mb-3">
            We will notify you once we have completed processing your payment.
          </div>
          <div class="row">
            <div class="col-6 pr-1">
              <b-button
                block
                variant="primary"
                :to="
                  '/gigs/view/' +
                  this.object.gig.id +
                  '/' +
                  this.object.gig.slug
                "
              >
                <b>Back To Gig</b>
              </b-button>
            </div>
            <div class="col-6 pl-1">
              <b-button
                block
                variant="success"
                :to="'/account/messages/0/' + this.object.seller.username"
              >
                <b>Message Seller</b>
              </b-button>
            </div>
          </div>
        </div>
        <div
          v-if="object_type === 'subscription' || object_type === 'promotion'"
          class="mt-2"
        >
          <h6 v-if="paymentStatus == 'error'" class="mb-1">
            Payment Cancelled
          </h6>
          <h6 v-if="paymentStatus == 'success'" class="mb-1">
            Payment Successful
          </h6>
          <h6 v-if="paymentStatus == 'pending'" class="mb-1">
            Payment Pending
          </h6>
          <div
            v-if="paymentStatus == 'error'"
            style="font-size: 0.9rem"
            class="mb-3"
          >
            Your payment was unsuccessful, please retry.
          </div>
          <div
            v-if="paymentStatus == 'success'"
            style="font-size: 0.9rem"
            class="mb-3"
          >
            Thank you for choosing Workmorr.
          </div>
          <div
            v-if="paymentStatus == 'pending'"
            style="font-size: 0.9rem; line-height: 1.3;"
            class="mb-3"
          >
            Your payment is pending verification <br>You will be notified once payment is verified.
          </div>
          <div class="row">
            <div class="col-12 pr-1">
              <b-button
                v-if="object_type === 'subscription'"
                block
                variant="primary"
                @click="$router.push('/account/subscription')"
              >
                <b>Back To Subscriptions</b>
              </b-button>
              <b-button
                v-if="object_type === 'promotion'"
                block
                variant="primary"
                @click="$router.push('/')"
              >
                <b>Back To Home</b>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>

<script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import { BIconCheckCircle, BIconExclamationCircle } from "bootstrap-vue";
import GigService from "@/services/GigService";
import PaymentService from "@/services/PaymentService";

export default {
  name: "ConfirmPaymentView",
  components: {
    MobileMenuBody,
    MobileMenuWrapper,
    MobileMenuHeader,
    BIconCheckCircle,
    BIconExclamationCircle,
  },
  data() {
    return {
      object: null,
      object_type: null,
      object_id: null,
      paymentStatus: null,
    };
  },

  mounted() {
    this.object_type = this.$route.params.object_type;
    this.object_id = this.$route.params.object_id;
    // this.getObject(this.object_type, this.object_id);
    if (this.$route.query.depositId) {
      if (this.$route.query.cancel == "true") {
        this.paymentStatus = "error";
      } else {
        // Check for transaction status.
        PaymentService.getTransaction(this.$route.query.depositId).then(
          (response) => {
            // Check transactions status
            if (response.data.transaction.status_code == "CMPLTD") {
              this.paymentStatus = "success";
            } else {
              this.paymentStatus = "error";
            }
          }
        );
      }
    } else {
      // Manual payment
      this.paymentStatus = "pending";
    }
  },

  methods: {
    getObject(object_type, object_id) {
      switch (object_type) {
        case "offer":
          GigService.getOffer(object_id).then((response) => {
            this.object = response.data.offer;
          });
          break;
        case "subscription":
          // Not necessary.
          break;
      }
    },
  },
};
</script>

<style scoped>
</style>