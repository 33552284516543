<template>
  <tablet-menu-wrapper>
    <mobile-menu-header
      title="Manage Pages"
      back-title="Back to Dashboard"
      back-route-link="/manager/dashboard"
    >
      <b-button
        to="/manager/pages/add"
        size="sm"
        variant="primary"
        class="font-weight-bold mr-1 mt-2"
      >
        <span style="font-size: 0.8rem">Add Page</span>
      </b-button>
    </mobile-menu-header>
    <tablet-menu-body>
      <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
      <div v-else id="infinite-list-tablet-view">
        <div v-if="pages.length === 0 && !loading.page">
          <CenterMessage message="No pages found."></CenterMessage>
        </div>
        <div v-else>
          <div v-if="!loading.page" class="spread-cols-to-ends mb-1">
            <div>
              <b-form-input
                placeholder="Search for Page"
                size="sm"
                v-model="search"
              >
              </b-form-input>
            </div>
            <div class="mt-3">
              <span
                >Showing page {{ pagination.currentPage }} of
                {{ pagination.totalPages }}.</span
              >
            </div>
          </div>
          <b-table-lite
            small
            hover
            v-if="!loading.page"
            :items="pages"
            :fields="fields"
          >
            <template #cell(status)="data">
              <b-badge v-if="data.item.is_published" variant="success"
                >Published</b-badge
              >
              <b-badge v-else variant="warning" style="color: white"
                >Draft</b-badge
              >
            </template>
            <template #cell(category)="data">
              {{ data.item.category.description }}
            </template>
            <template #cell(action)="data">
              <router-link :to="'/read/' + data.item.id + '/' + data.item.slug">
                <b-icon-search variant="success" size="sm"> </b-icon-search>
              </router-link>
              <router-link :to="'/manager/pages/view/' + data.item.id">
                <b-icon-pencil-square class="ml-1" variant="primary" size="sm">
                </b-icon-pencil-square>
              </router-link>
            </template>
          </b-table-lite>
          <nav>
            <ul class="pagination justify-content-center">
              <li v-if="pagination.currentPage > 1" class="page-item">
                <a
                  class="page-link"
                  href="#"
                  aria-label="Previous"
                  @click.prevent="changePage(pagination.currentPage - 1)"
                >
                  <span aria-hidden="true">«</span>
                </a>
              </li>
              <li
                class="page-item"
                v-for="page in pagination.totalPages"
                :key="page"
                :class="{ active: page == pagination.currentPage }"
              >
                <a
                  v-if="
                    page < 3 ||
                    page > pagination.totalPages - 3 ||
                    (page < pagination.currentPage + 3 &&
                      page > pagination.currentPage - 3)
                  "
                  class="page-link"
                  @click.prevent="changePage(page)"
                  >{{ page }}</a
                >
              </li>
              <li
                v-if="pagination.currentPage < pagination.totalPages"
                class="page-item"
              >
                <a
                  class="page-link"
                  aria-label="Next"
                  @click.prevent="changePage(pagination.currentPage + 1)"
                >
                  <span aria-hidden="true">»</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </tablet-menu-body>
  </tablet-menu-wrapper>
</template>

<script>
import TabletMenuBody from "@/components/Workmorr/MobileMenu/TabletLayout/TabletMenuBody";
import TabletMenuWrapper from "@/components/Workmorr/MobileMenu/TabletLayout/TabletMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import CustomLoader from "@/components/Workmorr/CustomLoader";
import { BIconSearch, BTableLite, BIconPencilSquare } from "bootstrap-vue";
import CenterMessage from "@/components/Workmorr/CenterMessage";
import ManagePagesService from "@/services/manager/ManagePagesService";

export default {
  name: "ManagePagesView",
  components: {
    CustomLoader,
    TabletMenuBody,
    TabletMenuWrapper,
    MobileMenuHeader,
    BTableLite,
    BIconSearch,
    CenterMessage,
    BIconPencilSquare,
  },

  data() {
    return {
      loading: {
        page: true,
        records: false,
      },
      fields: ["id", "title", "category", "status", "action"],
      pages: [],
      search: "",
      pagination: {
        currentPage: 1,
        perPage: 12,
        totalRows: 1000, // Placeholder so get is run at least once
        totalPages: 1,
      },
    };
  },

  async mounted() {
    await this.getPages();
  },

  methods: {
    changePage(page) {
      this.pagination.currentPage = page;
      this.getPages();
    },

    async getPages() {
        this.loading.page = true;
        let response = await ManagePagesService.getPages(
          this.pagination.perPage,
          this.pagination.currentPage,
          this.search
        );
        this.pages = response.data.pages.data;
        this.pagination.totalRows = response.data.pages.total;
        this.pagination.totalPages = response.data.pages.last_page;
        this.loading.page = false;
      
    },
  },

  watch: {
    search() {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(
        function () {
          (this.pages = []), this.getPages();
        }.bind(this),
        1000
      );
    },
  },
};
</script>

<style scoped>
</style>