<template>
  <mobile-menu-wrapper>
    <mobile-menu-header
      title="Payment Account Settings"
      back-title="Back to Profile"
      back-route-link="/account/profile"
    ></mobile-menu-header>
    <mobile-menu-body>
      <ValidationObserver v-slot="{ invalid }">
        <b-form>
          <ErrorAlert v-if="errors" :errors="errors"></ErrorAlert>
          <SuccessAlert v-if="success" :message="success"></SuccessAlert>
          <ValidationProvider
            name="account type"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <b-form-group
              id="account-type-group"
              label="Account Type"
              label-for="account-type"
            >
              <b-form-select
                id="account-type"
                v-model="form.payment_account_type_code"
                :options="options"
                :disabled="loading.form"
                :state="getValidationState(validationContext)"
              ></b-form-select>
              <b-form-invalid-feedback id="account-type-live-feedback">{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            name="institution name"
            :rules="{ required: true, min: 2 }"
            v-slot="validationContext"
          >
            <b-form-group
              id="institution-name-group"
              label="Institution Name"
              label-for="institution-name"
            >
              <b-form-input
                id="institution-name"
                v-model="form.payment_institution_name"
                placeholder="Example. Bank of Africa"
                :disabled="loading.form"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback id="institution-name-live-feedback">{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider
            name="institution code"
            :rules="{ required: false, min: 2, max: 20 }"
            v-slot="validationContext"
          >
            <b-form-group
              id="institution-code-group"
              label="Institution Code"
              label-for="institution-code"
              :description="'Optional. Only required for Banks i.e. Routing Code'"
            >
              <b-form-input
                id="institution-code"
                v-model="form.payment_institution_code"
                placeholder="Example. 01XXXXXXX"
                :disabled="loading.form"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback id="institution-code-live-feedback">{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider
            name="currency"
            :rules="{ required: true, min: 2 }"
            v-slot="validationContext"
          >
            <b-form-group
              id="currency-group"
              label="Currency"
              label-for="currency"
            >
              <b-form-input
                id="currency"
                v-model="form.payment_account_currency"
                placeholder="Example. Ghana Cedi"
                :disabled="loading.form"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback id="currency-live-feedback">{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider
            name="account number"
            :rules="{ required: true, min: 2 }"
            v-slot="validationContext"
          >
            <b-form-group
              id="account-number-group"
              label="Account Number"
              label-for="account-no"
            >
              <b-form-input
                id="account-no"
                v-model="form.payment_account_no"
                placeholder="Example. 024XXXXXXX"
                :disabled="loading.form"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback id="account-number-live-feedback">{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider
            name="account name"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <b-form-group
              id="account-name-group"
              label="Name on Account"
              label-for="account-name"
              :description="'The name on this account must match the name on your ID document for verification to be successful.'"
            >
              <b-form-input
                id="account-name"
                v-model="form.payment_name_on_account"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback id="account-name-live-feedback">{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
          <div class="text-center">
            <b-button
              @click.prevent="onSubmit"
              type="submit"
              variant="success"
              :disabled="loading || invalid"
            >
              {{
                loading
                  ? "Loading, please wait..."
                  : "Update Payment Account Details"
              }}
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>

<script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import ErrorAlert from "@/components/Workmorr/ErrorAlert";
import SuccessAlert from "@/components/Workmorr/SuccessAlert";
import { formatErrors } from "@/utils/helpers";
import UserService from "@/services/UserService";

export default {
  name: "SettlementAccountSettingsView",
  components: {
    MobileMenuBody,
    MobileMenuWrapper,
    MobileMenuHeader,
    ErrorAlert,
    SuccessAlert,
  },
  data() {
    return {
      loading: false,
      options: [{ value: null, text: "Select Account Type" }],
      form: {
        payment_account_type_code: "",
        payment_account_currency: "",
        payment_institution_name: "",
        payment_institution_code: "",
        payment_account_no: "",
        payment_name_on_account: "",
      },
      errors: {},
      success: null,
    };
  },

  async mounted() {
    let response = await UserService.getAccountOptions();
    response.data.types.map(
      function (type) {
        this.options.push({
          value: type.code,
          text: type.name,
        });
      }.bind(this)
    );

    let user = this.$store.getters["user/user"];
    this.form.payment_account_type_code = user.payment_detail.account_type_code;
    this.form.payment_account_currency = user.payment_detail.account_currency;
    this.form.payment_institution_name = user.payment_detail.institution_name;
    this.form.payment_institution_code = user.payment_detail.institution_code;
    this.form.payment_account_no = user.payment_detail.account_no;
    this.form.payment_name_on_account = user.payment_detail.name_on_account;
  },

  methods: {
    onSubmit() {
      this.loading = true;
      this.$store
        .dispatch("user/updateDetails", {
          payment_account_type_code: this.form.payment_account_type_code,
          payment_account_currency: this.form.payment_account_currency,
          payment_institution_name: this.form.payment_institution_name,
          payment_institution_code: this.form.payment_institution_code,
          payment_account_no: this.form.payment_account_no,
          payment_name_on_account: this.form.payment_name_on_account,
          endpoint: "/settlement/update",
        })
        .then((response) => {
          // Clear errors
          this.errors = {};
          this.success = response.message;

          // Update user
          let user = this.$store.getters["user/user"];
          this.form.payment_account_type_code =
            user.payment_detail.account_type_code;
          this.form.payment_account_currency =
            user.payment_detail.account_currency;
          this.form.payment_institution_name =
            user.payment_detail.institution_name;
          this.form.payment_institution_code =
            user.payment_detail.institution_code;
          this.form.payment_account_no = user.payment_detail.account_no;
          this.form.payment_name_on_account =
            user.payment_detail.name_on_account;
        })
        .catch((error) => {
          // Clear success
          this.success = null;
          this.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<style scoped>
</style>