<template>
  <tablet-menu-wrapper>
    <mobile-menu-header
      title="Manage Messages"
      back-title="Back to Dashboard"
      back-route-link="/manager/dashboard"
    ></mobile-menu-header>
    <tablet-menu-body>
      <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
      <div v-else id="infinite-list-tablet-view">
        <div v-if="messages.length === 0 && !loading.page">
          <CenterMessage message="No flagged messages."></CenterMessage>
        </div>
        <div v-else>
          <div v-if="!loading.page" class="spread-cols-to-ends mb-1">
            <div></div>
            <div class="mt-3">
              <span
                >Showing page {{ pagination.currentPage }} of
                {{ pagination.totalPages }}.</span
              >
            </div>
          </div>
          <b-table-lite
            small
            hover
            v-if="!loading.page"
            :items="messages"
            :fields="fields"
          >
            <template #cell(sender)="data">
              {{ data.item.sender.username }}
            </template>
            <template #cell(recipient)="data">
              {{ data.item.recipient.username }}
            </template>
            <template #cell(action)="data">
              <b-icon-check
                @click="reviewMessage('approve', data.item.id)"
                variant="success"
                class="mouse-pointer large"
              >
              </b-icon-check>
              <b-icon-x
                @click="reviewMessage('censor', data.item.id)"
                variant="danger"
                class="mouse-pointer large"
              >
              </b-icon-x>
            </template>
          </b-table-lite>
          <nav>
            <ul class="pagination justify-content-center">
              <li v-if="pagination.currentPage > 1" class="page-item">
                <a
                  class="page-link"
                  href="#"
                  aria-label="Previous"
                  @click.prevent="changePage(pagination.currentPage - 1)"
                >
                  <span aria-hidden="true">«</span>
                </a>
              </li>
              <li
                class="page-item"
                v-for="page in pagination.totalPages"
                :key="page"
                :class="{ active: page == pagination.currentPage }"
              >
                <a
                  v-if="
                    page < 3 ||
                    page > pagination.totalPages - 3 ||
                    (page < pagination.currentPage + 3 &&
                      page > pagination.currentPage - 3)
                  "
                  class="page-link"
                  @click.prevent="changePage(page)"
                  >{{ page }}</a
                >
              </li>
              <li
                v-if="pagination.currentPage < pagination.totalPages"
                class="page-item"
              >
                <a
                  class="page-link"
                  aria-label="Next"
                  @click.prevent="changePage(pagination.currentPage + 1)"
                >
                  <span aria-hidden="true">»</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </tablet-menu-body>
  </tablet-menu-wrapper>
</template>

<script>
import TabletMenuBody from "@/components/Workmorr/MobileMenu/TabletLayout/TabletMenuBody";
import TabletMenuWrapper from "@/components/Workmorr/MobileMenu/TabletLayout/TabletMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import CustomLoader from "@/components/Workmorr/CustomLoader";
import { BIconCheck, BIconX, BTableLite } from "bootstrap-vue";
import CenterMessage from "@/components/Workmorr/CenterMessage";
import ManageMessagesService from "@/services/manager/ManageMessagesService";
import { removeObjectWithId } from "@/utils/helpers";

export default {
  name: "ManageMessagesView",
  components: {
    CustomLoader,
    TabletMenuBody,
    TabletMenuWrapper,
    MobileMenuHeader,
    BTableLite,
    BIconCheck,
    BIconX,
    CenterMessage,
  },
  data() {
    return {
      loading: {
        page: true,
        records: false,
      },
      fields: ["id", "message", "sender", "recipient", "action"],
      messages: [],
      search: "",
      pagination: {
        currentPage: 1,
        perPage: 12,
        totalRows: 1000, // Placeholder so get is run at least once
        totalPages: 1,
      },
    };
  },

  async mounted() {
    await this.getFlaggedMessages();
  },

  methods: {
    changePage(page) {
      this.pagination.currentPage = page;
      this.getFlaggedMessages();
    },

    async getFlaggedMessages() {
      this.loading.page = true;
      let response = await ManageMessagesService.getFlaggedMessages(
        this.pagination.perPage,
        this.pagination.currentPage,
        this.search
      );
      this.messages = response.data.messages.data;
      this.pagination.totalRows = response.data.messages.total;
      this.pagination.totalPages = response.data.messages.last_page;
      this.loading.page = false;
    },

    reviewMessage(action, message_id) {
      ManageMessagesService.reviewFlaggedMessage({
        action: action,
        message_id: message_id,
      }).then(() => {
        this.messages = removeObjectWithId(this.messages, message_id);
      });
    },
  },
};
</script>

<style scoped>
.large {
  font-size: 1.4rem;
}
</style>