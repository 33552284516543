import SubscriptionService from "@/services/SubscriptionService";

export default {
    namespaced: true,

    state: {
        subscription: null,
    },

    actions: {
        getActiveSubscription({ commit }, payload) {
            return new Promise((resolve, reject) => {
                SubscriptionService.getActiveSubscription(payload)
                    .then(async ({data}) => {
                        // Set active subscription
                        commit('SET_USER_SUBSCRIPTION', data.subscription);
                        resolve(data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },

        confirmSubscriptionPayment({ commit }, payload) {
            return new Promise((resolve, reject) => {
                SubscriptionService.confirmSubscriptionPayment(payload)
                    .then(async ({data}) => {
                        // Set active subscription
                        commit('SET_USER_SUBSCRIPTION', data.subscription);
                        resolve(data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },

    },

    mutations: {
        SET_USER_SUBSCRIPTION(state, data) {
            state.subscription = data;
        },
    },

    getters: {
        subscription: (state) => state.subscription,
    },
}