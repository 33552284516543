<template>
  <mobile-menu-wrapper :portrait="true">
    <mobile-menu-header
        title='Report Issue'
        :back-title="prevRoute && prevRoute.meta.title ? 'Back to ' + prevRoute.meta.title : 'Back'"
        :back-route-link="prevRoute ? prevRoute.path : '/'"
    ></mobile-menu-header>
    <mobile-menu-body>
      <ErrorAlert v-if="errors" :errors="errors"></ErrorAlert>
      <SuccessAlert v-if="success" :message="success"></SuccessAlert>
      <div>
        <b-form-group
            id="report-category-group"
            label="Category of Report"
            label-for="report-category"
        >
          <b-form-select v-model="report.category_id" :disabled="loading">
            <b-form-select-option value="">Select Report Category</b-form-select-option>
            <b-form-select-option v-for="option in options.category" :key="option.value" :value="option.value">{{ option.text }}</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group
            label="Description">
          <editor
              :disabled="loading"
              v-model="report.description"
              api-key="id8huwbtsicpgcsq9digruov36p4d48xrd8zgpfyk9gvgyzn"
              :init="{
             height: 150,
             menubar: false,
             plugins: [
               'advlist autolink lists link image charmap print preview anchor',
               'searchreplace visualblocks code fullscreen',
               'insertdatetime media table paste code help wordcount'
             ],
             toolbar:
               'undo redo | formatselect | bold italic backcolor | \
               alignleft aligncenter alignright alignjustify | \
               bullist numlist outdent indent | removeformat '
           }"
          />
        </b-form-group>
        <b-form-group
            label="Supporting files or images (Optional)">
          <b-form-file
              multiple
              v-model="report.files"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              ref="report-files"
          ></b-form-file>
        </b-form-group>
        <b-button block @click.prevent="onSubmit" type="submit" variant="danger" :disabled="loading">
          {{ loading ? 'Loading, please wait...' : 'Report Issue' }}
        </b-button>
      </div>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>

<script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import ErrorAlert from "@/components/Workmorr/ErrorAlert";
import SuccessAlert from "@/components/Workmorr/SuccessAlert";
import {formatErrors} from "@/utils/helpers";
import ReportService from "@/services/ReportService";
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "ReportView",
  components: {
    MobileMenuBody,
    MobileMenuWrapper,
    MobileMenuHeader,
    ErrorAlert,
    SuccessAlert,
    Editor,
  },
  data(){
    return {
      prevRoute: null,
      loading: false,
      success: null,
      errors: {},
      report: {
        category_id: '',
        description: '',
        files: null
      },
      options: {
        category: []
      }
    }
  },

  async mounted() {
    let response = await ReportService.getReportCategories();
    response.data.categories.map(function(category) {
      this.options.category.push({
        value: category.id,
        text: category.name
      });
    }.bind(this))
  },

  methods: {
    onSubmit(){
      this.loading = true;

      const formData = new FormData();
      formData.append('category_id', this.report.category_id);
      formData.append('description', this.report.description);
      if (this.report.files){
        for( let i = 0; i < this.report.files.length; i++ ) {
          let file = this.$refs['report-files'].files[i];
          formData.append('files[' + i + ']', file);
        }
      }

      ReportService.makeReport(formData).then(response => {
        // Clear errors
        this.errors = {};
        this.success = response.data.message;

        this.report.category_id = '';
        this.report.description = '';
        this.report.files = null;
        this.$refs['report-files'].reset()

      }).catch((error) => {
        // Clear success
        this.success = null;
        this.errors = formatErrors(error);
      }).finally(() => {
        this.loading = false;
      });
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
}
</script>

<style scoped>

</style>