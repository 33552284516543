<template>
  <mobile-menu-wrapper :portrait="true">
    <mobile-menu-header
        title='Reset Password'
        back-title="Back to Login"
        back-route-link="/login"
    ></mobile-menu-header>
    <mobile-menu-body>
      <ErrorAlert v-if="errors" :errors="errors"></ErrorAlert>
      <SuccessAlert v-if="success" :message="success"></SuccessAlert>
      <div v-if="valid_code">
        <b-form-group
            id="login-email-group"
            label="Password"
            label-for="password"
        >
          <b-form-input
              id="password"
              v-model="form.new_password"
              type="password"
              placeholder="Enter new password"
              :disabled="loading"
          ></b-form-input>
        </b-form-group>
        <b-form-group
            id="login-email-group"
            label="Password"
            label-for="password_confirmation"
        >
          <b-form-input
              id="password_confirmation"
              v-model="form.new_password_confirmation"
              type="password"
              placeholder="Confirm new password"
              :disabled="loading"
          ></b-form-input>
        </b-form-group>
        <b-button block @click.prevent="onSubmit" type="submit" variant="success" :disabled="loading || success !== null">
          {{ loading ? 'Loading, please wait...' : 'Reset Password' }}
        </b-button>
      </div>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>

<script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import ErrorAlert from "@/components/Workmorr/ErrorAlert";
import SuccessAlert from "@/components/Workmorr/SuccessAlert";
import UserService from "@/services/UserService";
import {formatErrors} from "@/utils/helpers";

export default {
  name: "ResetPasswordView",
  components: {MobileMenuBody, MobileMenuWrapper, MobileMenuHeader, ErrorAlert, SuccessAlert},
  data(){
    return {
      loading: false,
      success: null,
      errors: {},
      form: {
        new_password: '',
        new_password_confirmation: '',
      },
      valid_code: false,
    }
  },

  async mounted() {
    await UserService.validateResetCode(this.$route.params.reset_code)
        .then(() => {
          this.valid_code = true;
        }).catch(() => {
          this.errors = [
              "Sorry, the reset link has expired or has been used."
          ]
        });
  },

  methods: {
    onSubmit(){
      this.loading = true;
      UserService.resetPassword({
        reset_code: this.$route.params.reset_code,
        new_password: this.form.new_password,
        new_password_confirmation: this.form.new_password_confirmation,
        device_name: navigator.userAgent
      })
          .then(async response => {
            // Clear errors
            this.errors = {};
            this.success = response.data.message;
          }).catch((error) => {
        // Clear success
        this.success = null;
        this.errors = formatErrors(error);
      }).finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>