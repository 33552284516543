import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from "@/store";
import LoginOrRegisterView from "@/views/authentication/LoginOrRegisterView";
import AddGigView from "@/views/gigs/AddGigView";
import MyProfileView from "@/views/account/MyProfileView";
import MyOrdersView from "@/views/account/MyOrdersView";
import MyMoneyView from "@/views/account/MyMoneyView";
import MyMessagesView from "@/views/account/MyMessagesView";
import MyGigsView from "@/views/account/MyGigsView";
import UsernameAndEmailSettingsView from "@/views/account/profile/UsernameAndEmailSettingsView";
import PasswordSettingsView from "@/views/account/profile/PasswordSettingsView";
import IdentityDetailsView from "@/views/account/profile/IdentityDetailsView";
import SettlementAccountSettingsView from "@/views/account/profile/SettlementAccountSettingsView";
import LocationSettingsView from "@/views/account/profile/LocationSettingsView";
import MySubscriptionView from "@/views/account/MySubscriptionView";
import MyAccountView from "@/views/account/MyAccountView";
import LogoutView from "@/views/authentication/LogoutView";
import ChatView from "@/views/account/chat/ChatView";
import MyFavoritesView from "@/views/account/MyFavoritesView";
import ReviewOrderView from "@/views/ReviewOrderView";
import ViewGigView from "@/views/gigs/ViewGigView";
import EditGigView from "@/views/gigs/EditGigView";
import StripeTestView from "@/views/testing/StripeTestView";
import ManagerDashboardView from "@/views/manager/ManagerDashboardView";
import ManageCustomersView from "@/views/manager/ManageCustomersView";
import ManageGigsView from "@/views/manager/ManageGigsView";
import ManageSubscriptionsView from "@/views/manager/ManageSubscriptionsView";
import ManageMessagesView from "@/views/manager/ManageMessagesView";
import { isUserManager } from "@/utils/helpers";
import ManageOrdersView from "@/views/manager/ManageOrdersView";
import ManagePagesView from "@/views/manager/ManagePagesView";
import ViewCustomerView from "@/views/manager/customers/ViewCustomerView";
import AddPageView from "@/views/manager/pages/AddPageView";
import ViewPageView from "@/views/manager/pages/ViewPageView";
import PageView from "@/views/PageView";
import OrderView from "@/views/account/orders/OrderView";
import CreateGigOfferView from "@/views/gigs/CreateGigOfferView";
import NotFound404View from "@/views/NotFound404View";
import ForgotPasswordView from "@/views/password/ForgotPasswordView";
import ResetPasswordView from "@/views/password/ResetPasswordView";
import ConfirmPaymentView from "@/views/pay/ConfirmPaymentView";
import InitiatePaymentView from "@/views/pay/InitiatePaymentView";
import VerifyView from "@/views/authentication/VerifyView";
import ReportView from "@/views/ReportView";
import ManageReportsView from "@/views/manager/ManageReportsView";
import ViewReportView from "@/views/manager/reports/ViewReportView";
import ManageTransactionsView from "@/views/manager/ManageTransactionsView";
import RequestOrderView from '@/views/account/orders/RequestOrderView.vue';
import PromoteGigView from '@/views/gigs/PromoteGigView.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Save time, earn money.',
      requiresAuth: false,
    }
  },
  {
    path: '/404',
    name: '404',
    component: NotFound404View,
    meta: {
      title: 'Oops, Page not Found',
    }
  },
  {
    path: '/report',
    name: 'report',
    component: ReportView,
    meta: {
      title: 'Make a Report',
      requiresAuth: true,
    }
  },
  {
    path: '/read/:id/:slug(.*)',
    name: 'read',
    component: PageView,
    meta: {
      title: 'Workmorr',
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginOrRegisterView,
    meta: {
      title: 'Login or Register',
    }
  },
  {
    path: '/password/forgot',
    name: 'initiate-forgot-password',
    component: ForgotPasswordView,
    meta: {
      title: 'Forgot Password',
    }
  },
  {
    path: '/password/reset/:reset_code',
    name: 'reset-forgotten-password',
    component: ResetPasswordView,
    meta: {
      title: 'Reset Password',
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutView,
    meta: {
      title: 'Logout',
    }
  },
  {
    path: '/v/:user_id/:verification_code?',
    name: 'verify-phone',
    component: VerifyView,
    meta: {
      title: 'Verify Phone',
    }
  },
  // Review Routes
  {
    path: '/review/complete',
    name: 'complete-review',
    component: ReviewOrderView,
    meta: {
      title: 'Complete Review',
      requiresAuth: true,
    }
  },
  // Gig Routes
  {
    path: '/gigs/add',
    name: 'add-gig',
    component: AddGigView,
    meta: {
      title: 'Add Gig',
      requiresAuth: true,
    }
  },
  {
    path: '/gigs/edit/:gig_id',
    name: 'edit-gig',
    component: EditGigView,
    meta: {
      title: 'Edit Gig',
      requiresAuth: true,
    }
  },
  {
    path: '/gigs/view/:gig_id/:slug',
    name: 'view-gig',
    component: ViewGigView,
    meta: {
      title: 'View Gig',
      requiresAuth: false,
    }
  },
  {
    path: '/gigs/promote/:gig_id/',
    name: 'promote-gig',
    component: PromoteGigView,
    meta: {
      title: 'Promote Gig',
      requiresAuth: true,
    }
  },
  {
    path: '/gigs/offer/:gig_id/create',
    name: 'create-gig-offer',
    component: CreateGigOfferView,
    meta: {
      title: 'Create Gig Offer',
      requiresAuth: true,
    }
  },
  // Order Routes
  {
    path: '/orders/request/:offer_id',
    name: 'request-order',
    component: RequestOrderView,
    meta: {
      title: 'Request Order',
      requiresAuth: true,
    }
  },
  // Account Routes
  {
    path: '/account',
    name: 'my-account',
    component: MyAccountView,
    meta: {
      title: 'Account Settings',
      requiresAuth: true,
    }
  },
  {
    path: '/account/gigs',
    name: 'my-gigs',
    component: MyGigsView,
    meta: {
      title: 'Your Gigs',
      requiresAuth: true,
    }
  },
  {
    path: '/account/messages',
    name: 'my-messages',
    component: MyMessagesView,
    meta: {
      title: 'Messages',
      requiresAuth: true,
    }
  },
  // Messages routes
  {
    path: '/account/messages/:chat_id/:username',
    name: 'my-messages-chat',
    component: ChatView,
    meta: {
      title: 'Workmorr',
      requiresAuth: true,
    }
  },

  {
    path: '/account/money',
    name: 'my-money',
    component: MyMoneyView,
    meta: {
      title: 'Your Money',
      requiresAuth: true,
    }
  },
  {
    path: '/account/orders',
    name: 'my-orders',
    component: MyOrdersView,
    meta: {
      title: 'Your Orders',
      requiresAuth: true,
    }
  },
  {
    path: '/account/orders/view/:order_id',
    name: 'view-order',
    component: OrderView,
    meta: {
      title: 'Order #',
      requiresAuth: true,
    }
  },
  {
    path: '/account/favorites',
    name: 'my-favorites',
    component: MyFavoritesView,
    meta: {
      title: 'Your Favorites',
      requiresAuth: true,
    }
  },
  {
    path: '/account/subscription',
    name: 'my-subscription',
    component: MySubscriptionView,
    meta: {
      title: 'Your Subscription',
      requiresAuth: true,
    }
  },
  {
    path: '/account/profile',
    name: 'my-profile',
    component: MyProfileView,
    meta: {
      title: 'Profile Settings',
      requiresAuth: true,
    }
  },
  // Profile routes
  {
    path: '/account/profile/username-and-email-settings',
    name: 'my profile',
    component: UsernameAndEmailSettingsView,
    meta: {
      title: 'User Settings',
      requiresAuth: true,
    }
  },
  {
    path: '/account/profile/password-settings',
    name: 'password-settings',
    component: PasswordSettingsView,
    meta: {
      title: 'Password Settings',
      requiresAuth: true,
    }
  },
  {
    path: '/account/profile/identity-details',
    name: 'identity-details',
    component: IdentityDetailsView,
    meta: {
      title: 'Identity Settings',
      requiresAuth: true,
    }
  },
  {
    path: '/account/profile/settlement-account-settings',
    name: 'settlement-account-settings',
    component: SettlementAccountSettingsView,
    meta: {
      title: 'Payment Account Settings',
      requiresAuth: true,
    }
  },
  {
    path: '/account/profile/location-settings',
    name: 'location-settings',
    component: LocationSettingsView,
    meta: {
      title: 'Location Settings',
      requiresAuth: true,
    }
  },
  // Manager Routes
  {
    path: '/manager/dashboard',
    name: 'manager-dashboard',
    component: ManagerDashboardView,
    meta: {
      title: 'Manager Dashboard',
      requiresAuth: true,
      requiresAdminAuth: true,
    }
  },
  {
    path: '/manager/gigs',
    name: 'manage-gigs',
    component: ManageGigsView,
    meta: {
      title: 'Manage Gigs',
      requiresAuth: true,
      requiresAdminAuth: true,
    }
  },
  {
    path: '/manager/customers',
    name: 'manage-customers',
    component: ManageCustomersView,
    meta: {
      title: 'Manage Customers',
      requiresAuth: true,
      requiresAdminAuth: true,
    }
  },
  {
    path: '/manager/customers/view/:id',
    name: 'manager-view-customer',
    component: ViewCustomerView,
    meta: {
      title: 'View Customer',
      requiresAuth: true,
      requiresAdminAuth: true,
    }
  },
  {
    path: '/manager/messages',
    name: 'manage-messages',
    component: ManageMessagesView,
    meta: {
      title: 'Manage Messages',
      requiresAuth: true,
      requiresAdminAuth: true,
    }
  },
  {
    path: '/manager/subscriptions',
    name: 'manage-subscriptions',
    component: ManageSubscriptionsView,
    meta: {
      title: 'Manage Subscriptions',
      requiresAuth: true,
      requiresAdminAuth: true,
    }
  },
  {
    path: '/manager/transactions',
    name: 'manage-transactions',
    component: ManageTransactionsView,
    meta: {
      title: 'Manage Transactions',
      requiresAuth: true,
      requiresAdminAuth: true,
    }
  },
  {
    path: '/manager/orders',
    name: 'manage-orders',
    component: ManageOrdersView,
    meta: {
      title: 'Manage Orders',
      requiresAuth: true,
      requiresAdminAuth: true,
    }
  },
  {
    path: '/manager/pages',
    name: 'manage-pages',
    component: ManagePagesView,
    meta: {
      title: 'Manage Pages',
      requiresAuth: true,
      requiresAdminAuth: true,
    }
  },
  {
    path: '/manager/pages/add',
    name: 'manager-add-page',
    component: AddPageView,
    meta: {
      title: 'Add Page',
      requiresAuth: true,
      requiresAdminAuth: true,
    }
  },
  {
    path: '/manager/pages/view/:id',
    name: 'manager-view-pages',
    component: ViewPageView,
    meta: {
      title: 'Workmorr -',
      requiresAuth: true,
      requiresAdminAuth: true,
    },
  },
  {
    path: '/manager/reports',
    name: 'manage-reports',
    component: ManageReportsView,
    meta: {
      title: 'Manage Reports',
      requiresAuth: true,
      requiresAdminAuth: true,
    }
  },
  {
    path: '/manager/reports/view/:id',
    name: 'manager-view-report',
    component: ViewReportView,
    meta: {
      title: 'View Report',
      requiresAuth: true,
      requiresAdminAuth: true,
    }
  },
  // Payment Routes
  {
    path: '/pay/:object_type/:object_id/:checkout_id',
    name: 'initiate-payment',
    component: InitiatePaymentView,
    meta: {
      title: 'Pay',
      requiresAuth: true,
    }
  },

  {
    path: '/paid/:object_type/:object_id',
    name: 'confirm-payment',
    component: ConfirmPaymentView,
    meta: {
      title: 'Paid',
      requiresAuth: true,
    }
  },

  // Test View
  {
    path: '/testing/stripe/pay',
    name: 'stripe-test',
    component: StripeTestView,
    meta: {
      title: 'Stripe Test',
      requiresAuth: true,
      requiresAdminAuth: true,
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const DEFAULT_TITLE = 'Workmorr - Earn more.';
// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = 'Workmorr - ' + to.meta.title || DEFAULT_TITLE;
  });
});

router.beforeEach((to, from, next) => {
  const authUser = store.getters["user/user"];
  const reqAuth = to.matched.some((record) => record.meta.requiresAuth);
  const reqAdminAuth = to.matched.some((record) => record.meta.requiresAdminAuth);
  const loginQuery = { path: "/login", query: { redirect: to.fullPath } };
  const reviewQuery = { path: "/review/complete", query: { redirect: to.fullPath } };
  const homeQuery = { path: "/", query: { redirect: to.fullPath } };

  if (reqAuth && !authUser) {
    next(loginQuery);
  } else if (!reqAuth && !authUser) {
    next();
  } else {
    // User is authenticated. Check for pending review.
    if (to.name !== 'complete-review') {
      let user = store.getters["user/user"];
      if (user.pending_review) {
        next(reviewQuery);
      } else {
        if (reqAuth && authUser && reqAdminAuth) {
          isUserManager() ? next() : next(homeQuery)
        } else {
          next();
        }
      }
    } else {
      if (reqAuth && authUser && reqAdminAuth) {
        isUserManager() ? next() : next(homeQuery)
      } else {
        next();
      }
    }
  }
});

export default router
