<template>
  <div>
    <div class="menu-border div-no-show-scroll shadow-sm mt-1">
      <slot></slot>
      <div class="mt-3 text-center" style="opacity: 80%" v-if="currentRouteName !== 'report' && currentRouteName !== 'manager-view-report' && currentRouteName !==  'verify-phone'">
        <router-link
            to="/report"
            style="text-decoration: none; color: inherit;"
        >
              <span class="font-weight-bold small text-danger">
                <b-icon-exclamation-circle></b-icon-exclamation-circle> Make a Report
              </span>
        </router-link>
      </div>
    </div>
    <BottomFooter></BottomFooter>
  </div>
</template>

<script>
import BottomFooter from "@/components/Workmorr/BottomFooter";
import {BIconExclamationCircle} from "bootstrap-vue";
export default {
  name: "MobileMenuBody",
  inheritAttrs: false, // optional
  components: {
    BottomFooter,
    BIconExclamationCircle,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  }
}
</script>

<style scoped>
  .menu-border {
    border-radius: 10px;
    border: 2px #f6f6f6 solid;
    padding: 1rem;
    position: relative;
    min-height: 250px;
    overflow-y: scroll;
  }


</style>