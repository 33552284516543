var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tablet-menu-wrapper',[_c('mobile-menu-header',{attrs:{"title":"Manage Customers","back-title":"Back to Dashboard","back-route-link":"/manager/dashboard"}}),_c('tablet-menu-body',[(_vm.loading.page)?_c('CustomLoader',{attrs:{"center":true}}):_c('div',{attrs:{"id":"infinite-list-tablet-view"}},[(_vm.customers.length === 0 && !_vm.loading.page)?_c('div',[_c('CenterMessage',{attrs:{"message":"No customers found."}})],1):_c('div',[(!_vm.loading.page)?_c('div',{staticClass:"spread-cols-to-ends mb-2"},[_c('div',[_c('b-form-input',{attrs:{"placeholder":"Search for Customer","size":"sm"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"mt-3"},[_c('span',[_vm._v("Showing page "+_vm._s(_vm.pagination.currentPage)+" of "+_vm._s(_vm.pagination.totalPages)+".")])])]):_vm._e(),(!_vm.loading.page)?_c('b-table-lite',{attrs:{"small":"","hover":"","items":_vm.customers,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(status)",fn:function(data){return [(data.item.status_id === 1)?_c('b-badge',{staticStyle:{"color":"white"},attrs:{"variant":"warning"}},[_vm._v("Inactive")]):_vm._e(),(data.item.status_id === 2)?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v("Live")]):_vm._e()]}},{key:"cell(username)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.username)+" "),(
                data.item.is_kyc_verified && data.item.active_subscription
              )?_c('b-icon-patch-check-fill',{attrs:{"variant":"warning"}}):(data.item.is_kyc_verified)?_c('b-icon-patch-check-fill',{attrs:{"variant":"primary"}}):_vm._e()]}},{key:"cell(kyc_submitted)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.id_photo ? "Yes" : "No")+" ")]}},{key:"cell(verified)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.is_kyc_verified ? "Yes" : "No")+" ")]}},{key:"cell(joined)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.moment(data.item.created_at).format("l"))+" ")]}},{key:"cell(action)",fn:function(data){return [_c('router-link',{attrs:{"to":'/manager/customers/view/' + data.item.id}},[_c('b-icon-search',{attrs:{"variant":"primary","size":"sm"}})],1),(data.item.status_id === 1)?_c('b-icon-check-circle-fill',{staticClass:"mouse-pointer ml-1",attrs:{"variant":"success"},on:{"click":function($event){return _vm.updateCustomerStatus('activate', data.item.id)}}}):_vm._e(),(data.item.status_id === 2)?_c('b-icon-x-circle-fill',{staticClass:"mouse-pointer ml-1",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.updateCustomerStatus('deactivate', data.item.id)}}}):_vm._e()]}}],null,false,449340101)}):_vm._e(),_c('nav',[_c('ul',{staticClass:"pagination justify-content-center"},[(_vm.pagination.currentPage > 1)?_c('li',{staticClass:"page-item"},[_c('a',{staticClass:"page-link",attrs:{"href":"#","aria-label":"Previous"},on:{"click":function($event){$event.preventDefault();return _vm.changePage(_vm.pagination.currentPage - 1)}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("«")])])]):_vm._e(),_vm._l((_vm.pagination.totalPages),function(page){return _c('li',{key:page,staticClass:"page-item",class:{ active: page == _vm.pagination.currentPage }},[(
                  page < 3 ||
                  page > _vm.pagination.totalPages - 3 ||
                  (page < _vm.pagination.currentPage + 3 &&
                    page > _vm.pagination.currentPage - 3)
                )?_c('a',{staticClass:"page-link",on:{"click":function($event){$event.preventDefault();return _vm.changePage(page)}}},[_vm._v(_vm._s(page))]):_vm._e()])}),(_vm.pagination.currentPage < _vm.pagination.totalPages)?_c('li',{staticClass:"page-item"},[_c('a',{staticClass:"page-link",attrs:{"aria-label":"Next"},on:{"click":function($event){$event.preventDefault();return _vm.changePage(_vm.pagination.currentPage + 1)}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("»")])])]):_vm._e()],2)])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }