<template>
  <mobile-menu-wrapper>
    <mobile-menu-header
        title='User'
        back-title="Back to Profile"
        back-route-link="/account/profile"
    ></mobile-menu-header>
    <mobile-menu-body>
      <ValidationObserver v-slot="{ invalid }">
        <b-form>
          <ErrorAlert v-if="errors" :errors="errors"></ErrorAlert>
          <SuccessAlert v-if="success" :message="success"></SuccessAlert>
          <ValidationProvider name="username" :rules="{ required: true, min: 4 }" v-slot="validationContext">
            <b-form-group id="username-group"
                          label="Username"
                          label-for="username"
                          description="This will be your public name on Workmorr. Use a nickname or business name to protect your identity."
            >
              <b-form-input
                  id="username"
                  v-model="form.username"
                  placeholder="Enter username"
                  :disabled="loading"
                  :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback id="username-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider name="email" :rules="{ required: true, email: true }" v-slot="validationContext">
            <b-form-group
                id="email-group"
                label="Email Address"
                label-for="email"
                description="You'll use this email to login always. We will never share your email with anyone else."
            >
              <b-form-input
                  id="email"
                  v-model="form.email"
                  type="email"
                  placeholder="Enter email"
                  :disabled="loading"
                  :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback id="email-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
          <div class="text-center">
            <b-button @click.prevent="onSubmit" type="submit" variant="success" :disabled="loading || invalid">
              {{ loading ? 'Loading, please wait...' : 'Update User Details' }}
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>
<script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import ErrorAlert from "@/components/Workmorr/ErrorAlert";
import SuccessAlert from "@/components/Workmorr/SuccessAlert";
import {formatErrors} from "@/utils/helpers";

export default {
  name: "UsernameAndEmailSettingsView",
  components: {SuccessAlert, MobileMenuBody, MobileMenuWrapper, MobileMenuHeader, ErrorAlert},
  data() {
    return {
      loading: false,
      form: {
        username: '',
        email: '',
      },
      errors: {},
      success: null,
    }
  },

  mounted() {
    let user = this.$store.getters['user/user'];
    this.form.username = user.username;
    this.form.email = user.email;
  },

  methods: {
    onSubmit() {
      this.loading = true;
      this.$store.dispatch('user/updateDetails', {
        username: this.form.username,
        email: this.form.email,
        endpoint: '/update'
      }).then(response => {
        // Clear errors
        this.errors = {};
        this.success = response.message;
      }).catch((error) => {
        // Clear success
        this.success = null;
        this.errors = formatErrors(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  }
}
</script>

<style scoped>

</style>