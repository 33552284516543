import { ApiService } from "@/services/APIService";
import { CaretakerApiService } from "@/services/CaretakerAPIService";

import axios from "axios";

export const AuthClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true, // required to handle the CSRF token
});

export default {

  async generateToken(payload){
    await AuthClient.get("/sanctum/csrf-cookie");
    return AuthClient.post("/api/sanctum/token", {
      email: payload.email,
      password: payload.password,
      device_name: navigator.userAgent
    });
  },

  getAuthenticatedUser() {
    return ApiService.get("/api/customer");
  },

  getPendingActions() {
    return ApiService.get("/api/customer/pending");
  },

  getAccounts() {
    return ApiService.get("/api/customer/accounts");
  },

  register(payload){
    return CaretakerApiService.post("/api/customer/register", {
      name: payload.name,
      username: payload.username,
      country_alpha_2_code: payload.country_alpha_2_code,
      phone: payload.phone,
      email: payload.email,
      password: payload.password,
      password_confirmation: payload.password_confirmation,
      consent: payload.consent,
      device_name: navigator.userAgent
    })
  },

  updateDetails(payload){
    return ApiService.post('/api/customer' + payload.endpoint, payload)
  },

  updateKYCDetails(payload) {
    return ApiService.post("/api/customer/kyc/update", payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },

  initiatePasswordReset(payload){
    return CaretakerApiService.post('/api/password/forgot', payload)
  },

  verifyCustomerByPhone(payload){
    return CaretakerApiService.post('/api/customer/verify', payload)
  },

  validateResetCode(code){
    return CaretakerApiService.get('/api/password/code/' + code + '/validate')
  },

  resetPassword(payload){
    return CaretakerApiService.post('/api/password/reset', payload)
  },

  getCountries(){
    return CaretakerApiService.get('/api/countries')
  },

  getAccountOptions() {
    return ApiService.get("/api/account/options");
  },

};
