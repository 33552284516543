<template>
  <mobile-menu-wrapper>
    <mobile-menu-header
        title='Manage Account'
        back-title="Back to Home"
        back-route-link="/"
    ></mobile-menu-header>
    <mobile-menu-body>
      <mobile-menu-link
          route="/account/profile"
          borderBottom="true"
          title="My Profile"
          icon="person-badge"
      ></mobile-menu-link>
      <mobile-menu-link
          route="/account/messages"
          borderBottom="true"
          title="My Messages"
          icon="mailbox"
      ></mobile-menu-link>
      <mobile-menu-link
          route="/account/gigs"
          borderBottom="true"
          title="My Gigs"
          icon="tools"
      ></mobile-menu-link>
      <mobile-menu-link
          route="/account/orders"
          borderBottom="true"
          title="My Orders"
          icon="briefcase"
      ></mobile-menu-link>
      <mobile-menu-link
          route="/account/favorites"
          borderBottom="true"
          title="My Favorites"
          icon="heart-fill"
      ></mobile-menu-link>
      <mobile-menu-link
          route="/account/money"
          v-if="isUserManager()"
          borderBottom="true"
          title="My Money"
          icon="cash-stack"
      ></mobile-menu-link>
      <mobile-menu-link
          route="/account/subscription"
          borderBottom="true"
          title="My Subscription"
          icon="people-fill"
      ></mobile-menu-link>
      <!-- <mobile-menu-link
          route="/account/settings"
          borderBottom="true"
          title="Settings"
          icon="gear-fill"
      ></mobile-menu-link> -->
      <mobile-menu-link
          v-if="isUserManager()"
          route="/manager/dashboard"
          borderBottom="true"
          title="Manager Area"
          icon="columns-gap"
      ></mobile-menu-link>
      <mobile-menu-link
          route="/logout"
          borderBottom="false"
          title="Logout"
          icon="lock"
      ></mobile-menu-link>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>

<script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import MobileMenuLink from "@/components/Workmorr/MobileMenu/MobileMenuLink";

export default {
  name: "MyAccountView",
  components: {MobileMenuBody, MobileMenuWrapper, MobileMenuHeader, MobileMenuLink},
}
</script>

<style scoped>

</style>