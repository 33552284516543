import {ApiService} from "@/services/APIService";

export default {
    getPendingTransactions(){
        return ApiService.get('api/manager/transactions');
    },

    reviewPendingTransaction(payload){
        return ApiService.post('api/manager/transactions/review', payload);
    },
}