import Vue from 'vue'
import './plugins/bootstrap-vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate";

import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize("en", en);

import moment from 'moment'
Vue.prototype.moment = moment;

// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.component('ValidationProvider', ValidationProvider);

import DisableAutocomplete from 'vue-disable-autocomplete';
Vue.use(DisableAutocomplete);

let filter = function(text, length, clamp){
  clamp = clamp || '...';
  let node = document.createElement('div');
  node.innerHTML = text;
  let content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter('truncate', filter);

import VueChatScroll from 'vue-chat-scroll'
import {isUserManager} from "@/utils/helpers";
Vue.use(VueChatScroll);

Vue.mixin({
  methods: {
    isUserManager,
  }
})

import VueIframe from 'vue-iframes'

Vue.use(VueIframe)

import VueGtag from "vue-gtag";
if (process.env.VUE_APP_GOOGLE_TRACKING === 'true'){
  Vue.use(VueGtag, {
    config: { id: "G-MRD0TMNEGG" }
  }, router);
}


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
