<template>
  <div>
    <router-link tag="div" class="mouse-pointer" :to="'messages/' + chat.id + '/' + chat.user.username">
      <div class="row">
        <div class="col-2 mb-2">
          <img src="@/assets/avatar-placeholder.png" class="rounded-circle" style="width: 3rem;" alt="Avatar Placeholder">
        </div>
        <div class="col-10 mt-2">
          <div class="spread-cols-to-ends">
            <span :class="'chat-name' + unreadMessagesClass">
              {{ chat.user.username }}
              <b-icon-patch-check-fill variant="warning" v-if="chat.user.is_kyc_verified && chat.user.active_subscription"></b-icon-patch-check-fill>
              <b-icon-patch-check-fill variant="primary" v-else-if="chat.user.is_kyc_verified"></b-icon-patch-check-fill>
            </span>

            <div>
          <span :class="'mr-2 chat-time' + unreadMessagesClass">
              {{ moment(chat.last_message.created_at).fromNow() }}
          </span>
            </div>
          </div>
          <div class="spread-cols-to-ends">
            <div :class="'last-message' + unreadMessagesClass">
              {{ chat.last_message.message | truncate(35, '...') }}
            </div>
            <b-badge pill variant="danger" v-if="chat.unread_messages_count > 0">
              <span class="small mb-1 font-weight-bold">{{ chat.unread_messages_count }}</span>
            </b-badge>
          </div>
        </div>
      </div>
    </router-link>
    <hr v-if="borderBottom === true" class="mt-1 mb-1">
  </div>
</template>

<script>
import {BIconPatchCheckFill} from "bootstrap-vue";

export default {
  name: "MobileMenuChatLink",
  props: [
      'chat',
      'borderBottom'
  ],
  components: {
    BIconPatchCheckFill
  },
  computed: {
    unreadMessagesClass() {
      if (this.chat.unread_messages_count > 0){
        return ' font-weight-bold'
      }

      return '';
    }
  }
}
</script>

<style scoped>
  .chat-time {
    font-size: 0.7rem !important;
  }

  .chat-name {
    font-size: 0.9rem !important;
  }

  .last-message {
    font-size: 0.7rem !important;
  }

  .side-by-side {
    /*display: inline-block;*/
  }

  .pic img {
    /* display block not needed */
    object-fit: cover; /* moved this property under image element */
    width: 20vw; /* added view port size so image only takes up half of view port */
  }
</style>