<template>
  <tablet-menu-wrapper>
    <mobile-menu-header
      title="Manage Reports"
      back-title="Back to Dashboard"
      back-route-link="/manager/dashboard"
    ></mobile-menu-header>
    <tablet-menu-body>
      <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
      <div v-else id="infinite-list-tablet-view">
        <div v-if="reports.length === 0 && !loading.page">
          <CenterMessage message="No reports found."></CenterMessage>
        </div>
        <div v-else>
          <div v-if="!loading.page" class="spread-cols-to-ends mb-1">
            <div>
              <!--              <b-form-input-->
              <!--                  placeholder="Search for Report"-->
              <!--                  size="sm"-->
              <!--                  v-model="search"-->
              <!--              >-->
              <!--              </b-form-input>-->
            </div>
            <div class="mt-3">
              <span
                >Showing page {{ pagination.currentPage }} of
                {{ pagination.totalPages }}.</span
              >
            </div>
          </div>
          <b-table-lite
            small
            hover
            v-if="!loading.page"
            :items="reports"
            :fields="fields"
          >
            <template #cell(status)="data">
              <b-badge
                v-if="data.item.status_id === 1"
                variant="warning"
                style="color: white"
                >Pending</b-badge
              >
              <b-badge v-if="data.item.status_id === 2" variant="success"
                >Completed</b-badge
              >
            </template>
            <template #cell(reporter)="data">
              {{ data.item.reporter.username }}
              <b-icon-patch-check-fill
                variant="warning"
                v-if="
                  data.item.reporter.is_kyc_verified &&
                  data.item.reporter.active_subscription
                "
              ></b-icon-patch-check-fill>
              <b-icon-patch-check-fill
                variant="primary"
                v-else-if="data.item.reporter.is_kyc_verified"
              ></b-icon-patch-check-fill>
            </template>
            <template #cell(category)="data">
              {{ data.item.category.name }}
            </template>
            <template #cell(reported)="data">
              {{ moment(data.item.created_at).format("l") }}
            </template>
            <template #cell(action)="data">
              <router-link :to="'/manager/reports/view/' + data.item.reference">
                <b-icon-search variant="primary" size="sm"> </b-icon-search>
              </router-link>
              <b-icon-check-circle-fill
                v-if="data.item.status_id === 1"
                @click="updateReport('mark-as-actioned', data.item.reference)"
                variant="success"
                class="mouse-pointer ml-1"
              >
              </b-icon-check-circle-fill>
            </template>
          </b-table-lite>
          <nav>
            <ul class="pagination justify-content-center">
              <li v-if="pagination.currentPage > 1" class="page-item">
                <a
                  class="page-link"
                  href="#"
                  aria-label="Previous"
                  @click.prevent="changePage(pagination.currentPage - 1)"
                >
                  <span aria-hidden="true">«</span>
                </a>
              </li>
              <li
                class="page-item"
                v-for="page in pagination.totalPages"
                :key="page"
                :class="{ active: page == pagination.currentPage }"
              >
                <a
                  v-if="
                    page < 3 ||
                    page > pagination.totalPages - 3 ||
                    (page < pagination.currentPage + 3 &&
                      page > pagination.currentPage - 3)
                  "
                  class="page-link"
                  @click.prevent="changePage(page)"
                  >{{ page }}</a
                >
              </li>
              <li
                v-if="pagination.currentPage < pagination.totalPages"
                class="page-item"
              >
                <a
                  class="page-link"
                  aria-label="Next"
                  @click.prevent="changePage(pagination.currentPage + 1)"
                >
                  <span aria-hidden="true">»</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </tablet-menu-body>
  </tablet-menu-wrapper>
</template>

<script>
import TabletMenuBody from "@/components/Workmorr/MobileMenu/TabletLayout/TabletMenuBody";
import TabletMenuWrapper from "@/components/Workmorr/MobileMenu/TabletLayout/TabletMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import CustomLoader from "@/components/Workmorr/CustomLoader";
import ManageReportsService from "@/services/manager/ManageReportsService";
import {
  BTableLite,
  BIconSearch,
  BIconPatchCheckFill,
  BIconCheckCircleFill,
} from "bootstrap-vue";
import CenterMessage from "@/components/Workmorr/CenterMessage";
import { updateObjectWithId } from "@/utils/helpers";

export default {
  name: "ManageReportsView",
  components: {
    CustomLoader,
    TabletMenuBody,
    TabletMenuWrapper,
    MobileMenuHeader,
    BTableLite,
    BIconSearch,
    CenterMessage,
    BIconPatchCheckFill,
    BIconCheckCircleFill,
  },
  data() {
    return {
      loading: {
        page: true,
        records: false,
      },
      fields: [
        "id",
        "reference",
        "reporter",
        "category",
        "status",
        "reported",
        "action",
      ],
      reports: [],
      search: "",
      pagination: {
        currentPage: 1,
        perPage: 12,
        totalRows: 1000, // Placeholder so get is run at least once
        totalPages: 1,
      },
    };
  },
  async mounted() {
    await this.getReports();
  },
  methods: {
    changePage(page) {
      this.pagination.currentPage = page;
      this.getReports();
    },

    async getReports(search) {
      if (search === true) {
        this.pagination.currentPage = 1;
      }
      this.loading.page = true;

      let response = await ManageReportsService.getReports(
        this.pagination.perPage,
        this.pagination.currentPage,
        this.search
      );
      this.reports = response.data.reports.data;
      this.pagination.totalRows = response.data.reports.total;
      this.pagination.totalPages = response.data.reports.last_page;
      this.loading.page = false;
    },

    updateReport(action, report_reference) {
      ManageReportsService.updateReport({
        action: action,
        report_reference: report_reference,
      }).then((response) => {
        this.reports = updateObjectWithId(this.reports, response.data.report);
      });
    },
  },

  watch: {
    search() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(
        function () {
          this.getReports(true);
        }.bind(this),
        1000
      );
    },
  },
};
</script>

<style scoped>
</style>