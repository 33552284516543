import {ApiService} from "@/services/APIService";

export default {
    getChats(){
        return ApiService.get('api/manager/chats');
    },

    getChat(chat_id){
        return ApiService.get('api/manager/chats/' + chat_id);
    },

    getFlaggedMessages(){
        return ApiService.get('api/manager/messages/flagged');
    },

    reviewFlaggedMessage(payload){
        return ApiService.get('api/manager/message/' + payload.action + '/' + payload.message_id);
    },
}