<template>
  <tablet-menu-wrapper>
    <mobile-menu-header
      title="Manage Gigs"
      back-title="Back to Dashboard"
      back-route-link="/manager/dashboard"
    ></mobile-menu-header>
    <tablet-menu-body>
      <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
      <div v-else id="infinite-list-tablet-view">
        <div v-if="gigs.length === 0 && !loading.page">
          <CenterMessage message="No gigs found."></CenterMessage>
        </div>
        <div v-else>
          <div v-if="!loading.page" class="spread-cols-to-ends mb-2">
            <div>
              <b-form-input
                placeholder="Search for Gigs"
                size="sm"
                v-model="search"
              >
              </b-form-input>
            </div>
            <div class="mt-3">
              <span
                >Showing page {{ pagination.currentPage }} of
                {{ pagination.totalPages }}.</span
              >
            </div>
          </div>
          <b-table-lite
            small
            hover
            v-if="!loading.page"
            :items="gigs"
            :fields="fields"
          >
            <template #cell(status)="data">
              <b-badge
                v-if="data.item.status_id === 1"
                variant="warning"
                style="color: white"
                >Inactive</b-badge
              >
              <b-badge v-if="data.item.status_id === 2" variant="success"
                >Live</b-badge
              >
              <b-badge v-if="data.item.status_id === 3" variant="danger"
                >Deleted</b-badge
              >
              <b-badge v-if="data.item.status_id === 4" variant="warning"
                >Paused</b-badge
              >
            </template>
            <template #cell(username)="data">
              {{ data.item.user.username }}
            </template>
            <template #cell(added)="data">
              {{ moment(data.item.created_at).calendar() }}
            </template>
            <template #cell(category)="data">
              {{ data.item.category.description }}
            </template>
            <template #cell(type)="data">
              {{ data.item.type.name }}
            </template>
            <template #cell(action)="data">
              <router-link
                :to="'/gigs/view/' + data.item.id + '/' + data.item.slug"
              >
                <b-icon-search variant="success" size="sm"> </b-icon-search>
              </router-link>
              <router-link :to="'/gigs/edit/' + data.item.id">
                <b-icon-pencil-square class="ml-1" variant="primary" size="sm">
                </b-icon-pencil-square>
              </router-link>
            </template>
          </b-table-lite>
          <nav>
            <ul class="pagination justify-content-center">
              <li v-if="pagination.currentPage > 1" class="page-item">
                <a
                  class="page-link"
                  href="#"
                  aria-label="Previous"
                  @click.prevent="changePage(pagination.currentPage - 1)"
                >
                  <span aria-hidden="true">«</span>
                </a>
              </li>
              <li
                class="page-item"
                v-for="page in pagination.totalPages"
                :key="page"
                :class="{ active: page == pagination.currentPage }"
              >
                <a
                  v-if="
                    page < 3 ||
                    page > pagination.totalPages - 3 ||
                    (page < pagination.currentPage + 3 &&
                      page > pagination.currentPage - 3)
                  "
                  class="page-link"
                  @click.prevent="changePage(page)"
                  >{{ page }}</a
                >
              </li>
              <li
                v-if="pagination.currentPage < pagination.totalPages"
                class="page-item"
              >
                <a
                  class="page-link"
                  aria-label="Next"
                  @click.prevent="changePage(pagination.currentPage + 1)"
                >
                  <span aria-hidden="true">»</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </tablet-menu-body>
  </tablet-menu-wrapper>
</template>

<script>
import TabletMenuBody from "@/components/Workmorr/MobileMenu/TabletLayout/TabletMenuBody";
import TabletMenuWrapper from "@/components/Workmorr/MobileMenu/TabletLayout/TabletMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import CustomLoader from "@/components/Workmorr/CustomLoader";
import { BIconSearch, BTableLite, BIconPencilSquare } from "bootstrap-vue";
import ManageGigsService from "@/services/manager/ManageGigsService";
import CenterMessage from "@/components/Workmorr/CenterMessage";

export default {
  name: "ManageGigsView",
  components: {
    CustomLoader,
    TabletMenuBody,
    TabletMenuWrapper,
    MobileMenuHeader,
    BTableLite,
    BIconSearch,
    CenterMessage,
    BIconPencilSquare,
  },
  data() {
    return {
      loading: {
        page: true,
        records: false,
      },
      fields: [
        "id",
        "username",
        "short_title",
        "category",
        "type",
        "status",
        "added",
        "action",
      ],
      gigs: [],
      search: "",
      pagination: {
        currentPage: 1,
        perPage: 12,
        totalRows: 1000, // Placeholder so get is run at least once
        totalPages: 1,
      },
    };
  },

  async mounted() {
    await this.getGigs();
  },

  methods: {
    changePage(page) {
      this.pagination.currentPage = page;
      this.getGigs();
    },

    async getGigs() {
      this.loading.page = true;
      let response = await ManageGigsService.getGigs(
        this.pagination.perPage,
        this.pagination.currentPage,
        this.search
      );
      this.gigs = response.data.gigs.data;
      this.pagination.totalRows = response.data.gigs.total;
      this.pagination.totalPages = response.data.gigs.last_page;
      this.loading.page = false;
    },
  },
};
</script>

<style scoped>
</style>