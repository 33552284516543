import { ApiService } from "@/services/APIService";

export default {
    getChats(
        search,
        paginate = false,
        page = 1,
        recordsPerPage = 15
    ) {
        if (paginate) {
            return ApiService.get("/api/chats?search=" + search + '&paginate=true&page=' + page + '&records_per_page=' + recordsPerPage);
        }
        return ApiService.get("/api/chats?search=" + search);
    },

    getChatMessages(chat_id, username) {
        return ApiService.get("/api/chat/" + chat_id + "/" + username);
    },

    markChatMessageAsRead(message_id) {
        return ApiService.post("/api/chat/message/read", {
            message_id: message_id
        });
    },

    sendChatMessage(payload) {
        return ApiService.post("/api/chat/message/send", payload, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    },
}