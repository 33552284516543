<template>
  <mobile-menu-wrapper>
    <mobile-menu-header
      v-if="prevRoute"
      title="Edit Gig"
      :back-title="
        prevRoute && prevRoute.meta.title
          ? 'Back to ' + prevRoute.meta.title
          : 'Back'
      "
      :back-route-link="prevRoute.path"
    ></mobile-menu-header>
    <mobile-menu-body>
      <SuccessAlert v-if="success" :message="success"></SuccessAlert>
      <ErrorAlert v-if="errors" :errors="errors"></ErrorAlert>
      <b-alert
        variant="primary"
        :show="user != null && gig.user_id === user.id && gig.is_promoted === 0"
      >
        <span style="font-size: 0.8rem"
          ><router-link
            style="text-decoration: none; color: inherit"
            :to="'/gigs/promote/' + gig.id"
            ><b><b-icon-graph-up class="mr-1"></b-icon-graph-up> Promote</b>
            your gig for more views at the top.</router-link
          ></span
        >
      </b-alert>
      <div class="spread-cols-to-ends mt-2 mb-2">
        <div class="mb-1">
          <b-badge v-if="gig.status_id === 1" variant="info">In review</b-badge>
          <b-badge v-else-if="gig.status_id === 2" variant="success"
            >Live</b-badge
          >
          <b-badge v-else-if="gig.status_id === 3" variant="danger"
            >Deleted</b-badge
          >
          <b-badge
            v-else-if="gig.status_id === 4"
            variant="warning"
            class="text-white"
            >Paused</b-badge
          >
        </div>
        <div v-if="gig.status_id !== 3">
          <router-link
            v-if="gig.status_id == 2"
            :to="'/gigs/view/' + gig.id + '/' + gig.slug"
          >
            <b-icon-search variant="primary"> </b-icon-search>
          </router-link>
          <b-icon-check-circle-fill
            v-if="gig.status_id === 1 && isUserManager"
            @click="performGigAction('approve', gig.id)"
            variant="success"
            class="mouse-pointer ml-2"
          ></b-icon-check-circle-fill>
          <b-icon-pause-fill
            v-if="gig.status_id !== 4"
            @click="performGigAction('pause', gig.id)"
            variant="warning"
            class="mouse-pointer ml-2"
          ></b-icon-pause-fill>
          <b-icon-play-fill
            v-if="gig.status_id === 4"
            @click="performGigAction('activate', gig.id)"
            variant="success"
            class="mouse-pointer ml-2"
          ></b-icon-play-fill>
          <b-icon-trash-fill
            @click="showConfirmActionModal(gig)"
            variant="danger"
            class="mouse-pointer ml-2"
          ></b-icon-trash-fill>
        </div>
      </div>
      <b-form-group label="Type of Service">
        <div class="row">
          <div class="col-6 pr-1">
            <b-button
              class="wide-btn"
              @click="toggleGigType(1)"
              :variant="gig.type_id === 1 ? 'success' : 'outline-success'"
            >
              Offer
            </b-button>
          </div>
          <div class="col-6 pl-1">
            <b-button
              class="wide-btn"
              @click="toggleGigType(2)"
              :variant="gig.type_id === 2 ? 'success' : 'outline-success'"
            >
              Request
            </b-button>
          </div>
        </div>
      </b-form-group>
      <b-form-group label="Title">
        <b-input-group :prepend="prepend">
          <b-form-input
            :disabled="loading.form"
            v-model="gig.title"
            aria-label="First name"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
      <b-form-group label="Category">
        <b-form-select v-model="gig.category_id" :disabled="loading.form">
          <b-form-select-option value="">Select Category</b-form-select-option>
          <b-form-select-option
            v-for="cat in options.category"
            :key="cat.value"
            :value="cat.value"
            >{{ cat.text }}</b-form-select-option
          >
        </b-form-select>
      </b-form-group>
      <b-form-group label="Description">
        <editor
          :disabled="loading.form"
          v-model="gig.description"
          api-key="id8huwbtsicpgcsq9digruov36p4d48xrd8zgpfyk9gvgyzn"
          :init="{
            height: 150,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
               alignleft aligncenter alignright alignjustify | \
               bullist numlist outdent indent | removeformat ',
          }"
        />
      </b-form-group>
      <b-form-group label="Pictures / Images (Optional)">
        <b-form-file
          multiple
          v-model="gig.files"
          placeholder="Select or drop up to 10 images ..."
          drop-placeholder="Drop image here..."
          ref="gig-files"
        ></b-form-file>
      </b-form-group>
      <div v-if="images.length > 0" class="mb-2">
        <hr class="mb-1 mt-0" />
        <viewer :images="images" class="images clearfix">
          <template slot-scope="scope">
            <div
              v-for="(value, key, index) in scope.images"
              :key="index"
              style="float: left"
            >
              <img :src="value.url" class="image" />
              <b-icon-trash-fill
                @click="deleteGigImage(value.file_id)"
                variant="danger"
                class="mouse-pointer"
                style="position: absolute; margin-left: -23px; margin-top: 9px"
              ></b-icon-trash-fill>
            </div>
          </template>
        </viewer>
      </div>
      <b-form-group label="Amount in GHS" :description="amountDescription">
        <b-form-input
          :disabled="loading.form"
          v-model="gig.amount"
          type="number"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Completion Time in hours">
        <b-form-input
          v-model="gig.duration"
          type="number"
          placeholder="E.g. 24"
        ></b-form-input>
      </b-form-group>
      <b-form-group>
        <b-form-checkbox
          v-model="gig.location_based"
          @change="toggleLocationBased()"
        >
          <b
            >{{ gig.type_id === 1 ? "Buyer" : "Seller" }} needs to be present at
            my location.</b
          >
        </b-form-checkbox>
        <div style="height: 200px; border-radius: 7px" v-if="show_map">
          <MglMap
            :accessToken="accessToken"
            :mapStyle.sync="mapStyle"
            :attributionControl="false"
            @load="onMapLoaded"
          >
            <MglGeolocateControl position="top-right" />
            <MglNavigationControl position="top-right" />
            <MglMarker
              v-if="gig.longitude && gig.latitude"
              :coordinates="[gig.longitude, gig.latitude]"
              :draggable="true"
              @dragend="updateLocation"
              color="green"
            />
          </MglMap>
        </div>
      </b-form-group>
      <div class="row">
        <div class="col-12">
          <b-button
            variant="success"
            block
            :disabled="loading.form || gig.status_id === 3"
            @click="updateGig()"
          >
            <span class="font-weight-bold">{{
              this.loading.form
                ? "Loading, please wait ..."
                : gig.type_id === 1
                ? "Update Service Offer"
                : "Update Service Request"
            }}</span>
          </b-button>
        </div>
      </div>
      <b-modal id="confirm-modal" title="Confirm Delete" size="sm">
        <template #default>
          <span class="font-weight-light">
            Are you sure you want to delete this Gig? This action is permanent
            and cannot be undone.
          </span>
        </template>
        <template #modal-footer>
          <div style="text-align: center">
            <b-button
              @click="$bvModal.hide('confirm-modal')"
              variant="outline-primary"
              class="btn-sm mr-2"
              >No, go back</b-button
            >
            <b-button
              @click="performGigAction('delete', gig.id)"
              variant="danger"
              class="btn-sm"
              >Yes, delete Gig</b-button
            >
          </div>
        </template>
      </b-modal>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>

<script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import Editor from "@tinymce/tinymce-vue";
import ErrorAlert from "@/components/Workmorr/ErrorAlert";
import GigService from "@/services/GigService";
import Mapbox from "mapbox-gl";
import {
  MglMap,
  MglMarker,
  MglGeolocateControl,
  MglNavigationControl,
} from "vue-mapbox";

// Vue Select
import "vue-search-select/dist/VueSearchSelect.css";
import { formatErrors } from "@/utils/helpers";
import {
  BIconPauseFill,
  BIconPlayFill,
  BIconTrashFill,
  BIconCheckCircleFill,
  BIconSearch,
  BIconGraphUp,
} from "bootstrap-vue";
import SuccessAlert from "@/components/Workmorr/SuccessAlert";

// Vue Viewer
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
Vue.use(VueViewer);

export default {
  name: "AddGigView",
  components: {
    MobileMenuBody,
    MobileMenuWrapper,
    MobileMenuHeader,
    Editor,
    ErrorAlert,
    MglMap,
    MglMarker,
    MglGeolocateControl,
    MglNavigationControl,
    BIconTrashFill,
    BIconPlayFill,
    BIconPauseFill,
    BIconCheckCircleFill,
    BIconSearch,
    SuccessAlert,
    BIconGraphUp,
  },

  created() {
    // We need to set mapbox-gl library here in order to use it in template
    this.mapbox = Mapbox;
  },

  data() {
    return {
      loading: {
        page: true,
        form: false,
      },
      success: "",
      user: null,
      errors: {},
      gig: {
        title: "",
        type_id: 1,
        category_id: "",
        description: "",
        amount: "",
        duration: "",
        location_based: false,
        longitude: "",
        latitude: "",
        files: null,
      },
      images: [],
      options: {
        category: [],
        type: [
          { value: 1, text: "Offer" },
          { value: 2, text: "Request" },
        ],
      },
      show_map: false,
      accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN, // your access token. Needed if you using Mapbox maps
      mapStyle: "mapbox://styles/mapbox/streets-v11", // your map style
      mapbox: null,
      prevRoute: null,
    };
  },

  async mounted() {
    await GigService.getGig(this.$route.params.gig_id)
      .then((response) => {
        this.gig = response.data.gig;

        // Format images
        if (this.gig.images.length > 0) {
          this.gig.images.forEach((image) => {
            this.images.push({
              file_id: image.id,
              url: image.file.firebase_url,
            });
          });
        }

        if (this.gig.location_based) {
          this.gig.location_based = true;
          this.show_map = true;
        }
      })
      .catch(() => {
        this.$router.push("/");
      });

    let response = await GigService.getGigCategories();
    response.data.categories.map(
      function (category) {
        this.options.category.push({
          value: category.id,
          text: category.description,
        });
      }.bind(this)
    );

    this.user = await this.$store.getters["user/user"];
  },

  computed: {
    amountDescription() {
      if (this.gig.type_id === 1) {
        // Offer
        return "How much you are charging for the service. Enter 0 for custom pricing.";
      } else {
        return "How much you are paying for the service. Enter 0 for custom pricing.";
      }
    },

    prepend() {
      if (this.gig.type_id === 1) {
        // Offer
        return "I will ";
      } else {
        return "I need someone to ";
      }
    },

    geolocationPermissionGrantefile_idd() {
      return "geolocation" in navigator;
    },
  },

  methods: {
    deleteGigImage(image_id) {
      GigService.deleteGigImage(this.gig.id, image_id)
        .then((response) => {
          // Clear errors
          this.gig = response.data.gig;
          // Format images
          if (this.gig.images.length > 0) {
            this.images = [];
            this.gig.images.forEach((image) => {
              this.images.push({
                file_id: image.id,
                url: image.file.firebase_url,
              });
            });
          }

          this.errors = {};
          this.success = response.data.message;
        })
        .catch((error) => {
          // Clear success
          this.success = null;
          this.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },

    toggleGigType(type_id) {
      this.gig.type_id = type_id;
    },

    toggleLocationBased() {
      if (this.gig.location_based === true) {
        // Check if user has granted access to location
        if (this.geolocationPermissionGranted) {
          // Show map
          this.show_map = true;
        } else {
          this.errors = ["Please allow us access to your location."];
          this.gig.location_based = false;
        }
      } else {
        this.show_map = false;
      }
    },

    updateGig() {
      this.loading.form = true;
      const formData = new FormData();
      formData.append("id", this.gig.id);
      formData.append("title", this.gig.title);
      formData.append("type_id", this.gig.type_id);
      formData.append("category_id", this.gig.category_id);
      formData.append("description", this.gig.description);
      formData.append("amount", this.gig.amount);
      formData.append("duration", this.gig.duration);
      formData.append(
        "location_based",
        this.gig.location_based == false ? 0 : 1
      );
      formData.append("longitude", this.gig.longitude);
      formData.append("latitude", this.gig.latitude);
      if (this.gig.files) {
        for (let i = 0; i < this.gig.files.length; i++) {
          let file = this.$refs["gig-files"].files[i];
          formData.append("files[" + i + "]", file);
        }
      }
      GigService.updateGig(formData)
        .then((response) => {
          // Clear errors
          this.gig = response.data.gig;
          // Format images
          if (this.gig.images.length > 0) {
            this.images = [];
            this.gig.images.forEach((image) => {
              this.images.push({
                file_id: image.id,
                url: image.file.firebase_url,
              });
            });
          }

          this.errors = {};
          this.success = response.data.message;
        })
        .catch((error) => {
          // Clear success
          this.success = null;
          this.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },

    updateLocation(event) {
      const newCoordinates = event.marker.getLngLat();
      this.gig.longitude = newCoordinates.lng;
      this.gig.latitude = newCoordinates.lat;
    },

    async onMapLoaded(event) {
      if (this.gig.longitude && this.gig.latitude) {
        event.map.flyTo({
          center: [this.gig.longitude, this.gig.latitude],
          zoom: 14,
          speed: 1,
        });
      } else if (this.geolocationPermissionGranted) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          // Here we catching 'load' map event
          const asyncActions = event.component.actions;

          const newParams = await asyncActions.flyTo({
            center: [position.coords.longitude, position.coords.latitude],
            zoom: 14,
            speed: 1,
          });

          this.gig.longitude = newParams.center.lng;
          this.gig.latitude = newParams.center.lat;
        });
      } else {
        this.errors = ["Please allow us access to your location."];
      }
    },

    async performGigAction(action) {
      this.loading.page = true;

      await GigService.performGigAction(action, this.gig.id)
        .then((response) => {
          if (action === "delete") {
            this.$bvModal.hide("confirm-modal");
          }
          this.gig = response.data.gig;
          this.success = response.data.message;
        })
        .catch((response) => {
          this.errors = formatErrors(response.data.message);
        });
      this.loading.form = false;
    },

    showConfirmActionModal(gig) {
      this.gig = gig;
      this.$bvModal.show("confirm-modal");
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
};
</script>

<style scoped>
.wide-btn {
  width: 100% !important;
}

.btn-radio:focus {
  outline-style: none !important;
  box-shadow: none !important;
}

.image {
  height: 60px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
  border-radius: 7%;
}
</style>