<template>
  <mobile-menu-wrapper>
    <mobile-menu-header
        title='Password'
        back-title="Back to Profile"
        back-route-link="/account/profile"
    ></mobile-menu-header>
    <mobile-menu-body>
      <ValidationObserver v-slot="{ invalid }">
        <b-form>
          <ErrorAlert v-if="errors" :errors="errors"></ErrorAlert>
          <SuccessAlert v-if="success" :message="success"></SuccessAlert>
          <ValidationProvider name="current password" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group id="current-password-group" label="Current Password" label-for="current_password">
              <b-form-input
                  id="current_password"
                  v-model="form.current_password"
                  type="password"
                  placeholder="Enter current password"
                  :disabled="loading"
                  :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback id="current-password-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider name="new password" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group id="new-password-group" label="New Password" label-for="new_password">
              <b-form-input
                  id="new_password"
                  v-model="form.new_password"
                  type="password"
                  placeholder="Enter your new password"
                  :disabled="loading"
                  :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback id="new-password-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider name="new password " :rules="{ required: true, confirmed: 'new password' }" v-slot="validationContext">
            <b-form-group id="new-password-confirmation-group" label="Confirm your New Password" label-for="new_password_confirmation">
              <b-form-input
                  id="new_password_confirmation"
                  v-model="form.new_password_confirmation"
                  type="password"
                  placeholder="Repeat your new password"
                  :disabled="loading"
                  :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback id="new-password-confirmation-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
          <div class="text-center">
            <b-button @click.prevent="onSubmit" type="submit" variant="success" :disabled="loading || invalid">
              {{ loading ? 'Loading, please wait...' : 'Update Password' }}
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>

<script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import ErrorAlert from "@/components/Workmorr/ErrorAlert";
import SuccessAlert from "@/components/Workmorr/SuccessAlert";
import {formatErrors} from "@/utils/helpers";

export default {
  name: "PasswordSettingsView",
  components: {MobileMenuBody, MobileMenuWrapper, MobileMenuHeader, ErrorAlert, SuccessAlert},
  data() {
    return {
      loading: false,
      form: {
        current_password: '',
        new_password: '',
        new_password_confirmation: ''
      },
      errors: {},
      success: null,
    }
  },

  methods: {
    onSubmit() {
      this.loading = true;
      this.$store.dispatch('user/updateDetails', {
        current_password: this.form.current_password,
        new_password: this.form.new_password,
        new_password_confirmation: this.form.new_password_confirmation,
        device_name: navigator.userAgent,
        endpoint: '/password/update'
      }).then(response => {
        // Clear errors
        this.errors = {};
        this.success = response.data.message;
      }).catch((error) => {
        // Clear success
        this.success = null;
        this.errors = formatErrors(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  }
}
</script>

<style scoped>

</style>