<template>
  <mobile-menu-wrapper>
    <mobile-menu-header
        title='Identity'
        back-title="Back to Profile"
        back-route-link="/account/profile"
    ></mobile-menu-header>
    <mobile-menu-body>
      <warning-alert :errors="['Note: Using someones details here other than yours is a criminal offence. Once identified, you will be banned and reported for investigation.']"></warning-alert>
      <ValidationObserver v-slot="{ invalid }">
        <b-form>
          <ErrorAlert v-if="errors" :errors="errors"></ErrorAlert>
          <SuccessAlert v-if="success" :message="success"></SuccessAlert>
          <ValidationProvider name="name" :rules="{ required: true, min: 4 }" v-slot="validationContext">
            <b-form-group id="name-group"
                          label="Name"
                          label-for="name"
                          description="Your name will have to match the name on your payment account."
            >
              <b-form-input
                  id="name"
                  v-model="form.name"
                  placeholder="Enter your full name."
                  :disabled="loading"
                  :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback id="name-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="National ID" :rules="{ required: true, min: 4 }" v-slot="validationContext">
            <b-form-group id="id-group"
                          label="National ID or Passport No."
                          label-for="id"
            >
              <b-form-input
                  id="id"
                  v-model="form.id_reference"
                  placeholder="E.g. GHXXXXX ..."
                  :disabled="loading"
                  :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback id="id-reference-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="Picture of ID" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group id="picture-of-id-group"
                          label="National ID or Passport Photo"
                          label-for="picture-of-id"
                          description="A clear scan or image of your National ID or Passport. (Image or PDF)"
            >
              <b-form-file
                  id="picture-of-id"
                  v-model="form.id_photo"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  :disabled="loading"
                  :state="getValidationState(validationContext)"
              ></b-form-file>
              <b-form-invalid-feedback id="picture-of-id-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>

          <div class="text-center">
            <b-button @click.prevent="onSubmit" type="submit" variant="success" :disabled="loading || invalid">
              {{ loading ? 'Loading, please wait...' : 'Update Identity Details' }}
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>

<script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import ErrorAlert from "@/components/Workmorr/ErrorAlert";
import SuccessAlert from "@/components/Workmorr/SuccessAlert";
import {formatErrors} from "@/utils/helpers";
import WarningAlert from "@/components/Workmorr/WarningAlert";

export default {
  name: "IdentityDetailsView",
  components: {WarningAlert, MobileMenuBody, MobileMenuWrapper, MobileMenuHeader, ErrorAlert, SuccessAlert},
  data() {
    return {
      loading: false,
      form: {
        name: '',
        id_type: 'ghana_card',
        id_reference: '',
        id_photo: null,
      },
      errors: {},
      success: null,
    }
  },

  mounted() {
    let user = this.$store.getters['user/user'];
    this.form.name = user.name;
    this.form.id_reference = user.id_reference;
  },


  methods: {
    onSubmit() {
      const formData = new FormData();
      formData.append('name', this.form.name);
      formData.append('id_type', this.form.id_type);
      formData.append('id_reference', this.form.id_reference);
      formData.append('id_photo', this.form.id_photo);

      this.loading = true;
      this.$store.dispatch('user/updateKYCDetails', formData
        ).then(response => {
        // Clear errors
        this.errors = {};
        this.success = response.message;
      }).catch((error) => {
        // Clear success
        this.success = null;
        this.errors = formatErrors(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  }
}
</script>

<style scoped>

</style>