<template>
  <mobile-menu-wrapper>
    <mobile-menu-header
        title='Stripe Test'
        back-title="Back to Home"
        back-route-link="/"
    ></mobile-menu-header>
    <mobile-menu-body>
      <CustomLoader v-if="loading" :center="true"></CustomLoader>
      <div class="text-center">
        <stripe-element-payment
            ref="paymentRef"
            :pk="pk"
            :elements-options="elementsOptions"
            :confirm-params="confirmParams"
        />
        <b-button class="mt-2" :disabled="loading" variant="outline-success" @click="pay">Pay Now</b-button>
      </div>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>

<script>
import CustomLoader from "@/components/Workmorr/CustomLoader";
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import { StripeElementPayment } from '@vue-stripe/vue-stripe';

export default {
  name: "StripeTestView",
  components: {CustomLoader, MobileMenuBody, MobileMenuWrapper, MobileMenuHeader, StripeElementPayment,},
  data() {
    return {
      loading: true,
      pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      elementsOptions: {
        appearance: {}, // appearance options
      },
      confirmParams: {
        return_url: 'http://localhost:8080/success', // success url
      },
    }
  },
  mounted() {
    this.generatePaymentIntent();
    this.loading = false;
  },
  methods: {
    async generatePaymentIntent () {
      this.elementsOptions.clientSecret = 'pi_3MaRjpGlH0aO6inD0U8KJoib_secret_d03Vom7tWbWrtn8a0SvDndZ3X';
    },
    pay () {
      this.$refs.paymentRef.submit();
    },
  }
}
</script>

<style scoped>

</style>