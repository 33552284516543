<template>
  <div>
    <gig-listings></gig-listings>
  </div>
</template>

<script>
// @ is an alias to /src
import GigListings from '@/components/Workmorr/GigListings.vue'

export default {
  name: 'HomeView',
  components: {
    GigListings
  },
  mounted() {
    if (this.$store.getters['user/user']){
      // refresh user data
      this.$store.dispatch('user/getAuthenticatedUser');
    }
  },
}
</script>

<style scoped>

</style>
