<template>
  <mobile-menu-wrapper :portrait="true">
    <mobile-menu-header
        title='Activate your Account'
        back-title="Back to Register"
        back-route-link="/login"
    ></mobile-menu-header>
    <mobile-menu-body>
      <ErrorAlert v-if="errors" :errors="errors"></ErrorAlert>
      <SuccessAlert v-if="success" :message="success"></SuccessAlert>
      <div>
        <b-form-group
            id="verification-code-group"
            label="Verification Code"
            label-for="login_email"
        >
          <b-form-input
              id="verification-code"
              v-model="form.verification_code"
              type="text"
              placeholder="Enter activation code received via sms or email"
              :disabled="loading"
          ></b-form-input>
        </b-form-group>
        <b-button block @click.prevent="onSubmit" type="submit" variant="success" :disabled="loading || success !== null">
          {{ loading ? 'Loading, please wait...' : 'Activate Account' }}
        </b-button>
      </div>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>

<script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import ErrorAlert from "@/components/Workmorr/ErrorAlert";
import SuccessAlert from "@/components/Workmorr/SuccessAlert";
import {formatErrors} from "@/utils/helpers";

export default {
  name: "VerifyView",
  components: {MobileMenuBody, MobileMenuWrapper, MobileMenuHeader, ErrorAlert, SuccessAlert},
  data(){
    return {
      loading: false,
      success: null,
      errors: {},
      form: {
        user_id: '',
        verification_code: '',
        device_name: navigator.userAgent
      }
    }
  },

  mounted() {
    this.form.user_id = this.$route.params.user_id;
    if (this.$route.params.verification_code){
      this.form.verification_code = this.$route.params.verification_code;
      this.onSubmit();
    }
  },

  methods: {
    onSubmit(){
      this.loading = true;

      this.$store.dispatch('user/verifyPhone', this.form).then(response => {
            // Clear errors
            this.errors = {};
            this.success = response.message;

            // Redirect to profile
            this.$router.push('/account/profile');
          }).catch((error) => {
        // Clear success
        this.success = null;
        this.errors = formatErrors(error);
      }).finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>