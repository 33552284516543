<template>
  <mobile-menu-wrapper>
    <mobile-menu-header
      :title="customer.name"
      back-title="Back to Customers"
      back-route-link="/manager/customers"
    ></mobile-menu-header>
    <mobile-menu-body>
      <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
      <div v-if="!loading.page">
        <ErrorAlert v-if="errors" :errors="errors"></ErrorAlert>
        <SuccessAlert v-if="success" :message="success"></SuccessAlert>
        <div class="accordion" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-0" role="tab">
              <h6 class="mt-2 ml-3" v-b-toggle.username-and-email>
                Username & Email
              </h6>
            </b-card-header>
            <b-collapse
              id="username-and-email"
              visible
              accordion="customer-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <ValidationProvider
                  name="username"
                  :rules="{ required: true, min: 4 }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="username-group"
                    label="Username"
                    label-for="username"
                  >
                    <b-form-input
                      id="username"
                      v-model="customer.username"
                      placeholder="Enter username"
                      :disabled="loading.form"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback id="username-live-feedback">{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  name="email"
                  :rules="{ required: true, email: true }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="email-group"
                    label="Email Address"
                    label-for="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="customer.email"
                      type="email"
                      placeholder="Enter email"
                      :disabled="loading.form"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback id="email-live-feedback">{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-0" role="tab">
              <h6 class="mt-2 ml-3" v-b-toggle.identity>Identity</h6>
            </b-card-header>
            <b-collapse
              id="identity"
              accordion="customer-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <div class="mb-1">
                  <h6 class="text-right">
                    <span>{{
                      customer.is_kyc_verified
                        ? "Verified "
                        : "Pending Verification "
                    }}</span>
                    <span v-if="customer.is_kyc_verified"
                      ><b-icon-patch-check-fill
                        variant="primary"
                      ></b-icon-patch-check-fill
                    ></span>
                    <span v-else class="mouse-pointer" @click="verifyKYC"
                      ><b-icon-patch-check></b-icon-patch-check
                    ></span>
                  </h6>
                </div>
                <ValidationProvider
                  name="name"
                  :rules="{ required: true, min: 4 }"
                  v-slot="validationContext"
                >
                  <b-form-group id="name-group" label="Name" label-for="name">
                    <b-form-input
                      id="name"
                      v-model="customer.name"
                      placeholder="Enter your full name."
                      :disabled="loading.form"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback id="name-live-feedback">{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider
                  name="National ID or Passport"
                  :rules="{ required: true, min: 4 }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="national-id-group"
                    label="National ID or Passport No."
                    label-for="national-id"
                  >
                    <b-form-input
                      id="national-id"
                      v-model="customer.id_reference"
                      placeholder="E.g. GHA-XXXX-X"
                      :disabled="loading.form"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      id="national-id-reference-live-feedback"
                      >{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback
                    >
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider
                  name="Picture of ID"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="picture-of-id-group"
                    label="Picture of ID"
                    label-for="picture-of-id"
                  >
                    <b-form-file
                      id="picture-of-id"
                      v-model="customer.id_photo"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                      :disabled="loading.form"
                      :state="getValidationState(validationContext)"
                    ></b-form-file>
                    <div v-if="customer.id_photo_file" class="text-left mt-2">
                      <a
                        :href="customer.id_photo_file.firebase_url"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <b-icon-cloud-arrow-down-fill></b-icon-cloud-arrow-down-fill>
                        {{
                          customer.id_photo_file.original_name
                            | truncate(50, "...")
                        }}
                      </a>
                    </div>
                    <b-form-invalid-feedback id="picture-of-id-live-feedback">{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card v-if="customer.payment_detail" no-body class="mb-1">
            <b-card-header header-tag="header" class="p-0" role="tab">
              <h6 class="mt-2 ml-3" v-b-toggle.payment>Payment</h6>
            </b-card-header>
            <b-collapse
              id="payment"
              accordion="customer-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <ValidationProvider
                  name="account type"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="account-type-group"
                    label="Account Type"
                    label-for="account-type"
                  >
                    <b-form-select
                      id="account-type"
                      v-model="customer.payment_detail.account_type_code"
                      :options="options"
                      :disabled="loading.form"
                      :state="getValidationState(validationContext)"
                    ></b-form-select>
                    <b-form-invalid-feedback id="account-type-live-feedback">{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  name="institution name"
                  :rules="{ required: true, min: 2 }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="institution-name-group"
                    label="Institution Name"
                    label-for="institution-name"
                  >
                    <b-form-input
                      id="institution-name"
                      v-model="customer.payment_detail.institution_name"
                      placeholder="Example. Bank of Africa"
                      :disabled="loading.form"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      id="institution-name-live-feedback"
                      >{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback
                    >
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider
                  name="institution code"
                  :rules="{ required: false, min: 2, max: 20 }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="institution-code-group"
                    label="Institution Code"
                    label-for="institution-code"
                    :description="'Optional. Only required for Banks i.e. Routing Code'"
                  >
                    <b-form-input
                      id="institution-code"
                      v-model="customer.payment_detail.institution_code"
                      placeholder="Example. 01XXXXXXX"
                      :disabled="loading.form"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      id="institution-code-live-feedback"
                      >{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback
                    >
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider
                  name="currency"
                  :rules="{ required: true, min: 2 }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="currency-group"
                    label="Currency"
                    label-for="currency"
                  >
                    <b-form-input
                      id="currency"
                      v-model="customer.payment_detail.account_currency"
                      placeholder="Example. Ghana Cedi"
                      :disabled="loading.form"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback id="currency-live-feedback">{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider
                  name="account number"
                  :rules="{ required: true, min: 2 }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="account-number-group"
                    label="Account Number"
                    label-for="account-no"
                  >
                    <b-form-input
                      id="account-no"
                      v-model="customer.payment_detail.account_no"
                      placeholder="Example. 024XXXXXXX"
                      :disabled="loading.form"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      id="account-number-live-feedback"
                      >{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback
                    >
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider
                  name="account name"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="account-name-group"
                    label="Name on Account"
                    label-for="account-name"
                  >
                    <b-form-input
                      id="account-name"
                      v-model="customer.payment_detail.name_on_account"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback id="account-name-live-feedback">{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-0" role="tab">
              <h6 class="mt-2 ml-3" v-b-toggle.location>Location</h6>
            </b-card-header>
            <b-collapse
              id="location"
              accordion="customer-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <div v-if="customer.location" style="height: 300px">
                  <MglMap
                    :accessToken="accessToken"
                    :mapStyle.sync="mapStyle"
                    :attributionControl="false"
                    @load="onMapLoaded"
                  >
                    <MglNavigationControl position="top-right" />
                    <MglMarker
                      :coordinates="[customer.longitude, customer.latitude]"
                      :draggable="false"
                      color="green"
                    />
                  </MglMap>
                </div>
                <div v-else class="small">
                  <b-icon-exclamation-circle
                    class="text-warning font-weight-bold"
                  ></b-icon-exclamation-circle>
                  <em> Customer location information not provided. </em>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
      <div class="text-center mt-2">
        <b-button
          @click.prevent="onSubmit"
          type="submit"
          variant="success"
          :disabled="loading.form"
        >
          {{
            loading.form ? "Loading, please wait..." : "Update Customer Details"
          }}
        </b-button>
      </div>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>

<script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import CustomLoader from "@/components/Workmorr/CustomLoader";
import ManageCustomersService from "@/services/manager/ManageCustomersService";
import ErrorAlert from "@/components/Workmorr/ErrorAlert";
import SuccessAlert from "@/components/Workmorr/SuccessAlert";
import { formatErrors } from "@/utils/helpers";
import {
  BIconExclamationCircle,
  BIconPatchCheck,
  BIconPatchCheckFill,
} from "bootstrap-vue";
import { MglMap, MglMarker, MglNavigationControl } from "vue-mapbox";
import UserService from "@/services/UserService";

export default {
  name: "ViewCustomerView",
  components: {
    MobileMenuBody,
    MobileMenuWrapper,
    MobileMenuHeader,
    CustomLoader,
    ErrorAlert,
    SuccessAlert,
    BIconExclamationCircle,
    BIconPatchCheck,
    BIconPatchCheckFill,
    MglMap,
    MglMarker,
    MglNavigationControl,
  },
  data() {
    return {
      errors: {},
      success: "",
      loading: {
        page: true,
        form: false,
      },
      customer: {},
      options: [{ value: null, text: "Select Account Type" }],
      accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN, // your access token. Needed if you using Mapbox maps
      mapStyle: "mapbox://styles/mapbox/streets-v11", // your map style
      mapbox: null,
    };
  },
  async mounted() {
    await this.getCustomer();
    let response = await UserService.getAccountOptions();
    response.data.types.map(
      function (type) {
        this.options.push({
          value: type.code,
          text: type.name,
        });
      }.bind(this)
    );
    this.loading.page = false;
  },

  methods: {
    async onMapLoaded(event) {
      event.map.flyTo({
        center: [this.customer.longitude, this.customer.latitude],
        zoom: 13,
        speed: 1,
      });
    },

    getCustomer() {
      ManageCustomersService.getCustomer(this.$route.params.id).then(
        (response) => {
          this.customer = response.data.customer;
          window.document.title = "Workmorr - View " + this.customer.name;
        }
      );
    },

    onSubmit() {
      this.loading.form = true;

      // Prepare payload
      let payload = new FormData();
      payload.append("id", this.customer.id);
      payload.append("name", this.customer.name);
      payload.append("username", this.customer.username);
      payload.append("email", this.customer.email);
      payload.append("name", this.customer.name);
      payload.append("id_type", this.customer.id_type);
      payload.append("id_reference", this.customer.id_reference);
      payload.append("id_photo", this.customer.id_photo);
      payload.append(
        "settlement_institution_code",
        this.customer.settlement_institution_code
      );
      payload.append(
        "settlement_account_no",
        this.customer.settlement_account_no
      );

      // Payment details
      payload.append(
        "payment_account_type_code",
        this.customer.payment_detail.account_type_code
      );
      payload.append(
        "payment_account_currency",
        this.customer.payment_detail.account_currency
      );
      payload.append(
        "payment_institution_name",
        this.customer.payment_detail.institution_name
      );
      payload.append(
        "payment_institution_code",
        this.customer.payment_detail.institution_code
      );
      payload.append(
        "payment_account_no",
        this.customer.payment_detail.account_no
      );
      payload.append(
        "payment_name_on_account",
        this.customer.payment_detail.name_on_account
      );

      ManageCustomersService.updateCustomer(payload)
        .then((response) => {
          // Clear errors
          this.errors = {};
          this.success = response.data.message;
          this.customer = response.data.customer;
        })
        .catch((error) => {
          // Clear success
          this.success = null;
          this.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },

    verifyKYC() {
      this.loading.form = true;
      ManageCustomersService.verifyCustomerKyc(this.customer.id)
        .then((response) => {
          // Clear errors
          this.errors = {};
          this.success = response.data.message;
          this.customer = response.data.customer;
        })
        .catch((error) => {
          // Clear success
          this.success = null;
          this.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<style scoped>
</style>