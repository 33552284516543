import store from '@/store';

export const getError = (error) => {
    const errorMessage = "API Error, please try again.";

    if (error.name === "Fetch User") {
        return error.message;
    }

    if (!error.response) {
        console.error(`API ${error.config.url} not found`);
        return errorMessage;
    }
    if (process.env.NODE_ENV === "development") {
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
    }
    if (error.response.data && error.response.data.errors) {
        return error.response.data.errors;
    }

    return errorMessage;
};

export const formatErrors = (error) => {
    let errors = [];

    if ( typeof error.response.data.error !== 'undefined' ){
        errors[0] = error.response.data.error;
    }

    else if ( typeof error.response.data.errors !== 'undefined' ){
        const keys = Object.keys(error.response.data.errors);
        keys.forEach((key, index) => {
            errors[index] = error.response.data.errors[key][0];
        });
    }

    else if ( typeof error.response.data.message !== 'undefined' ){
        errors[0] = error.response.data.message;
    }

    return errors;
};

export const removeObjectWithId = (array, id) => {
    // Making a copy with the Array from() method
    const arrayCopy = Array.from(array);

    const objWithIdIndex = arrayCopy.findIndex((obj) => obj.id === id);
    arrayCopy.splice(objWithIdIndex, 1);
    return arrayCopy;
};

export const removeObjectWithReference = (array, reference) => {
    // Making a copy with the Array from() method
    const arrayCopy = Array.from(array);

    const objWithReferenceIndex = arrayCopy.findIndex((obj) => obj.reference === reference);
    arrayCopy.splice(objWithReferenceIndex, 1);
    return arrayCopy;
};

export const updateObjectWithId = (array, object) => {
    // Making a copy with the Array from() method
    const arrayCopy = Array.from(array);

    const objWithIdIndex = arrayCopy.findIndex((obj) => obj.id === object.id);
    arrayCopy[objWithIdIndex] = object;
    return (arrayCopy);
};


export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });
}

export const isUserManager = () => {
   let user = store.getters['user/user'];
   if (user){
       return user.id === 1;
   }
   return false;
}