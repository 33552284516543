<template>
  <mobile-menu-wrapper>
    <mobile-menu-header
        title='Your Orders'
        back-title="Back to Account"
        back-route-link="/account"
    ></mobile-menu-header>
    <mobile-menu-body>
      <div id="infinite-list-mobile-view">
        <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
        <div v-else>
          <ErrorAlert v-if="errors" :errors="errors"></ErrorAlert>
          <SuccessAlert v-if="success" :message="success"></SuccessAlert>
          <b-card v-for="order in orders" :key="order.id" class="mb-2 pt-1 shadow-sm">
            <router-link :to="'/account/orders/view/' + order.id" tag="span" class="mouse-pointer">
              <span style="font-size: 0.9rem" class="font-weight-bold">
                {{ order.offer.gig.short_title }}
              </span>
            </router-link>
            <div class="spread-cols-to-ends mb-1">
              <div>
                {{ order.offer.seller_id === user.id ? order.offer.buyer.username : order.offer.seller.username }}
              </div>
              <div>
                <span style="color:green; font-weight: bold">	&#8373;{{ order.offer.amount }}</span>
              </div>
            </div>

            <template #footer>
              <div class="spread-cols-to-ends mt-1">
                <div class="mb-1">
                  <b-badge v-if="order.status_id === 1" variant="info">Pending {{ order.offer.seller_id === user.id ? 'Your' : 'Seller' }} Acceptance</b-badge>
                  <b-badge v-else-if="order.status_id === 2" variant="warning" class="text-white">Ongoing</b-badge>
                  <b-badge v-else-if="order.status_id === 3" variant="primary">In Review</b-badge>
                  <b-badge v-else-if="order.status_id === 4" variant="success">Completed</b-badge>
                  <b-badge v-else-if="order.status_id === 5" variant="danger">Cancelled</b-badge>
                </div>
                <div>
                  <b-icon-search @click="$router.push('/account/orders/view/' + order.id)" variant="primary" class="mouse-pointer ml-2"></b-icon-search>
                  <span v-if="order.status_id === 1 && order.offer.seller_id === user.id">
                    <b-icon-play-fill @click="performOrderAction('accept', order.id)" variant="success" class="mouse-pointer ml-2"></b-icon-play-fill>
                  </span>
                  <span v-if="order.status_id === 3 && order.offer.buyer_id === user.id">
                    <b-icon-check-circle-fill @click="performOrderAction('complete', order.id)" variant="success" class="mouse-pointer ml-2"></b-icon-check-circle-fill>
                  </span>
                </div>
              </div>
            </template>
          </b-card>
          <CustomLoader v-if="loading.list"></CustomLoader>
        </div>
        <div v-if="(!loading.list) && orders && orders.length === 0" class="text-center">
          <CenterMessage>
            <h6>You have no orders yet.</h6>
            <router-link to="/orders/add">
              <b-button variant="outline-success" class="font-weight-bold" style="min-width: 300px">
                Request or Offer a Service
              </b-button>
            </router-link>
          </CenterMessage>
        </div>
      </div>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>

<script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import CustomLoader from "@/components/Workmorr/CustomLoader";
import {
  BIconPlayFill,
  BIconSearch,
  BIconCheckCircleFill
} from 'bootstrap-vue'
import CenterMessage from "@/components/Workmorr/CenterMessage";
import {formatErrors, updateObjectWithId} from "@/utils/helpers";
import SuccessAlert from "@/components/Workmorr/SuccessAlert";
import ErrorAlert from "@/components/Workmorr/ErrorAlert";
import OrderService from "@/services/OrderService";

export default {
  name: "MyOrdersView",
  components: {
    CenterMessage,
    CustomLoader,
    MobileMenuBody,
    MobileMenuWrapper,
    MobileMenuHeader,
    BIconPlayFill,
    BIconSearch,
    BIconCheckCircleFill,
    SuccessAlert,
    ErrorAlert,
  },
  data() {
    return {
      user: null,
      loading: {
        page: true,
        list: false,
      },
      orders: [],
      order: null,
      pagination: {
        currentPage: 1,
        perPage: 10,
        totalRows: 1000, // Placeholder so get is run at least once
      },
      errors: {},
      success: '',
      revision: '',
    }
  },

  async mounted() {
    this.user = this.$store.getters['user/user'];
    await this.getOrders();
    this.loading.page = false;

    const listElm = document.querySelector('#infinite-list-mobile-view');
    // eslint-disable-next-line no-unused-vars
    listElm.addEventListener('scroll', e => {
      if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
        this.pagination.currentPage++;
        this.getOrders();
      }
    });
  },

  methods: {
    async getOrders(){
      if (this.orders.length < this.pagination.totalRows){
        this.loading.list = true;
        OrderService.getOrders(
            this.pagination.currentPage,
            this.pagination.perPage
        ).then((response) => {
          response.data.orders.data.map(function(order) {
            this.orders.push(order);
          }.bind(this))

          this.pagination.totalRows = response.data.orders.total;
          this.loading.list = false;
        })
      }
    },

    async performOrderAction(action, order_id) {
      this.loading.page = true;

      await OrderService.performOrderAction(action, {
        gig_order_id: order_id,
      }).then((response) => {

        // update orders
        this.orders = updateObjectWithId(this.orders, response.data.order)
        
        this.success = response.data.message;

      }).catch(response => {
        this.errors = formatErrors(response);
      })

      this.loading.page = false;
    }
  }
}
</script>

<style scoped>
.card-body, .card-footer{
  padding-top: 2px;
  padding-bottom: 2px;
}
</style>