<template>
  <div>
    <div class="spread-cols-to-ends">
      <div class="mt-3">
        <span style="text-align: left" class="h5">{{ title }}</span>
      </div>
      <div>
        <slot></slot>
        <router-link v-if="backRouteLink" :to="backRouteLink">
          <b-button
            variant="success"
            size="sm"
            class="font-weight-bold pl-2 mt-2"
          >
            <b-icon-chevron-left></b-icon-chevron-left>
            <span style="font-size: small">{{ backTitle }} </span>
          </b-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { BIconChevronLeft } from "bootstrap-vue";

export default {
  name: "MobileMenuHeader",
  inheritAttrs: false,
  props: ["title", "backTitle", "backRouteLink", "showPaymentWarning"],
  components: {
    BIconChevronLeft,
  },
};
</script>

<style scoped>
</style>