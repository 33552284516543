<template>
  <div class="row center-col">
    <div :class="portrait ? 'col-md-6' : 'col-md-9'">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabletMenuWrapper",
  inheritAttrs: false, // optional
  props: [
      'portrait'
  ],
}
</script>

<style scoped>
.menu-border {
  border-radius: 10px;
  border: 2px #f6f6f6 solid;
  padding: 1rem;
}
</style>