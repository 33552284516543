<template>
  <mobile-menu-wrapper>
    <mobile-menu-header
      title=""
      back-title="Back to Browse Gigs"
      back-route-link="/"
    ></mobile-menu-header>
    <mobile-menu-body>
      <ErrorAlert v-if="errors" :errors="errors"></ErrorAlert>
      <CustomLoader :center="true" v-if="loading"></CustomLoader>
      <div v-else>
        <div v-if="gig">
          <b-alert
            variant="primary"
            :show="
              user != null && gig.user_id === user.id && gig.is_promoted === 0
            "
          >
            <span style="font-size: 0.8rem"
              ><router-link
                style="text-decoration: none; color: inherit"
                :to="'/gigs/promote/' + gig.id"
                ><b><b-icon-graph-up class="mr-1"></b-icon-graph-up> Promote</b>
                your gig for more views at the top.</router-link
              ></span
            >
          </b-alert>
          <div class="spread-cols-to-ends">
            <div></div>
            <div>
              <span style="font-size: 0.9rem; text-align: right"
                ><b-icon-eye class="font-weight-bold"> </b-icon-eye>
                {{ gig.human_readable_views }}
              </span>
            </div>
          </div>
          <h5 class="mb-1">{{ gig.long_title }}</h5>
          <div class="spread-cols-to-ends">
            <div>
              <span class="category-text mt-2"
                ><em>{{ gig.category.description }} </em></span
              >
              <span style="font-size: 15px"> {{ gig.user.country_flag }}</span>
              <span
                v-if="gig.is_promoted"
                style="font-size: 15px; margin-left: 2px"
                >🚀</span
              >
            </div>
            <div>
              <span
                class="edit-btn mr-1"
                style="font-size: 1.2rem"
                v-if="
                  isUserManager() ||
                  ($store.getters['user/user'] !== null &&
                    gig.user.id === $store.getters['user/user'].id)
                "
              >
                <router-link :to="'/gigs/edit/' + gig.id">
                  <b-icon-pencil-square
                    class="font-weight-bold mouse-pointer"
                    variant="primary"
                  ></b-icon-pencil-square>
                </router-link>
              </span>
              <span
                style="font-size: 1.2rem"
                @click="favoriteGig(gig.id)"
                class="mouse-pointer"
              >
                <b-icon
                  v-if="gig.favorite"
                  icon="heart-fill"
                  style="color: red"
                ></b-icon>
                <b-icon v-else icon="heart"></b-icon>
              </span>
            </div>
          </div>
          <hr class="mt-1 mb-2" />
          <p
            style="font-size: 0.9rem; line-height: 1.2"
            v-html="gig.description"
          ></p>
          <div class="spread-cols-to-ends">
            <div>
              <span
                v-if="gig.amount > 0"
                style="color: green; font-weight: bold"
              >
                &#8373;{{ gig.amount }}</span
              >
              <b-badge v-else variant="success" class="mb-1"
                >Custom Offer</b-badge
              >
            </div>
            <div style="font-size: 0.9rem">
              <div>
                <span>
                  <b-icon-stopwatch
                    style="color: darkorange"
                    class="font-weight-bold mr-1"
                  ></b-icon-stopwatch>
                  <span style="font-size: 0.7rem"
                    >Completion time:
                    <b>{{ gig.human_readable_duration }}</b></span
                  >
                </span>
              </div>
              <div class="mt-1">
                <span v-if="gig.distance">
                  <b-icon-map
                    class="text-primary font-weight-bold mr-1"
                  ></b-icon-map>
                  <span style="font-size: 0.7rem"
                    >Distance from you:
                    <b>{{ gig.human_readable_distance }}</b></span
                  >
                </span>
              </div>
            </div>
          </div>
          <div v-if="gig.amount === 0">
            <div style="font-size: 0.7rem" class="mb-2 mt-1">
              <b-icon-exclamation-circle
                class="text-primary font-weight-bold"
              ></b-icon-exclamation-circle>
              <em>
                This gig is by custom offer. Message
                {{ gig.type_id === 1 ? "seller" : "buyer" }} to negotiate terms.
              </em>
            </div>
          </div>
          <div v-if="images.length > 0">
            <hr class="mb-2 mt-1" />
            <viewer :images="images" class="images clearfix">
              <template slot-scope="scope">
                <img
                  v-for="src in scope.images"
                  :src="src"
                  :key="src"
                  class="image"
                />
              </template>
            </viewer>
          </div>
          <!-- Location Details -->
          <div v-if="gig.location_based">
            <hr class="mb-1 mt-2" />
            <div v-if="user && user.is_kyc_verified">
              <div style="font-size: 0.7rem" class="mb-2">
                <b-icon-exclamation-circle
                  class="text-primary font-weight-bold"
                ></b-icon-exclamation-circle>
                <em>
                  This gig is location based. This means that you
                  <b>may</b> have to be physically present at some point to
                  complete this gig. Message
                  {{ gig.type_id === 1 ? "seller" : "buyer" }} if you are not
                  sure about this.
                </em>
              </div>
              <div style="height: 200px">
                <MglMap
                  :accessToken="accessToken"
                  :mapStyle.sync="mapStyle"
                  :attributionControl="false"
                  @load="onMapLoaded"
                >
                  <MglNavigationControl position="top-right" />
                  <MglMarker
                    :coordinates="[gig.longitude, gig.latitude]"
                    :draggable="false"
                    color="green"
                  />
                </MglMap>
              </div>
              <b-button
                :href="
                  'https://www.google.com/maps/search/?api=1&query=' +
                  gig.latitude +
                  ',' +
                  gig.longitude
                "
                target="_blank"
                variant="primary"
                class="mt-2"
                size="sm"
                block
              >
                <b-icon-map class="mr-1"></b-icon-map>
                View Location on Google Maps
              </b-button>
            </div>
            <div v-else-if="user && !user.is_kyc_verified">
              <div style="font-size: 0.7rem" class="mb-2">
                <b-icon-exclamation-circle
                  class="text-warning font-weight-bold"
                ></b-icon-exclamation-circle>
                <em>
                  Location information available but hidden. We are unable to
                  disclose the exact location at this moment because your
                  Identity Information is incomplete or pending verification.
                </em>
              </div>
            </div>
            <div v-else>
              <div style="font-size: 0.7rem" class="mb-2">
                <b-icon-exclamation-circle
                  class="text-danger font-weight-bold"
                ></b-icon-exclamation-circle>
                <em>
                  Location information available but hidden. Please login or
                  register to view location details of this gig.
                </em>
              </div>
            </div>
          </div>

          <!-- About the Seller -->
          <div>
            <hr class="mt-2 mb-1" />
            <h6 style="font-size: 0.9rem">
              About the {{ gig.type_id === 1 ? "Seller" : "Buyer" }} -
              <b>{{ gig.user.username }}</b>
            </h6>
            <ul style="font-size: 0.8rem; line-height: 1.5">
              <li>
                <b-icon icon="people-fill" class="text-success"></b-icon> Joined
                on
                <b>{{
                  moment(gig.user.created_at).format("dddd, MMMM Do YYYY")
                }}</b>
              </li>
              <li v-if="gig.user.is_kyc_verified">
                <b-icon-patch-check-fill
                  class="text-primary"
                ></b-icon-patch-check-fill>
                Identity details <b class="text-primary">verified</b> by
                Workmorr
              </li>
              <li v-if="gig.user.active_subscription">
                <b-icon-patch-check-fill
                  class="text-warning"
                ></b-icon-patch-check-fill
                ><b class="text-warning"> Premium</b> Workrr on Workmorr
              </li>
              <li v-if="gig.user.rating">
                <b-icon-star-fill style="color: darkorange"></b-icon-star-fill>
                Rated
                <b style="color: darkorange">{{ gig.user.rating }}</b> from
                <b>{{ gig.user.rating_count }}</b> review{{
                  gig.user.rating_count > 1 ? "s" : ""
                }}
              </li>
            </ul>
          </div>

          <!-- Actions -->
          <div>
            <div v-if="user !== null && gig.user.id === user.id" class="row">
              <div class="col-12">
                <b-alert variant="warning" :show="true">
                  <div class="mb-2">
                    <b>
                      <b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill>
                      <span style="font-size: 0.9rem" class="ml-1"
                        >Disclaimer & Warning</span
                      > </b
                    ><br />
                  </div>
                  <span style="font-size: 0.8rem"
                    >Workmorr <b>DOES NOT</b> charge a commission on orders. An
                    order created on Workmorr represents a
                    <b>contract</b> between you and the user requesting your
                    service.</span
                  >
                  <br /><br />
                  <span style="font-size: 0.8rem"
                    >We therefore advice that you only perform your service when
                    an order is requested on Workmorr so there is evidence of an agreement.</span
                  >
                </b-alert>
                <b-button
                  variant="info"
                  size="sm"
                  block
                  @click="redirectToOfferCreation()"
                >
                  <b-icon-cash-coin class="mr-1"></b-icon-cash-coin>
                  Create Custom Offer
                </b-button>
              </div>
            </div>
            <div v-else-if="gig.amount > 0">
              <b-alert variant="info" :show="true">
                <div class="mb-2">
                  <b>
                    <b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill>
                    <span style="font-size: 0.9rem" class="ml-1"
                      >Disclaimer & Warning</span
                    > </b
                  ><br />
                </div>
                <span style="font-size: 0.8rem"
                  >Workmorr <b>DOES NOT</b> charge a commission on orders. An
                  order created on Workmorr represents a <b>contract</b> between
                  you and the user providing or requesting this service.</span
                >
                <br /><br />
                <span style="font-size: 0.8rem"
                  >We therefore advice that you only request for services by
                  creating an order on Workmorr so there is evidence of an
                  agreement.</span
                >
                <br /><br />
                <span style="font-size: 0.8rem"
                  >Please <b>DO NOT</b> pay for services before they are provided to you, for
                  your own safety.</span
                >
              </b-alert>
              <div class="row">
                <div class="col-6 pr-1">
                  <b-button variant="primary" size="sm" block @click="chat()">
                    <b-icon-chat-right-dots
                      class="mr-1"
                    ></b-icon-chat-right-dots>
                    Message Workrr
                  </b-button>
                </div>
                <div class="col-6 pl-1">
                  <b-button
                    :variant="gig.type_id === 1 ? 'success' : 'info'"
                    size="sm"
                    block
                    @click="purchaseOrBidForGig()"
                  >
                    <b-icon-cash-coin class="mr-1"></b-icon-cash-coin>
                    {{ gig.type_id === 1 ? "Request" : "Offer " }} Service
                  </b-button>
                </div>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-12">
                <b-alert variant="success" :show="true">
                  <div class="mb-2">
                    <b>
                      <b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill>
                      <span style="font-size: 0.9rem" class="ml-1"
                        >Disclaimer & Warning</span
                      > </b
                    ><br />
                  </div>
                  <span style="font-size: 0.8rem"
                    >Workmorr <b>DOES NOT</b> charge a commission on orders. An
                    order created on Workmorr represents a
                    <b>contract</b> between you and the user providing or requesting this
                    service.</span
                  >
                  <br /><br />
                  <span style="font-size: 0.8rem"
                    >We therefore advice that you only request for services by
                    creating an order on Workmorr so there is evidence of an
                    agreement.</span
                  >
                  <br /><br />
                  <span style="font-size: 0.8rem"
                    >Please <b>DO NOT</b> pay for services before they are provided to you, for
                    your own safety.</span
                  >
                </b-alert>
                <b-button variant="primary" block @click="chat()">
                  <b-icon-chat-right-dots class="mr-1"></b-icon-chat-right-dots>
                  Message {{ gig.type_id === 1 ? "Seller" : "Buyer" }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>

<script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import GigService from "@/services/GigService";

import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
Vue.use(VueViewer);

import {
  BIcon,
  BIconHeart,
  BIconMap,
  BIconStarFill,
  BIconStopwatch,
  BIconExclamationCircle,
  BIconChatRightDots,
  BIconCashCoin,
  BIconEye,
  BIconPencilSquare,
  BIconExclamationTriangleFill,
  BIconPatchCheckFill,
  BIconGraphUp,
} from "bootstrap-vue";
import Mapbox from "mapbox-gl";
import { MglMap, MglMarker, MglNavigationControl } from "vue-mapbox";
import { formatErrors } from "@/utils/helpers";
import CustomLoader from "@/components/Workmorr/CustomLoader";
import ErrorAlert from "@/components/Workmorr/ErrorAlert";

export default {
  name: "ViewGigView",
  components: {
    ErrorAlert,
    CustomLoader,
    MobileMenuBody,
    MobileMenuWrapper,
    MobileMenuHeader,
    BIcon,
    BIconStopwatch,
    // eslint-disable-next-line vue/no-unused-components
    BIconHeart,
    BIconMap,
    BIconExclamationCircle,
    BIconStarFill,
    MglMap,
    MglMarker,
    MglNavigationControl,
    BIconChatRightDots,
    BIconCashCoin,
    BIconEye,
    BIconPencilSquare,
    BIconExclamationTriangleFill,
    BIconPatchCheckFill,
    BIconGraphUp,
  },
  data() {
    return {
      gig: null,
      user: null,
      accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN, // your access token. Needed if you using Mapbox maps
      mapStyle: "mapbox://styles/mapbox/streets-v11", // your map style
      mapbox: null,
      offer: null,
      errors: {},
      loading: true,
      object: null,
      images: [],
      options: {
        inline: false,
        button: false,
        navbar: true,
        title: false,
        toolbar: false,
        tooltip: false,
        movable: false,
        zoomable: false,
        rotatable: false,
        scalable: false,
        transition: true,
        fullscreen: false,
        keyboard: false,
        url: "data-source",
      },
    };
  },
  created() {
    // We need to set mapbox-gl library here in order to use it in template
    this.mapbox = Mapbox;
  },
  async mounted() {
    await this.getGig();
    this.user = await this.$store.getters["user/user"];
  },
  methods: {
    getGig() {
      GigService.getGig(this.$route.params.gig_id)
        .then((response) => {
          this.gig = response.data.gig;
          if (this.gig.status_id !== 2) {
            // redirect to gigs
            this.$router.push("/");
          }
          window.document.title = "Workmorr - " + this.gig.long_title;

          // Format images
          if (this.gig.images.length > 0) {
            this.gig.images.forEach((image) => {
              this.images.push(image.file.firebase_url);
            });
          }
        })
        .finally(() => (this.loading = false));
    },

    favoriteGig(gig_id) {
      GigService.favoriteGig(gig_id).then((response) => {
        this.gig = response.data.gig;
      });
    },

    async onMapLoaded(event) {
      event.map.flyTo({
        center: [this.gig.longitude, this.gig.latitude],
        zoom: 13,
        speed: 1,
      });
    },

    async purchaseOrBidForGig() {
      this.loading = true;
      if (this.gig.type_id === 2) {
        // Gig is an request so bid
        this.redirectToOfferCreation();
      } else {
        // Gig is a service offering so pay
        // Check if user is logged in else redirect.
        if (this.$store.getters["user/user"] === null) {
          await this.$router.push(
            "/login?redirect=" + this.$router.currentRoute.path
          );
        } else {
          // User is logged in.
          // Create Offer
          await GigService.createOffer({
            gig_id: this.gig.id,
            is_custom: false,
          })
            .then((response) => {
              // Set offer
              this.offer = response.data.gig_offer;

              // Pay
              this.requestOrder();
            })
            .catch((error) => {
              this.errors = formatErrors(error);
              this.loading = false;
            });
        }
      }
    },

    redirectToOfferCreation() {
      this.$router.push("/gigs/offer/" + this.gig.id + "/create");
    },

    chat() {
      const message =
        "Hi " +
        this.gig.user.username +
        "," +
        "<br>" +
        "I am interested in your gig: " +
        "<a href='/gigs/view/" +
        this.gig.id +
        "/" +
        this.gig.slug +
        "'> " +
        this.gig.long_title +
        "</a>";

      this.$router.push(
        "/account/messages/" +
          0 +
          "/" +
          this.gig.user.username +
          "?message=" +
          message
      );
    },

    async requestOrder() {
      this.loading = true;
      this.$router.push("/orders/request/" + this.offer.id);
    },
  },
};
</script>

<style scoped>
.col-padding-small {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

li {
  list-style-type: none;
}

ul {
  padding-inline-start: 20px;
}

.edit-btn {
  padding: 0px 3px 0px 3px;
}

.image {
  height: 60px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
  border-radius: 7%;
}
</style>