<template>
  <div>
    <div class="container menu-border div-no-show-scroll shadow-sm mt-1 pt-2" style="position: relative; height: 550px; font-size: 0.8rem">
      <slot></slot>
    </div>
    <BottomFooter></BottomFooter>
  </div>
</template>

<script>
import BottomFooter from "@/components/Workmorr/BottomFooter";
export default {
  name: "TabletMenuBody",
  components: {BottomFooter},
  inheritAttrs: false, // optional
}
</script>

<style scoped>

</style>