import { ApiService } from "@/services/APIService";

export default {
    getTransaction(reference) {
        return ApiService.get("/api/payments/transaction/" + reference);
    },

    getActivePaymentMethod() {
        return ApiService.get("/api/payments/active");
    },

    initiatePayment(payload) {
        return ApiService.post("/api/payments/initiate", payload);
    },

    initiateManualPayment(payload) {
        return ApiService.post("/api/payments/manual/initiate", payload);
    },

    submitManualPayment(payload) {
        return ApiService.post("/api/payments/manual/submit", payload);
    }
}