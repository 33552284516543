<template>
  <div class="text-center mt-2 text-success mb-5">
        <span class="small">
          &#169; Workmorr Limited
        </span>
    &middot;
    <span class="small">
          <a href="/read/1/support/privacy-policy" class="mouse-pointer text-success" style="text-decoration: none;">
            <span >
              Privacy Policy
            </span>
          </a>
        </span>
    &middot;
    <span class="small">
           <a href="/read/2/support/terms-of-use" class="mouse-pointer text-success" style="text-decoration: none;">
            <span >
              Terms Of Use
            </span>
          </a>
        </span>
  </div>
</template>

<script>
export default {
  name: "BottomFooter"
}
</script>

<style scoped>

</style>