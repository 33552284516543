import {ApiService} from "@/services/APIService";

export default {
    getReportCategories(){
        return ApiService.get("/api/report/categories");
    },

    makeReport(payload) {
        return ApiService.post("/api/report/add", payload, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    }
}