<template>
  <mobile-menu-wrapper>
    <mobile-menu-header
        :title="chat ? chat.user.username : ''"
        back-title="Back to Messages"
        back-route-link="/account/messages"
    ></mobile-menu-header>
    <mobile-menu-body class="pb-0">
      <div v-if="messages" class="chat-area" ref="messagesContainer">
        <mobile-menu-chat-message @refresh="getMessages" tag="li"
            v-for="(message, index) in messages"
            :service="service"
            :key="index"
            :message="message"
            :username="chat ? chat.user.username : ''"
        >
          <div class="text-center font-weight-bold"
               v-if="index === 0 ||
               ( index > 0 && moment(message.created_at).format('MMMM Do YYYY') != moment(messages[index - 1].created_at).format('MMMM Do YYYY') )">
            {{ moment(messages[index].created_at).format('MMMM Do, YYYY') }}
          </div>
        </mobile-menu-chat-message>
      </div>
      <div class="row chat-input">
        <div class="col-12">
          <b-input-group>
            <b-input-group-prepend>
              <b-button @click="openFileUpload" class="attach-button" variant="outline-primary">
                <b-icon icon="paperclip"></b-icon>
              </b-button>
            </b-input-group-prepend>
            <b-form-input
                @keyup.enter="sendMessage"
                v-model="message.text"
                placeholder="Type a message ..."
            >
            </b-form-input>
            <b-input-group-append>
              <b-button class="send-button" @click="sendMessage" :disabled="message.text === '' || loading" variant="success">
                {{ loading ? 'Sending...' : 'Send' }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <small v-if="file_name !== ''"><b-icon icon="paperclip"></b-icon> {{ file_name }}</small>
        </div>
      </div>
    </mobile-menu-body>
    <b-form-file
        @change="onFileChange"
        style="visibility: hidden"
        ref="message-file"
        id="file-upload"
        v-model="message.files"
        placeholder="(Optional) Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
        :disabled="loading"
        class="mt-2"
        hidden
    ></b-form-file>
  </mobile-menu-wrapper>
</template>

<script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import MobileMenuChatMessage from "@/components/Workmorr/MobileMenu/Chat/MobileMenuChatMessage";
import ChatService from "@/services/ChatService";
import {BIconPaperclip, BIcon} from 'bootstrap-vue';
import OrderService from "@/services/OrderService";
import Pusher from 'pusher-js'


export default {
  name: "ChatView",
  // eslint-disable-next-line vue/no-unused-components
  components: {MobileMenuChatMessage, MobileMenuBody, MobileMenuWrapper, MobileMenuHeader, BIconPaperclip, BIcon},
  data(){
    return {
      loading: false,
      messages: [],
      chat: null,
      message: {
        text: '',
        files: null
      },
      file_name: '',
      service: null,
    }
  },

  async mounted() {
    this.service = OrderService;

    await this.getMessages();
    await this.$store.dispatch('user/getAuthenticatedUser');
    document.title = 'Workmorr Chat - ' + this.chat.user.username;

    let pusher = new Pusher('932ad9028bdb64393a8b', {
      cluster: 'eu'
    });

    let channel = pusher.subscribe('workmorr-chat-' + this.chat.id + '-' + this.$store.getters['user/user'].id);
    channel.bind('App\\Events\\Shop\\Chat\\ChatMessageEvent', function(data) {
      this.messages.push(data.message);
      this.markChatMessageAsRead(data.message.id);
      this.$nextTick(() => this.scrollToEnd());
    }.bind(this));

    // Check for message
    if ( this.$route.query.message ){
      this.message.text = this.$route.query.message;
      await this.sendMessage();
      await this.$router.push('/account/messages/' + this.chat.id + '/' + this.$route.params.username )
    }

    // Check for floating route
    if ( this.$route.params.chat_id === '0' ){
      await this.$router.push('/account/messages/' + this.chat.id + '/' + this.$route.params.username )
    }

    // Import Paystack Script
    let paystackScript = document.createElement('script')
    paystackScript.setAttribute('src', 'https://js.paystack.co/v1/inline.js')
    document.head.appendChild(paystackScript)
  },

  methods: {
    async getMessages() {
      let response = await ChatService.getChatMessages(this.$route.params.chat_id, this.$route.params.username);
      this.messages = response.data.messages;
      this.chat = response.data.chat;

      this.$nextTick(() => this.scrollToEnd());
    },

    scrollToEnd: function () {
      let content = this.$refs.messagesContainer;
      content.scrollTop = content.scrollHeight
    },

    async sendMessage() {
      this.loading = true;
      const formData = new FormData();
      formData.append('to_user_id', this.chat.user.id);
      formData.append('message', this.message.text);
      if (this.message.files){
        formData.append('message_files', this.message.files);
      }


      await ChatService.sendChatMessage(formData)
          .then((response) => {
            this.messages = response.data.messages;
            // clear form
            this.message.text = '';
            this.$refs['message-file'].reset()
            this.file_name = '';

          })
          .finally(() => {
            this.loading = false
          });

      this.$nextTick(() => this.scrollToEnd());
    },

    markChatMessageAsRead(message_id) {
      ChatService.markChatMessageAsRead(message_id);
    },

    openFileUpload: ()=> {document.getElementById('file-upload').click()},

    onFileChange(event){
      let fileData =  event.target.files[0];
      this.file_name = fileData.name;
    }
  }
}
</script>

<style scoped>
.chat-area {
  height: 470px;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.chat-area::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.chat-input{
  background-color: #f1ffee;
  padding: 1rem 0.1rem 1rem 0.1rem;
}

.input-buttons {
  text-align: center!important;
}

.send-button{
  border-radius: 0px 7px 7px 0px !important;
}

.attach-button{
  border-radius: 7px 0px 0px 7px !important;
}

input:focus{
  outline-style: none !important;
  box-shadow: none !important;
}

.attach-button:focus, .send-button:focus{
  outline-style: none !important;
  box-shadow: none !important;
}
</style>