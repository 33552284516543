<template>
  <div id="app">
    <b-navbar toggleable="lg" type="light" variant="light" class="mb-0">
      <div class="container">
        <router-link to="/" tag="b-navbar-brand" class="mb-2">
          <a>
            <img src="@/assets/logo.png" alt="Workmorr Logo" width="150px" />
          </a>
        </router-link>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <router-link to="/gigs/add" tag="span" class="mr-1 ml-1" right>
              <b-button class="btn-success font-weight-bold mb-2" block>
                Request or Offer a Service
              </b-button>
            </router-link>
            <b-nav-item-dropdown
              v-if="$store.getters['user/user']"
              class="ml-2 mt-1"
              right
            >
              <!-- Using 'button-content' slot -->
              <template #button-content>
                {{ $store.getters["user/user"].name }}
                <b-icon-bell-fill
                  v-if="$store.getters['user/user'].unread_messages_count > 0"
                  animation="fade"
                  class="mr-2 text-danger"
                ></b-icon-bell-fill>
              </template>
              <b-dropdown-item to="/account/profile">
                <b-icon-person-badge
                  class="mr-2 text-success"
                ></b-icon-person-badge>
                <span>My Profile</span>
              </b-dropdown-item>
              <b-dropdown-item to="/account/messages">
                <b-icon-mailbox class="mr-2 text-success"></b-icon-mailbox>
                <span>My Messages</span>
                <b-badge
                  pill
                  variant="danger"
                  class="ml-1"
                  v-if="$store.getters['user/user'].unread_messages_count > 0"
                >
                  <span class="small mb-1 font-weight-bold">{{
                    $store.getters["user/user"].unread_messages_count
                  }}</span>
                </b-badge>
              </b-dropdown-item>
              <b-dropdown-item to="/account/gigs">
                <b-icon-tools class="mr-2 text-success"></b-icon-tools>
                <span>My Gigs</span>
              </b-dropdown-item>
              <b-dropdown-item to="/account/orders">
                <b-icon-briefcase class="mr-2 text-success"></b-icon-briefcase>
                <span>My Orders</span>
              </b-dropdown-item>
              <b-dropdown-item to="/account/favorites">
                <b-icon-heart-fill
                  class="mr-2 text-success"
                ></b-icon-heart-fill>
                <span>My Favorites</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="isUserManager()" to="/account/money">
                <b-icon-cash-stack
                  class="mr-2 text-success"
                ></b-icon-cash-stack>
                <span>My Money</span>
              </b-dropdown-item>
              <b-dropdown-item to="/account/subscription">
                <b-icon-people-fill
                  class="mr-2 text-success"
                ></b-icon-people-fill>
                <span>My Subscription</span>
              </b-dropdown-item>
              <!-- <b-dropdown-item to="/account/settings">
                <b-icon-gear-fill
                  class="mr-2 text-success"
                ></b-icon-gear-fill>
                <span>My Settings</span>
              </b-dropdown-item> -->
              <b-dropdown-item v-if="isUserManager()" to="/manager/dashboard">
                <b-icon-columns-gap
                  class="mr-2 text-success"
                ></b-icon-columns-gap>
                <span>Manager Area</span>
              </b-dropdown-item>
              <b-dropdown-item to="/logout">
                <b-icon-lock class="mr-2 text-danger"></b-icon-lock>
                <span class="text-danger">Log Out</span>
              </b-dropdown-item>
            </b-nav-item-dropdown>
            <router-link v-else to="/login" tag="div" class="mr-1 ml-1" right>
              <b-button
                variant="outline-success"
                class="font-weight-bold"
                block
              >
                Login or Register
              </b-button>
            </router-link>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
    <div class="container">
      <b-alert show variant="primary"
        ><div class="text-center">
          <a href="#" @click="showDownloadAppModal" class="alert-link"
            >Download the App on Google Play Store or Apple App Store.</a
          >
        </div></b-alert
      >
      <router-view />
    </div>
    <b-modal id="download-the-app-modal" hide-footer>
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <h4 class="mb-1">Download the App.</h4>
            <h5>Available on iPhone & Android.</h5>
            <img
              src="/media/apps-stores-mockup.png"
              alt="App Stores Mockups"
              style="width: 90%"
              class="mb-2"
            />
          </div>
          <div class="col-md-12 text-center mb-2">
            <h6 class="mb-1">Earn money from your skills on the go.</h6>
            <h6>Download the app for free today.</h6>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="row mb-3">
              <div class="col-12 text-center mb-1">
                <a
                  href="https://play.google.com/store/apps/details?id=com.workmorr.workmorr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/media/play-store.png"
                    alt="Play Store"
                    style="height: 52px"
                  />
                </a>
              </div>
              <div class="col-12 text-center mb-0">
                <a
                  href="https://apps.apple.com/gh/app/workmorr/id6473766809"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/media/app-store.png"
                    alt="App Store"
                    style="height: 57px"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BIconLock,
  BIconPersonBadge,
  // BIconCashStack,
  BIconTools,
  BIconBriefcase,
  BIconMailbox,
  BIconPeopleFill,
  BIconHeartFill,
  BIconBlockquoteLeft,
  BIconBellFill,
  BIconColumnsGap,
  BIconCurrencyDollar,
  BIconGearFill,
  BIconCashStack,
} from "bootstrap-vue";

export default {
  components: {
    BIconLock,
    BIconPersonBadge,
    // BIconCashStack,
    BIconTools,
    BIconBriefcase,
    BIconMailbox,
    BIconPeopleFill,
    BIconHeartFill,
    // eslint-disable-next-line vue/no-unused-components
    BIconBlockquoteLeft,
    BIconBellFill,
    BIconColumnsGap,
    // eslint-disable-next-line vue/no-unused-components
    BIconCurrencyDollar,
    // eslint-disable-next-line vue/no-unused-components
    BIconGearFill,
    // eslint-disable-next-line vue/no-unused-components
    BIconCashStack,
  },

  data() {
    return {
      user: {},
    };
  },

  mounted() {
    if (this.$store.getters["user/user"]) {
      // refresh user data
      this.$store.dispatch("user/getAuthenticatedUser");
    }

    // this.$bvModal.show("download-the-app-modal");
  },

  methods: {
    showDownloadAppModal() {
      this.$bvModal.show("download-the-app-modal");
    },
  },
};
</script>

<style>
@import "./assets/styles/main.css";
</style>
