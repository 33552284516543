<template>
  <tablet-menu-wrapper :portrait="true">
    <mobile-menu-header
        title='Add Page'
        back-title="Back to Pages"
        back-route-link="/manager/pages"
    ></mobile-menu-header>
    <tablet-menu-body>
      <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
      <div v-else>
        <ErrorAlert v-if="errors" :errors="errors"></ErrorAlert>
        <SuccessAlert v-if="success" :message="success"></SuccessAlert>
        <b-form-group
            label="Page Title"
        >
          <b-form-input v-model="page.title" :disabled="loading.form"></b-form-input>
        </b-form-group>

        <b-form-group
            label="Page Category"
        >
          <b-form-select v-model="page.category_id" :disabled="loading.form">
            <option :value="null">Select Category</option>
            <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.description }}</option>
          </b-form-select>
        </b-form-group>

        <b-form-group
            label="Page Content"
        >
          <editor
              :disabled="loading.form"
              v-model="page.content"
              api-key="id8huwbtsicpgcsq9digruov36p4d48xrd8zgpfyk9gvgyzn"
              :init="{
             height: 300,
             menubar: false,
             plugins: [
               'advlist autolink lists link image charmap print preview anchor',
               'searchreplace visualblocks code fullscreen',
               'insertdatetime media table paste code help wordcount'
             ],
             toolbar:
               'undo redo | formatselect | bold italic backcolor | media | \
               alignleft aligncenter alignright alignjustify | \
               bullist numlist outdent indent | removeformat | help'
           }"
          />
        </b-form-group>
        <div class="text-center">
          <b-button style="min-width: 200px" @click.prevent="onSubmit" type="submit" variant="success" :disabled="loading.form">
            {{ loading.form ? 'Loading, please wait...' : 'Add Page' }}
          </b-button>
        </div>
      </div>
    </tablet-menu-body>
  </tablet-menu-wrapper>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import TabletMenuBody from "@/components/Workmorr/MobileMenu/TabletLayout/TabletMenuBody";
import TabletMenuWrapper from "@/components/Workmorr/MobileMenu/TabletLayout/TabletMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import ManagePagesService from "@/services/manager/ManagePagesService";
import {formatErrors} from "@/utils/helpers";
import ErrorAlert from "@/components/Workmorr/ErrorAlert";
import SuccessAlert from "@/components/Workmorr/SuccessAlert";
import CustomLoader from "@/components/Workmorr/CustomLoader";

export default {
  name: "AddPageView",
  components: {TabletMenuBody, TabletMenuWrapper, MobileMenuHeader, Editor, ErrorAlert, SuccessAlert, CustomLoader},
  data() {
    return {
      loading: {
        page: true,
        form: false,
      },
      page: {
        title: '',
        content: '',
        category_id: null
      },
      categories: [],
      success: '',
      errors: {},
    }
  },

  async mounted() {
    await ManagePagesService.getPageCategories().then((response) => {
      this.categories = response.data;
    })
    this.loading.page = false;
  },

  methods: {
    onSubmit(){
      this.loading.form = true;
      ManagePagesService.addPage(this.page).then((response) => {
        this.$router.push('/manager/pages/view/' + response.data.page.id);
      }).catch((error) => {
        // Clear success
        this.success = null;
        this.errors = formatErrors(error);
      }).finally(() => {
        this.loading.form = false;
      });
    }
  }

}
</script>

<style scoped>

</style>