import {ApiService} from "@/services/APIService";

export default {
   
    getPromotionOffers() {
        return ApiService.get("/api/promotions/offers");
    },

    getOffer(offer_id){
        return ApiService.get("/api/promotions/offers/" + offer_id);
    }
}