<template>
  <tablet-menu-wrapper>
    <mobile-menu-header
      title="Manage Orders"
      back-title="Back to Dashboard"
      back-route-link="/manager/dashboard"
    ></mobile-menu-header>
    <tablet-menu-body>
      <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
      <div v-else id="infinite-list-tablet-view">
        <div v-if="orders.length === 0 && !loading.page">
          <CenterMessage message="No orders found."></CenterMessage>
        </div>
        <div v-else>
          <div v-if="!loading.page" class="spread-cols-to-ends mb-1">
            <div></div>
            <div class="mt-3">
              <span
                >Showing page {{ pagination.currentPage }} of
                {{ pagination.totalPages }}.</span
              >
            </div>
          </div>
          <b-table-lite
            small
            hover
            v-if="!loading.page"
            :items="orders"
            :fields="fields"
          >
            <template #cell(gig)="data">
              {{ data.item.offer.gig.short_title }}
            </template>
            <template #cell(seller)="data">
              {{ data.item.offer.seller.username }}
            </template>
            <template #cell(buyer)="data">
              {{ data.item.offer.buyer.username }}
            </template>
            <template #cell(amount)="data">
              &#8373; {{ data.item.offer.amount }}
            </template>
            <template #cell(status)="data">
              <b-badge v-if="data.item.status_id === 1" variant="info"
                >Pending Seller Acceptance</b-badge
              >
              <b-badge
                v-if="data.item.status_id === 2"
                variant="warning"
                style="color: white"
                >Ongoing</b-badge
              >
              <b-badge
                v-if="data.item.status_id === 3"
                variant="primary"
                style="color: white"
                >Submitted for Review</b-badge
              >
              <b-badge v-if="data.item.status_id === 4" variant="success"
                >Completed</b-badge
              >
              <b-badge v-if="data.item.status_id === 5" variant="danger"
                >Cancelled</b-badge
              >
            </template>
            <template #cell(created)="data">
              {{ moment(data.item.created_at).calendar() }}
            </template>
            <template #cell(start)="data">
              {{
                data.item.started_on
                  ? moment(data.item.started_on).calendar()
                  : ""
              }}
            </template>
            <template #cell(due)="data">
              {{ data.item.due_on ? moment(data.item.due_on).calendar() : "" }}
            </template>
          </b-table-lite>
          <nav>
            <ul class="pagination justify-content-center">
              <li v-if="pagination.currentPage > 1" class="page-item">
                <a
                  class="page-link"
                  href="#"
                  aria-label="Previous"
                  @click.prevent="changePage(pagination.currentPage - 1)"
                >
                  <span aria-hidden="true">«</span>
                </a>
              </li>
              <li
                class="page-item"
                v-for="page in pagination.totalPages"
                :key="page"
                :class="{ active: page == pagination.currentPage }"
              >
                <a
                  v-if="
                    page < 3 ||
                    page > pagination.totalPages - 3 ||
                    (page < pagination.currentPage + 3 &&
                      page > pagination.currentPage - 3)
                  "
                  class="page-link"
                  @click.prevent="changePage(page)"
                  >{{ page }}</a
                >
              </li>
              <li
                v-if="pagination.currentPage < pagination.totalPages"
                class="page-item"
              >
                <a
                  class="page-link"
                  aria-label="Next"
                  @click.prevent="changePage(pagination.currentPage + 1)"
                >
                  <span aria-hidden="true">»</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </tablet-menu-body>
  </tablet-menu-wrapper>
</template>

<script>
import TabletMenuBody from "@/components/Workmorr/MobileMenu/TabletLayout/TabletMenuBody";
import TabletMenuWrapper from "@/components/Workmorr/MobileMenu/TabletLayout/TabletMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import ManageOrdersService from "@/services/manager/ManageOrdersService";
import CustomLoader from "@/components/Workmorr/CustomLoader";
import { BTableLite } from "bootstrap-vue";
import CenterMessage from "@/components/Workmorr/CenterMessage";

export default {
  name: "ManageOrdersView",
  components: {
    CustomLoader,
    TabletMenuBody,
    TabletMenuWrapper,
    MobileMenuHeader,
    BTableLite,
    CenterMessage,
  },
  data() {
    return {
      loading: {
        page: true,
        records: false,
      },
      fields: [
        "id",
        "gig",
        "buyer",
        "seller",
        "amount",
        "status",
        "created",
        "start",
        "due",
      ],
      orders: [],
      search: "",
      pagination: {
        currentPage: 1,
        perPage: 12,
        totalRows: 1000, // Placeholder so get is run at least once
        totalPages: 1,
      },
    };
  },

  async mounted() {
    await this.getOrders();
  },

  methods: {
    changePage(page) {
      this.pagination.currentPage = page;
      this.getOrders();
    },

    async getOrders() {
      this.loading.page = true;
      let response = await ManageOrdersService.getOrders(
        this.pagination.perPage,
        this.pagination.currentPage,
        this.search
      );
      this.orders = response.data.orders.data;
      this.pagination.totalRows = response.data.orders.total;
      this.pagination.totalPages = response.data.orders.last_page;
      this.loading.page = false;
    },
  },
};
</script>

<style scoped>
</style>