<template>
  <mobile-menu-wrapper>
    <mobile-menu-header
      title="Request for Service"
      back-title="Go Back"
      :back-route-link="lastPath"
    ></mobile-menu-header>
    <mobile-menu-body v-if="object">
      <div v-if="requestSubmitted == false">
        <div class="mb-2">
          <p style="font-size: 15px; line-height: 1.3">
            By requesting for this service, you agree to the following terms and
            conditions.
          </p>
          <!-- Gig details go here -->
          <div class="mb-2" style="font-weight: 800; font-size: 15px">
            Service Details
          </div>
          <div class="mb-2">
            <ul class="mt-1" style="font-size: 14px; line-height: 1.3">
              <li>Title: {{ object.gig.long_title }}</li>
              <li>Price: GHS {{ object.gig.amount }}</li>
              <li>Duration: {{ object.gig.human_readable_duration }}</li>
              <li>
                Description
                <p
                  style="font-size: 0.9rem; line-height: 1.2; margin-top: 3px"
                  v-html="object.gig.description"
                ></p>
              </li>
              <li>
                Pre-Verified Seller Payment Method <br />
                <div style="font-size: 13px; margin-top: 3px">
                  Type:
                  <span style="font-weight: 500">{{
                    object.seller.payment_detail.account_type.name
                  }}</span>
                  <br />
                  Name:
                  <span style="font-weight: 500">{{
                    object.seller.payment_detail.institution_name
                  }}</span>
                  <br />
                  Currency:
                  <span style="font-weight: 500">{{
                    object.seller.payment_detail.account_currency
                  }}</span>
                  <br />
                  <!-- Account No:
                  <b>{{ object.seller.payment_detail.account_no }}</b>
                  <br />
                  Name on Account:
                  <b>{{ object.seller.payment_detail.name_on_account }}</b>
                  <br /><br /> -->
                </div>
              </li>
            </ul>
            <div style="font-size: 0.7rem; line-height: 1.3" class="mt-2 mb-3">
              <b-icon-exclamation-circle
                class="text-primary font-weight-bold"
              ></b-icon-exclamation-circle>
              Account number and name will be provided once the seller accepts
              your order. We advice you only pay the seller using their verified
              account as this is the account that matches their identity details
              provided to Workmorr.
            </div>
            <b-alert variant="warning" :show="true">
              <div class="mb-1">
                <b>
                  <b-icon-exclamation-triangle></b-icon-exclamation-triangle>
                  <span style="font-size: 0.9rem" class="ml-1"
                    >Please Note</span
                  >
                </b>
              </div>
              <span style="font-size: 0.8rem"
                >Requesting a service means your are ready to pay the full cost
                once the seller completed their work. <b>DO NOT</b> request for
                a service if payment is not ready.</span
              >
            </b-alert>
          </div>

          <div class="text-center">
            <b-button
              @click.prevent="performOrderAction"
              variant="success"
              :disabled="loading"
            >
              {{
                loading ? "Loading, please wait..." : "I agree to the terms - Request service."
              }}
            </b-button>
          </div>
        </div>
      </div>
      <div v-else style="text-align: center" class="mt-5 mb-5">
        <div style="font-size: 3rem; text-align: center">
          <b-icon-check-circle variant="success"></b-icon-check-circle>
        </div>
        <div>
          <h6 class="mb-1">Service Request Submitted</h6>
          <div style="font-size: 0.9rem; line-height: 1.4" class="mb-3">
            We have notified <b>{{ this.object.seller.username }}</b
            >. <br />
            They will have 24 hours to accept or reject the request.
          </div>
          <div class="row">
            <div class="col-6 pr-1">
              <b-button
                block
                variant="primary"
                :to="
                  '/gigs/view/' +
                  this.object.gig.id +
                  '/' +
                  this.object.gig.slug
                "
              >
                <b>Back To Gig</b>
              </b-button>
            </div>
            <div class="col-6 pl-1">
              <b-button
                block
                variant="success"
                :to="'/account/messages/0/' + this.object.seller.username"
              >
                <b>Message Seller</b>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>
  
  <script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody.vue";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import GigService from "@/services/GigService";
import { formatErrors } from "@/utils/helpers";
import {
  BIconExclamationCircle,
  BIconExclamationTriangle,
} from "bootstrap-vue";
import OrderService from "@/services/OrderService";

export default {
  name: "RequestOrderView",
  components: {
    MobileMenuBody,
    MobileMenuWrapper,
    MobileMenuHeader,
    BIconExclamationCircle,
    BIconExclamationTriangle,
  },
  data() {
    return {
      lastPath: "/",
      object: null,
      object_type: null,
      loading: false,
      requestSubmitted: false,
    };
  },
  mounted() {
    // Get Gig Offer
    GigService.getOffer(this.$route.params.offer_id).then((response) => {
      this.object = response.data.offer;
    });
  },

  methods: {
    onLoad() {
      console.log("loaded");
    },

    async performOrderAction() {
      this.loading = true;

      await OrderService.performOrderAction("request", {
        gig_offer_id: this.$route.params.offer_id,
      })
        .then((response) => {
          // let order = response.data.order;
          this.success = response.data.message;

          // Update request submitted status
          this.requestSubmitted = true;
        })
        .catch((response) => {
          this.errors = formatErrors(response);
        });

      this.loading.page = false;
    },

    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.lastPath = from;
      });
    },
  },
};
</script>
  
  <style scoped>
</style>