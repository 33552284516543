import Vue from 'vue'
import Vuex from 'vuex'
import user from '@/store/modules/user'
import subscription from '@/store/modules/subscription'
import createPersistedState from 'vuex-persistedstate';


Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  plugins: [createPersistedState({
    storage: localStorage,
  })],
  modules: {
    user,
    subscription
  }
})
