<template>
  <mobile-menu-wrapper>
    <mobile-menu-header
      title="Messages"
      back-title="Back to Account"
      back-route-link="/account"
    ></mobile-menu-header>
    <b-form-input
      class="mt-1 mb-1"
      small
      placeholder="Search Conversation by Username"
      v-model="search"
    ></b-form-input>
    <mobile-menu-body class="chat-area">
      <div id="infinite-list-mobile-view">
        <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
        <div v-else>
          <div v-if="chats.length === 0">
            <CenterMessage message="No conversations found."></CenterMessage>
          </div>
          <div v-else>
            <mobile-menu-chat-link
              v-for="(chat, index) in chats"
              :border-bottom="false"
              :key="index"
              :chat="chat"
            >
            </mobile-menu-chat-link>
          </div>
        </div>
      </div>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>
<script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import ChatService from "@/services/ChatService";
import MobileMenuChatLink from "@/components/Workmorr/MobileMenu/Chat/MobileMenuChatLink";
import CenterMessage from "@/components/Workmorr/CenterMessage";
import CustomLoader from "@/components/Workmorr/CustomLoader";

export default {
  name: "MyMessagesView",
  components: {
    CustomLoader,
    MobileMenuChatLink,
    MobileMenuBody,
    MobileMenuWrapper,
    MobileMenuHeader,
    CenterMessage,
  },
  data() {
    return {
      chats: [],
      pagination: {
        currentPage: 1,
        perPage: 15,
        totalRows: 1000, // Placeholder so get is run at least once
      },
      self: null,
      search: "",
      loading: {
        page: true,
        list: false,
      },
    };
  },

  async mounted() {
    await this.getChats();
    this.loading.page = false;

    const listElm = document.querySelector("#infinite-list-mobile-view");
    // eslint-disable-next-line no-unused-vars
    listElm.addEventListener("scroll", (e) => {
      if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
        this.pagination.currentPage++;
        this.getChats();
      }
    });
  },

  methods: {
    async getChats() {
      if (this.chats.length < this.pagination.totalRows) {
        this.loading.list = true;
        let response = await ChatService.getChats(
          this.search,
          true,
          this.pagination.currentPage,
          this.pagination.perPage
        );

        response.data.chats.data.map(
          function (chat) {
            this.chats.push(chat);
          }.bind(this)
        );
        this.pagination.totalRows = response.data.chats.total;
        this.loading.list = false;
      }
    },
  },

  watch: {
    search() {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(
        function () {
          this.getChats();
        }.bind(this),
        1000
      );
    },
  },
};
</script>

<style scoped>
.chat-area {
  height: 700px;
  overflow-y: scroll;
}
input {
  border-radius: 0.5rem;
  outline-style: none !important;
  box-shadow: none !important;
}
</style>