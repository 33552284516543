<template>
  <mobile-menu-wrapper>
    <mobile-menu-header
        title='Location'
        back-title="Back to Profile"
        back-route-link="/account/profile"
    ></mobile-menu-header>
    <mobile-menu-body>
        <b-form>
          <ErrorAlert v-if="errors" :errors="errors"></ErrorAlert>
          <SuccessAlert v-if="success" :message="success"></SuccessAlert>
          <div style="height: 300px; border-radius: 7px;">
            <MglMap v-if="geolocationPermissionGranted"
                :accessToken="accessToken"
                :mapStyle.sync="mapStyle"
                :attributionControl="false"
                @load="onMapLoaded"
            >
              <MglGeolocateControl position="top-right" />
              <MglNavigationControl position="top-right" />
              <MglMarker
                  v-if="(form.longitude && form.latitude)"
                  :coordinates="[form.longitude, form.latitude]"
                  :draggable="true"
                  @dragend="updateLocation"
                  color="green" />
            </MglMap>
          </div>
          <div class="text-center mt-3">
            <b-button @click.prevent="onSubmit" type="submit" variant="success" :disabled="loading || (!form.longitude && form.latitude)">
              {{ loading ? 'Loading, please wait...' : 'Update Location' }}
            </b-button>
          </div>
        </b-form>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>

<script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import ErrorAlert from "@/components/Workmorr/ErrorAlert";
import SuccessAlert from "@/components/Workmorr/SuccessAlert";
import Mapbox from "mapbox-gl";
import { MglMap, MglMarker, MglGeolocateControl, MglNavigationControl } from "vue-mapbox";
import {formatErrors} from "@/utils/helpers";


export default {
  name: "LocationSettingsView",
  components: {MobileMenuBody, MobileMenuWrapper, MobileMenuHeader, ErrorAlert, SuccessAlert, MglMap, MglMarker, MglGeolocateControl, MglNavigationControl },
  data() {
    return {
      loading: false,
      form: {
        longitude: '',
        latitude: '',
      },
      errors: {},
      success: null,
      accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN, // your access token. Needed if you using Mapbox maps
      mapStyle: 'mapbox://styles/mapbox/streets-v11', // your map style
      mapbox: null,
    }
  },
  created() {
    // We need to set mapbox-gl library here in order to use it in template
    this.mapbox = Mapbox;
  },

  computed: {
    geolocationPermissionGranted() {
      return 'geolocation' in navigator;
    }
  },

  mounted() {
    this.loading = true;
    let user = this.$store.getters['user/user'];
    this.form.longitude = user.longitude;
    this.form.latitude = user.latitude;
  },

  methods: {
    async onMapLoaded(event) {
      if (this.form.longitude && this.form.latitude){
        event.map.flyTo({
          center: [this.form.longitude, this.form.latitude],
          zoom: 14,
          speed: 1
        })
      }
      else if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(async position => {
          // Here we catching 'load' map event
          const asyncActions = event.component.actions

          const newParams = await asyncActions.flyTo({
            center: [position.coords.longitude, position.coords.latitude],
            zoom: 14,
            speed: 1
          })

          this.form.longitude = newParams.center.lng;
          this.form.latitude = newParams.center.lat;
        });
      } else {
         this.errors = ['Location permissions are not granted. Please grant permission to location. If you still cannot access location services please reload this page.']
      }
      this.loading = false;
    },

    updateLocation(event){
      const newCoordinates = event.marker.getLngLat();
      this.form.longitude = newCoordinates.lng;
      this.form.latitude = newCoordinates.lat;
    },

    onSubmit() {
      this.loading = true;
      this.$store.dispatch('user/updateDetails', {
        longitude: this.form.longitude,
        latitude: this.form.latitude,
        endpoint: '/location/update'
      }).then(response => {
        // Clear errors
        this.errors = {};
        this.success = response.message;
      }).catch((error) => {
        // Clear success
        this.success = null;
        this.errors = formatErrors(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  }
}
</script>

<style scoped>
#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
</style>