<template>
  <mobile-menu-wrapper>
    <mobile-menu-header
      title="Subscription"
      :back-title="
        prevRoute && prevRoute.meta.title
          ? 'Back to ' + prevRoute.meta.title
          : 'Back to Account'
      "
      :back-route-link="prevRoute ? prevRoute.path : '/account'"
    ></mobile-menu-header>
    <mobile-menu-body>
      <div v-if="subscription">
        <h6 class="text-center">You Are Subscribed.</h6>
        <div class="text-center" style="line-height: 1.3; font-size: 0.9rem">
          <div>
            Status: <span class="text-success font-weight-bold">Active</span>
          </div>
          <div>
            Start Date:
            <span class="font-weight-bold">{{
              moment(subscription.start_date).format("dddd, MMMM Do YYYY")
            }}</span>
          </div>
          <div>
            End Date:
            <span>{{
              moment(subscription.end_date).format("dddd, MMMM Do YYYY")
            }}</span>
          </div>
        </div>
        <div
          class="container mt-3 mb-3"
          style="line-height: 1.3; font-size: 0.9rem"
        >
          <span>You are now entitled to:</span>
          <ul style="font-size: 0.8rem">
            <li>
              <b class="text-info">Priority high</b> ranking of gigs in
              listings.
            </li>
            <li><b class="text-success">Unlimited</b> number of orders.</li>
            <li>Premium Workrr <b class="text-warning">Gold Badge</b>.</li>
            <li>
              <b class="text-primary">Dedicated</b> Whatsapp Support
              Representative.
            </li>
          </ul>
        </div>
        <div class="mt-4">
          <h6 class="text-center">
            Extend your subscription, don't get disconnected.
          </h6>
        </div>
      </div>
      <div v-else>
        <h6 class="text-center">
          Become a
          <span class="text-success font-weight-bold">Premium Workrr</span>
        </h6>
        <div
          class="container mt-3 mb-3"
          style="line-height: 1.3; font-size: 0.9rem"
        >
          <span>As a Premium Workrr, you will have:</span>
          <ul style="font-size: 0.8rem">
            <li>
              <b class="text-info">Priority high</b> ranking of gigs in
              listings.
            </li>
            <li><b class="text-success">Unlimited</b> number of orders.</li>
            <li>Premium Workrr <b class="text-warning">Gold Badge</b>.</li>
            <li>
              <b class="text-primary">Dedicated</b> Whatsapp Support
              Representative.
            </li>
          </ul>
        </div>
      </div>
      <ValidationObserver v-slot="{ invalid }">
        <b-form>
          <ErrorAlert v-if="errors" :errors="errors"></ErrorAlert>
          <SuccessAlert v-if="success" :message="success"></SuccessAlert>
          <ValidationProvider
            name="account type"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <b-form-group id="account-type-group" label-for="account-type">
              <b-form-select
                id="account-type"
                v-model="form.subscription"
                :disabled="loading"
                :state="getValidationState(validationContext)"
              >
                <b-form-select-option :value="null"
                  >Select Subscription</b-form-select-option
                >
                <b-form-select-option
                  v-for="offer in offers"
                  :value="offer"
                  :key="offer.id"
                  >{{
                    "GHS " + offer.price + " for " + offer.name
                  }}</b-form-select-option
                >
              </b-form-select>
              <b-form-invalid-feedback id="account-type-live-feedback">{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-form>
        <div class="mt-2" v-if="activePaymentMethod == 'PAWAPAY'">
          <b-alert variant="info" :show="true">
            <div class="mb-2">
              <b>
                <b-icon-exclamation-circle></b-icon-exclamation-circle>
                <span style="font-size: 0.9rem" class="ml-2"
                  >Important</span
                > </b
              ><br />
            </div>
            <span style="font-size: 0.8rem"
              >Once you click pay, you will be redirected to a payment
              page.</span
            >
            <br /><br />
            <span style="font-size: 0.8rem"
              >Make sure to enter your phone number <b>WITHOUT</b> the leading 0
              as the international format is already applied.</span
            >
            <br /><br />
            <span style="font-size: 0.8rem"
              >For example if your phone number is <b>0241231231</b>, enter only
              <b>241231231</b>. Your transaction will <b>fail</b> if you do not
              do this.</span
            >
          </b-alert>
        </div>
        <div class="text-center">
          <b-button
            @click.prevent="onSubmit"
            type="submit"
            variant="success"
            :disabled="loading || invalid"
            block
          >
            {{ loading ? "Loading, please wait..." : "Pay to Subscribe" }}
          </b-button>
        </div>
      </ValidationObserver>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>

<script>
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import ErrorAlert from "@/components/Workmorr/ErrorAlert";
import SuccessAlert from "@/components/Workmorr/SuccessAlert";
import SubscriptionService from "@/services/SubscriptionService";
import { formatErrors } from "@/utils/helpers";
import PaymentService from "@/services/PaymentService";
import { BIconExclamationCircle } from "bootstrap-vue";
// import {formatErrors} from "@/utils/helpers";

export default {
  name: "MySubscriptionView",
  components: {
    MobileMenuBody,
    MobileMenuWrapper,
    MobileMenuHeader,
    ErrorAlert,
    SuccessAlert,
    BIconExclamationCircle,
  },
  data() {
    return {
      subscription: null,
      offers: null,
      user: null,
      form: {
        subscription: null,
      },
      loading: false,
      errors: {},
      success: null,
      prevRoute: null,
      activePaymentMethod: null,
    };
  },
  async mounted() {
    await this.$store.dispatch("subscription/getActiveSubscription");
    this.subscription = this.$store.getters["subscription/subscription"];
    this.user = this.$store.getters["user/user"];

    // Get Subscription Offers
    let response = await SubscriptionService.getSubscriptionOffers();
    this.offers = response.data.offers;

    let activePaymentMethodResponse =
      await PaymentService.getActivePaymentMethod();
    this.activePaymentMethod =
      activePaymentMethodResponse.data.active_payment_method;
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit() {
      this.initiatePayment();
    },

    async initiatePayment() {
      this.loading = true;
      /**
       * 1. Check active payment method.
       * 2. Based on active payment method, initiate manual or inbuilt checkout.
       * 3. Redirect to pay page with object and object id - Concatenate gig id and promotion id.
       */

      if (this.activePaymentMethod == "MANUAL") {
        PaymentService.initiateManualPayment({
          object_type: "subscription",
          object_id: this.form.subscription.id,
        })
          .then((response) => {
            this.$router.push(
              "/pay/subscription/" +
                this.form.subscription.id +
                "/" +
                response.data.reference
            );
          })
          .catch((error) => {
            this.errors = formatErrors(error);
          })
          .finally(() => (this.loading = false));
      } else {
        PaymentService.initiatePayment({
          object_type: "subscription",
          object_id: this.form.subscription.id,
        })
          .then((response) => {
            PaymentService.getTransaction(response.data.reference).then(
              (response) => {
                let iFrameURL = response.data.transaction.checkout_url;
                // Go to checkout
                window.location.href = iFrameURL;
              }
            );
          })
          .catch((error) => {
            this.errors = formatErrors(error);
          })
          .finally(() => (this.loading = false));
      }

      // Call Initiate Payment Endpoint
      // PaymentService.initiateManualPayment({
      //   object_type: "subscription",
      //   object_id: this.form.subscription.id,
      // })
      //   .then((response) => {
      //     PaymentService.getTransaction(response.data.reference).then(
      //       (response) => {
      //         let iFrameURL = response.data.transaction.checkout_url;
      //         // Go to checkout
      //         window.location.href = iFrameURL;
      //       }
      //     );
      //   })
      //   .catch((error) => {
      //     this.errors = formatErrors(error);
      //   })
      //   .finally(() => (this.loading = false));
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
};
</script>

<style scoped>
</style>