<template>
  <mobile-menu-wrapper>
    <mobile-menu-header
      :title="'Order #' + order.id"
      back-title="Back to Orders"
      back-route-link="/account/orders"
    ></mobile-menu-header>
    <mobile-menu-body>
      <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
      <div v-else>
        <ErrorAlert v-if="errors" :errors="errors"></ErrorAlert>
        <SuccessAlert v-if="success" :message="success"></SuccessAlert>
        <div>
          <router-link
            tag="h6"
            :to="
              '/gigs/view/' + order.offer.gig.id + '/' + order.offer.gig.slug
            "
          >
            {{ order.offer.gig.short_title }}
          </router-link>
        </div>
        <hr class="mt-0 mb-2" />
        <div>
          <div class="spread-cols-to-ends mb-1">
            <div style="font-size: 0.9rem; line-height: 1.3">
              <div v-if="order.offer.seller_id === user.id">
                Buyer: <b>{{ order.offer.buyer.username }}</b>
                <b-icon-patch-check-fill
                  class="ml-1"
                  variant="primary"
                  v-if="order.offer.buyer.is_kyc_verified"
                ></b-icon-patch-check-fill>
              </div>
              <div v-else-if="order.offer.buyer_id === user.id">
                Seller: <b>{{ order.offer.seller.username }}</b>
                <b-icon-patch-check-fill
                  class="ml-1"
                  variant="primary"
                  v-if="order.offer.seller.is_kyc_verified"
                ></b-icon-patch-check-fill>
              </div>
              <div>
                Price:
                <b style="font-size: 0.8rem; color: green">
                  <span style="font-size: 0.7rem">&#8373;</span>
                  {{ order.offer.amount }}</b
                >
              </div>
              <div>
                Created:
                <b style="font-size: 0.8rem">
                  {{ moment(order.created_at).calendar() }}</b
                >
              </div>
              <div v-if="order.started_on">
                Start:
                <b style="font-size: 0.8rem">
                  {{ moment(order.started_on).calendar() }}</b
                >
              </div>
              <div v-if="order.due_on">
                Due:
                <b style="font-size: 0.8rem">
                  {{ moment(order.due_on).calendar() }}</b
                >
              </div>
            </div>
            <div>
              <b-badge v-if="order.status_id === 1" variant="info"
                >Pending
                {{ order.offer.seller_id === user.id ? "Your" : "Seller" }}
                Acceptance</b-badge
              >
              <b-badge
                v-else-if="order.status_id === 2"
                variant="warning"
                class="text-white"
                >Ongoing</b-badge
              >
              <b-badge v-else-if="order.status_id === 3" variant="primary"
                >In Review</b-badge
              >
              <b-badge v-else-if="order.status_id === 4" variant="success"
                >Completed</b-badge
              >
              <b-badge v-else-if="order.status_id === 5" variant="danger"
                >Cancelled</b-badge
              >
            </div>
          </div>
          <div
            style="
              padding: 1.5rem;
              background-color: #f1ffee;
              border-radius: 0.5rem;
              font-size: 0.9rem;
            "
            class="mb-1 mt-2"
          >
            <span
              style="font-size: 0.8rem"
              v-html="order.offer.description"
            ></span>
          </div>
          <div
            class="mt-2"
            v-if="order.status_id === 3 && order.offer.buyer_id === user.id"
          >
            <div class="mb-2 mt-3" style="font-weight: 800; font-size: 15px">
              Pre-Verified Seller Payment Details
            </div>
            <div
              style="
                font-size: 13px;
                margin-top: 3px;
                margin-left: 5px;
                line-height: 1.3;
              "
            >
              Type:
              <span style="font-weight: 500">{{
                order.offer.seller.payment_detail.account_type.name
              }}</span>
              <br />
              Name:
              <span style="font-weight: 500">{{
                order.offer.seller.payment_detail.institution_name
              }}</span>
              <br />
              Currency:
              <span style="font-weight: 500">{{
                order.offer.seller.payment_detail.account_currency
              }}</span>
              <br />
              Account No:
              <b>{{ order.offer.seller.payment_detail.account_no }}</b>
              <br />
              Name on Account:
              <b>{{ order.offer.seller.payment_detail.name_on_account }}</b>
              <br /><br />
              <div
                style="font-size: 0.7rem; line-height: 1.3"
                class="mt-0 mb-3"
              >
                <b-icon-exclamation-circle
                  class="text-primary font-weight-bold"
                ></b-icon-exclamation-circle>
                We advice you only pay the seller using the above
                verified account as this is the account that matches their
                identity details provided to Workmorr.
              </div>
            </div>
          </div>
          <div class="text-center mt-2">
            <b-button
              @click="performOrderAction('accept')"
              variant="success"
              v-if="order.status_id === 1 && order.offer.seller_id === user.id"
              >Accept & Start Gig</b-button
            >
            <b-button
              class="ml-2"
              @click="performOrderAction('cancel')"
              variant="danger"
              v-if="order.status_id === 1 && order.offer.seller_id === user.id"
              >Reject Request</b-button
            >
            <b-button
              @click="performOrderAction('submit-for-review')"
              variant="primary"
              v-if="order.status_id === 2 && order.offer.seller_id === user.id"
              >Submit as Complete for Buyers Review</b-button
            >
            <b-button
              @click="performOrderAction('complete')"
              variant="success"
              v-if="order.status_id === 3 && order.offer.buyer_id === user.id"
              >I have made Payment</b-button
            >
            <b-button
              @click="showAddReviewModal"
              class="ml-1"
              variant="info"
              v-if="order.status_id === 3 && order.offer.buyer_id === user.id"
              >Error with Order</b-button
            >
          </div>
        </div>
        <b-modal
          no-enforce-focus
          id="revision-modal"
          title="Submit Error in Order"
          size="md"
        >
          <template #default>
            <editor
              v-model="revision"
              api-key="id8huwbtsicpgcsq9digruov36p4d48xrd8zgpfyk9gvgyzn"
              :init="{
                height: 300,
                menubar: false,
                disabled: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | \
               alignleft aligncenter alignright alignjustify | \
               bullist numlist outdent indent | removeformat | help',
              }"
            />
          </template>
          <template #modal-footer>
            <div style="text-align: center">
              <b-button
                @click="$bvModal.hide('revision-modal')"
                variant="outline-danger"
                class="btn-sm mr-2"
                >Go Back</b-button
              >
              <b-button
                @click="performOrderAction('revision', order.id)"
                variant="info"
                class="btn-sm"
                >Submit Error for Correction</b-button
              >
            </div>
          </template>
        </b-modal>
      </div>
    </mobile-menu-body>
  </mobile-menu-wrapper>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import OrderService from "@/services/OrderService";
import MobileMenuBody from "@/components/Workmorr/MobileMenu/MobileMenuBody";
import MobileMenuWrapper from "@/components/Workmorr/MobileMenu/MobileMenuWrapper";
import MobileMenuHeader from "@/components/Workmorr/MobileMenu/MobileMenuHeader";
import ErrorAlert from "@/components/Workmorr/ErrorAlert";
import SuccessAlert from "@/components/Workmorr/SuccessAlert";
import CustomLoader from "@/components/Workmorr/CustomLoader";
import { BIconExclamationCircle, BIconPatchCheckFill } from "bootstrap-vue";
import { formatErrors } from "@/utils/helpers";
export default {
  name: "OrderView",
  components: {
    CustomLoader,
    MobileMenuBody,
    MobileMenuWrapper,
    MobileMenuHeader,
    ErrorAlert,
    SuccessAlert,
    BIconPatchCheckFill,
    BIconExclamationCircle,
    Editor,
  },
  data() {
    return {
      user: {},
      loading: {
        page: true,
      },
      order: {
        id: "",
      },
      errors: {},
      success: "",
      revision: "",
    };
  },
  async mounted() {
    await this.getOrder();
    this.user = this.$store.getters["user/user"];
    window.document.title = "Workmorr - Order #" + this.order.id;
    this.loading.page = false;
  },
  methods: {
    async getOrder() {
      await OrderService.getOrder(this.$route.params.order_id)
        .then((response) => {
          this.order = response.data.order;
        })
        .catch(() => {
          this.$router.push("/account/orders");
        });
    },

    async performOrderAction(action) {
      this.loading.page = true;

      await OrderService.performOrderAction(action, {
        gig_order_id: this.order.id,
        revision: this.revision,
      })
        .then((response) => {
          // update order
          this.order = response.data.order;
          this.success = response.data.message;
        })
        .catch((response) => {
          this.errors = formatErrors(response);
        });

      this.loading.page = false;
    },

    showAddReviewModal() {
      this.$bvModal.show("revision-modal");
    },
  },
};
</script>

<style scoped>
.modal-body {
  padding-top: 0 !important;
}
</style>